import { NavLink } from "react-router-dom";
import { useAppTranslation } from "../../common";
import { LogoutIcon } from "../icon";
import "./header.scss";

type HeaderProps = {
  email: string;
};

export const Header = (props: HeaderProps) => {
  const { t } = useAppTranslation();

  return (
    <div className="header-main">
      <section className="header-content">
        <div className="text-container">
          <span>
            {t("header-logged-in-as")} &#160;
            <NavLink className="profile-email" to={"/cms/profile"}>{props.email}</NavLink>
          </span>
          <LogoutIcon />
        </div>
      </section>
    </div>
  );
};
