import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  QueryKeys,
  useAppLocation,
  useAppTranslation,
} from "../../../common";
import { ErrorModal, Modal } from "../../../components";
import { deletePoetryQuizAction } from "../../../actions/poetry-quiz";

type PoetryQuizDeleteModalProps = {
  handleDeleteModalVisible: () => void;
};

export function PoetryQuizDeleteModal(props: PoetryQuizDeleteModalProps) {
  const { t } = useAppTranslation();
  const location = useAppLocation();
  const queryClient = useQueryClient();

  const deletePoetryQuiz = useMutation({
    mutationKey: [QueryKeys.deletePoetryQuizMutationQuery],
    mutationFn: deletePoetryQuizAction,
    onSuccess() {
      props.handleDeleteModalVisible();
      queryClient.invalidateQueries({ queryKey: [QueryKeys.poetryQuizQuery] });
    },
  });

  const handleCloseErrorModal = () => {
    deletePoetryQuiz.reset();
  };

  const handleDeletePoetryQuiz = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    deletePoetryQuiz.mutate({ id: location.state?.id });
  };

  return (
    <div>
      <section>
        <Modal
          onClose={props.handleDeleteModalVisible}
          title={t("poetry-quiz-page-delete-modal-title")}
          footer
          submitText={t("poetry-quiz-page-delete-submit-button-title")}
          cancelText={t("poetry-quiz-page-delete-cancel-button-title")}
          handleSubmit={handleDeletePoetryQuiz}
        >
          <span>{t("poetry-quiz-page-delete-poetry-quiz-question-label")}</span>
        </Modal>
      </section>
      <section>
        {deletePoetryQuiz.isError && (
          <ErrorModal
            error={deletePoetryQuiz.error}
            onClose={handleCloseErrorModal}
            title={t("poetry-quiz-page-delete-parent-error-modal-title")}
          />
        )}
      </section>
    </div>
  );
}
