import { useAppNavigate } from "../../../common";
import { DeleteIcon, ModifyIcon, SortIcon } from "../../../components";
import { CategoryEntity } from "../../../entity";

type CategoryColumnProps = {
  handleTitleOrder: (value: string) => void;
  titleOrder: "ASC" | "DESC" | "";
  handleDeleteModalVisible: () => void;
  handleMutateModalVisible: (value: boolean) => void;
};

export function CategoryColumns(props: CategoryColumnProps) {
  const navigate = useAppNavigate();
  const handleOpenDeleteModal = (id: number) => {
    props.handleDeleteModalVisible();
    navigate("", { state: { id } });
  };

  const handleOpenModifyModal = (category: CategoryEntity) => {
    props.handleMutateModalVisible(true);
    navigate("", { state: { category } });
  };

  return [
    {
      title: "category-page-table-header-title",
      dataIndex: "title",
      width: "65%",
      suffix: (
        <SortIcon
          order={props.titleOrder}
          orderFunction={props.handleTitleOrder}
        />
      ),
    },
    {
      title: "category-page-table-header-created",
      dataIndex: "created",
      render: (record: CategoryEntity) => {
        return <span>{new Date(record?.created!).toLocaleDateString()}</span>;
      },
    },
    {
      title: "category-page-table-header-updated",
      dataIndex: "updated",
      render: (record: CategoryEntity) => {
        return <span>{new Date(record?.updated!).toLocaleDateString()}</span>;
      },
    },
    {
      title: "poet-page-table-header-modify",
      dataIndex: "modify",
      render: (record: CategoryEntity) => {
        return <ModifyIcon onClick={() => handleOpenModifyModal(record)} />;
      },
    },
    {
      title: "category-page-table-header-delete",
      dataIndex: "delete",
      render: (record: CategoryEntity) => {
        return <DeleteIcon onClick={() => handleOpenDeleteModal(record?.id)} />;
      },
    },
  ];
}
