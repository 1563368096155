import { Expose, Type } from "class-transformer";
import { ValidateNested } from "class-validator";
import { PoetryQuizInfoEntity } from "../../entity"

export class MutatePoetryQuizInfoDTO {
  @Expose()
  @ValidateNested()
  @Type(() => PoetryQuizInfoEntity)
  answer!: PoetryQuizInfoEntity;
}
