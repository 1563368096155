import { useEffect, useRef } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Modal } from "./modal";
import {
  useErrorHandler,
  useAppState,
  useAppNavigate,
  QueryKeys,
} from "../../common";

type ErrorModalProps = {
  error: any | null;
  onClose: () => void;
  title: string;
};

export function ErrorModal(props: ErrorModalProps) {
  const timerRef = useRef<NodeJS.Timeout>();
  const navigate = useAppNavigate();
  const queryClient = useQueryClient();

  const [state, setState] = useAppState({
    timer: 0,
  });

  useEffect(() => {
    clearTimeout(timerRef.current);
    timerRef.current = setInterval(() => {
      const remaining = Math.floor(
        (new Date(parseInt(props.error?.response?.data?.data)).getTime() -
          new Date().getTime()) /
          1000
      );
      setState((prevState) => ({ ...prevState, timer: remaining }));
    }, 100);

    if (state.timer! === 0) {
      setState((prevState) => ({ ...prevState, timer: null! }));
      clearInterval(timerRef.current);
    } else if (state.timer <= 1 && state.timer !== null) {
      return props.onClose;
    } else {
      return () => {
        clearInterval(timerRef.current);
      };
    }
  }, [props, state, setState]);

  const errorDescription = useErrorHandler({
    code: props.error?.response?.data?.code || 12,
  });

  const handleCloseModal = () => {
    const codesToInvalidate = [20, 30, 2001, 2002];
    if (codesToInvalidate.includes(props.error?.response?.data?.code)) {
      queryClient.invalidateQueries({
        queryKey: [
          QueryKeys.userQuery,
          QueryKeys.adminQuery,
          QueryKeys.getMeQuery,
          QueryKeys.verifyQuery,
          QueryKeys.profileMutationQuery,
          QueryKeys.loginMutationQuery,
          QueryKeys.logoutMutationQuery,
          QueryKeys.fisrtLoginMutationQuery,
        ],
      });
      navigate("/");
    }
    props.onClose();
  };

  return (
    <Modal onClose={handleCloseModal} title={props.title} footer={false}>
      <span>
        {props.error?.response?.data?.data
          ? `${errorDescription} ${
              typeof state.timer === "number" ? state.timer : ""
            }`
          : errorDescription}
      </span>
    </Modal>
  );
}
