import { Expose, Type } from "class-transformer";
import { ValidateNested } from "class-validator";
import { ParentEntity } from "../../entity"

export class MutateParentDTO {
  @Expose()
  @ValidateNested()
  @Type(() => ParentEntity)
  parent!: ParentEntity;
}
