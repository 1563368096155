import { Expose, Type } from "class-transformer";
import { ValidateNested } from "class-validator";
import { TierEntity } from "../../entity";

export class MutateTierDTO {
  @Expose()
  @ValidateNested()
  @Type(() => TierEntity)
  tier!: TierEntity;
}
