import { Expose, Type } from "class-transformer";
import { ValidateNested } from "class-validator";
import { ChildEntity } from "../../entity"

export class MutateChildDTO {
  @Expose()
  @ValidateNested()
  @Type(() => ChildEntity)
  child!: ChildEntity;
}
