import { Expose, Type } from "class-transformer";
import { IsArray, IsNumber, ValidateNested } from "class-validator";
import { LinesOfPoetriesEntity } from "../../entity";

export class GetAllLineOfPoetryDTO {
  @Expose()
  @ValidateNested()
  @IsArray()
  @Type(() => LinesOfPoetriesEntity)
  data!: LinesOfPoetriesEntity[];

  @Expose()
  @IsNumber()
  count!: number;

  @Expose()
  @IsNumber()
  page!: number;

  @Expose()
  @IsNumber()
  pageSize!: number;
}
