import { Expose } from "class-transformer";
import { IsDateString, IsDefined, IsNumber, IsString } from "class-validator";

export class FactEntity {
  @Expose()
  @IsDefined()
  @IsNumber()
  id!: number;

  @Expose()
  @IsDefined()
  @IsString()
  title!: string;

  @Expose()
  @IsDefined()
  @IsString()
  description!: string;

  @Expose()
  @IsDefined()
  @IsDateString()
  updated!: string;

  @Expose()
  @IsDefined()
  @IsDateString()
  created!: string;
}
