import { Expose } from "class-transformer";
import {
  IsBoolean,
  IsDateString,
  IsDefined,
  IsNumber,
  IsOptional,
  IsString,
} from "class-validator";
export class DisappearingEntity {
  @Expose()
  @IsDefined()
  @IsNumber()
  id!: number;

  @Expose()
  @IsDefined()
  @IsString()
  option!: string;

  @Expose()
  @IsOptional()
  @IsBoolean()
  isCorrect?: boolean;

  @Expose()
  @IsOptional()
  @IsDateString()
  updated?: string;

  @Expose()
  @IsOptional()
  @IsDateString()
  created?: string;
}
