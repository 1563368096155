import { useAppNavigate } from "../../../common";
import { DeleteIcon, DetailsIcon, EditIcon, SortIcon } from "../../../components"
import { ParentEntity } from "../../../entity";

type ParentColumnProps = {
  handleUpdatedOrder: (value: string) => void;
  updatedOrder: "ASC" | "DESC" | "";
  handleCreatedOrder: (value: string) => void;
  createdOrder: "ASC" | "DESC" | "";
  handleNameOrder: (value: string) => void;
  nameOrder: "ASC" | "DESC" | "";
  handleEmailOrder: (value: string) => void;
  emailOrder: "ASC" | "DESC" | "";
  handleNicknameOrder: (value: string) => void;
  nicknameOrder: "ASC" | "DESC" | "";
  handleDeleteModalVisible: () => void;
  handleEditModalVisible: (id: number) => void;
};

export function ParentColumns(props: ParentColumnProps) {
  const navigate = useAppNavigate();

  const handleOpenDeleteModal = (id: number) => {
    props.handleDeleteModalVisible();
    navigate("", { state: { id } });
  };

  const handleOpenEditModal = (id: number) => {
    props.handleEditModalVisible(id);
    navigate("", { state: { id } });
  };

  return [
    {
      title: "parent-page-table-header-profile-picture",
      dataIndex: "pictureUrl",
      render: (record: ParentEntity) => {
        return (
          <img
            className="parent-profile-picture"
            src={record?.pictureUrl ?? "/pictures/image-placeholder-icon.png"}
            alt={record?.name}
          />
        );
      },
    },
    {
      title: "parent-page-table-header-name",
      dataIndex: "name",
      suffix: (
        <SortIcon
          order={props.nameOrder}
          orderFunction={props.handleNameOrder}
        />
      ),
    },
    {
      title: "parent-page-table-header-email",
      dataIndex: "email",
      suffix: (
        <SortIcon
          order={props.emailOrder}
          orderFunction={props.handleEmailOrder}
        />
      ),
    },
    {
      title: "parent-page-table-header-nickname",
      dataIndex: "nickname",
      suffix: (
        <SortIcon
          order={props.nicknameOrder}
          orderFunction={props.handleNicknameOrder}
        />
      ),
    },
    {
      title: "parent-page-table-header-notifications",
      dataIndex: "notifications",
      render: (record: ParentEntity) => {
        return (
          <div
            className={`activity-circle ${
              record?.notifications ? "active" : ""
            }`}
          />
        );
      },
    },
    {
      title: "parent-page-table-header-sound-effect",
      dataIndex: "soundEffect",
      render: (record: ParentEntity) => {
        return (
          <div
            className={`activity-circle ${
              record?.soundEffects ? "active" : ""
            }`}
          />
        );
      },
    },
    {
      title: "parent-page-table-header-updated",
      dataIndex: "created",
      suffix: (
        <SortIcon
          order={props.createdOrder}
          orderFunction={props.handleCreatedOrder}
        />
      ),
      render: (record: ParentEntity) => {
        return <span>{new Date(record?.created).toLocaleDateString()}</span>;
      },
    },
    {
      title: "parent-page-table-header-created",
      dataIndex: "updated",
      suffix: (
        <SortIcon
          order={props.updatedOrder}
          orderFunction={props.handleUpdatedOrder}
        />
      ),
      render: (record: ParentEntity) => {
        return <span>{new Date(record?.updated).toLocaleDateString()}</span>;
      },
    },
    {
      title: "parent-page-table-header-edit",
      dataIndex: "edit",
      render: (record: ParentEntity) => {
          return <EditIcon onClick={() => handleOpenEditModal(record?.id)} />;
      },
    },
    {
      title: "parent-page-table-header-children",
      dataIndex: "children",
      render: (record: ParentEntity) => {
        return <DetailsIcon onClick={() => navigate(`${record?.id}`)} />;
      },
    },
    {
      title: "parent-page-table-header-delete",
      dataIndex: "delete",
      render: (record: ParentEntity) => {
        return <DeleteIcon onClick={() => handleOpenDeleteModal(record?.id)} />;
      },
    },
  ];
}
