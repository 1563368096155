import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ErrorModal, Form, Input, Modal, ValidationErrorModal } from "../../../components";
import { ParentEntity } from "../../../entity"
import { PageType, QueryKeys, mutationHandler, useAppState, useAppTranslation } from "../../../common";
import { modifyParentAction } from "../../../actions"

interface ParentEditModalProps {
  parent: ParentEntity | null;
  close: () => void;
  latestQueryKey: any;
}

export const ParentEditModal = (props: ParentEditModalProps) => {
    const queryClient = useQueryClient();
  const { t } = useAppTranslation();

  const [state, setState] = useAppState({
      name: props.parent?.name || ``,
      nickname: props.parent?.nickname || ``,
      validationErrorMessage: `` as string | null
  });


  const checkForm = () => {
      if (state.name.trim().length === 0) {
          setState({
              ...state,
              validationErrorMessage: `A név kitöltése kötelező!`,
          });
          return false;
      }
      if (state.nickname.trim().length === 0) {
          setState({
              ...state,
              validationErrorMessage: `A becenév kitöltése kötelező!`,
          });
          return false;
      }
      return true;
  };

  const mutateParent = useMutation({
    mutationKey: [QueryKeys.mutateParentQuery],
    mutationFn: (data: any) => modifyParentAction(data),
    onMutate: async (newParent) => {
      await queryClient.cancelQueries({ queryKey: props.latestQueryKey });
      const previousParents = queryClient.getQueryData([props.latestQueryKey]);
      queryClient.setQueryData(
        props.latestQueryKey,
        (old: PageType<ParentEntity>) => {
          const handler = mutationHandler({
            dataLength: old.data.length,
            pageSize: old.pageSize,
            count: old.count,
            oldData: old.data,
            newData: newParent,
          });
            console.log(`4`)
          return {
            page: old.page,
            pageSize: old.pageSize,
            count: handler.newCount,
            data: handler.newData,
          };
        }
      );

      return { previousParents };
    },
    onSuccess() {
      props.close();
    },
    onError(error, variables, context) {
      queryClient.setQueryData(
        [props.latestQueryKey],
        context?.previousParents
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.parentsQuery],
      });
    },
  });

  const handleCloseErrorModal = () => {
    mutateParent.reset();
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      name: e.target.value,
    }));
  };

  const handleNicknameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      nickname: e.target.value,
    }));
  };

  const submit = async () => {
    if (!checkForm()) {
      return;
    }
    const parent = {
      id: props.parent ? props.parent.id : null,
      name: state.name,
      nickname: state.nickname
    };

    mutateParent.mutate(parent);
  };

  return (
    <div>
      <section>
        <Modal
          onClose={props.close}
          title={`${t(`parent-page-parent`)} ${ t(`parent-page-edit`) }`}
          footer={true}
          submitText={`Módosítás`}
          cancelText={t(`parent-page-delete-cancel-button-title`)}
          handleSubmit={submit}
          loading={mutateParent.isPending}
        >
          <div className="parent-modal">
            <Form onSubmit={submit}>
              <Input
                onChange={handleNameChange}
                value={state.name}
                placeholder={t(`parent-page-parent-name`)}
                className="parent-modal-input"
                type="text"
              />

              <Input
                onChange={handleNicknameChange}
                value={state.nickname}
                placeholder={t(`parent-page-parent-nickname`)}
                className="parent-modal-input"
                type="text"
              />
            </Form>

            {state.validationErrorMessage && (
              <ValidationErrorModal
                error={state.validationErrorMessage}
                onClose={() =>
                  setState({
                    ...state,
                    validationErrorMessage: null,
                  })
                }
              />
            )}
          </div>
        </Modal>
      </section>

      <section>
        {mutateParent.isError && (
          <ErrorModal
            error={mutateParent.error}
            onClose={handleCloseErrorModal}
            title={ t("parent-page-mutate-parent-form-error-modal-modify-title") }
          />
        )}
      </section>
    </div>
  );
};
