import { Expose, Type } from "class-transformer";
import { ValidateNested } from "class-validator";
import { PoetryChatEntity } from "../../entity"

export class MutatePoetryChatDTO {
  @Expose()
  @ValidateNested()
  @Type(() => PoetryChatEntity)
  preparationChat!: PoetryChatEntity;
}
