import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  DeleteIcon,
  EditIcon,
  ErrorModal,
  Modal,
  ValidationErrorModal,
} from "../../../components";
import { PoetryQuizAnswerEntity, PoetryQuizEntity } from "../../../entity";
import { QueryKeys, useAppState, useAppTranslation } from "../../../common";
import {
  createPoetryQuizAnswerAction,
  deletePoetryQuizAnswerAction,
  modifyPoetryQuizAnswerAction,
  modifyPoetryQuizCorrectAnswerAction,
} from "../../../actions";
import { InputModal } from "./input-modal";

interface PoetryQuizAnswersModalProps {
  poetryQuiz: PoetryQuizEntity | null;
  close: () => void;
}

export const PoetryQuizAnswersModal = (props: PoetryQuizAnswersModalProps) => {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();
  const [state, setState] = useAppState({
    showInput: false,
    selectedAnswer: null as PoetryQuizAnswerEntity | null,
    validationErrorMessage: null as string | null,
  });

  const mutateQuizAnswer = useMutation({
    mutationKey: [
      state.selectedAnswer === null
        ? QueryKeys.createQuizAnswerQuery
        : QueryKeys.modifyQuizAnswerQuery,
    ],
    mutationFn: (data: any) =>
      state.selectedAnswer === null
        ? createPoetryQuizAnswerAction(data)
        : modifyPoetryQuizAnswerAction(data),
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.poetryQuizQuery],
      });
      setState({ ...state, selectedAnswer: null, showInput: false });
    },
  });

  const checkForm = (answer: string) => {
    if (answer.trim().length === 0) {
      setState({
        ...state,
        validationErrorMessage: `A válasz kitöltése kötelező!`,
      });
      return false;
    }

    return true;
  };

  const handleMutateQuizAnswer = (answer: string) => {
    if (!checkForm(answer)) {
      return;
    }
    mutateQuizAnswer.mutate({
      id: state.selectedAnswer?.id,
      answer: answer,
      quizId: props.poetryQuiz!.id,
    });
  };

  const mutateCorrectAnswer = useMutation({
    mutationKey: [QueryKeys.mutateQuizCorrectAnswerQuery],
    mutationFn: modifyPoetryQuizCorrectAnswerAction,
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.poetryQuizQuery],
      });
    },
  });

  const setCorrectAnswer = (id: number) => {
    mutateCorrectAnswer.mutate(id);
  };

  const deleteAnswer = useMutation({
    mutationKey: [QueryKeys.deleteQuizAnswerQuery],
    mutationFn: deletePoetryQuizAnswerAction,
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.poetryQuizQuery],
      });
    },
  });

  const onDelete = (id: number) => {
    deleteAnswer.mutate({ id });
  };

  const handleCloseErrorModal = () => {
    if (mutateQuizAnswer.isError) {
      mutateQuizAnswer.reset();
    } else if (mutateCorrectAnswer.isError) {
      mutateCorrectAnswer.reset();
    } else {
      deleteAnswer.reset();
    }
  };

  return (
    <Modal
      onClose={props.close}
      title={t(`poetry-quiz-page-poetry-answers`)}
      footer={true}
      cancelText={t(`poetry-quiz-answers-modal-cancel-button-title`)}
    >
      <div className="poetry-quiz-answers-modal">
        <Button
          onClick={() =>
            setState({
              ...state,
              selectedAnswer: null,
              showInput: true,
            })
          }
          title="+"
          htmlType="button"
          creationButton
        />
        <div className="answers-container">
          {props.poetryQuiz!.answers.map((answer) => (
            <div className="answer" key={answer.id}>
              <div className="answer__text">{answer.answer}</div>

              <div className="answer__solution">
                <div
                  onClick={() => setCorrectAnswer(answer.id)}
                  className={`activity-circle ${
                    answer.isCorrect ? "active" : ""
                  }`}
                />
              </div>

              <div className="answer__edit">
                <EditIcon
                  onClick={() =>
                    setState({
                      ...state,
                      selectedAnswer: answer,
                      showInput: true,
                    })
                  }
                />
              </div>

              <div className="answer__delete">
                <DeleteIcon onClick={() => onDelete(answer.id)} />
              </div>
            </div>
          ))}

          {props.poetryQuiz!.answers.length === 0 ? (
            <div style={{ padding: 15 }}>
              {t(`poetry-quiz-answer-page-no-answer`)}
            </div>
          ) : null}
        </div>

        {state.showInput ? (
          <InputModal
            title={
              state.selectedAnswer
                ? t(`poetry-quiz-answer-page-modify-answer`)
                : t(`poetry-quiz-answer-page-create-answer`)
            }
            cancelText={t(`poetry-quiz-answer-page-answer-modal-cancel`)}
            submitText={t(`poetry-quiz-answer-page-answer-modal-save`)}
            placeholder={t(`poetry-quiz-answer-page-answer`)}
            save={handleMutateQuizAnswer}
            close={() =>
              setState({
                ...state,
                showInput: false,
                selectedAnswer: null,
              })
            }
            text={
              state.selectedAnswer ? state.selectedAnswer.answer : undefined
            }
          />
        ) : null}

        <section>
          {(mutateQuizAnswer.isError ||
            mutateCorrectAnswer.isError ||
            deleteAnswer.isError) && (
            <ErrorModal
              error={
                mutateQuizAnswer.error ||
                mutateCorrectAnswer.error ||
                deleteAnswer.error
              }
              onClose={handleCloseErrorModal}
              title={
                mutateCorrectAnswer.isError
                  ? t("poetry-quiz-answers-modify-correct-answer-form-error")
                  : deleteAnswer.isError
                  ? t("poetry-quiz-answers-delete-answer-form-error")
                  : state.selectedAnswer !== null
                  ? t(
                      "poetry-quiz-answers-mutate-answer-form-error-modal-modify-title"
                    )
                  : t(
                      "poetry-quiz-answers-page-mutate-answer-form-error-modal-create-title"
                    )
              }
            />
          )}
        </section>

        {state.validationErrorMessage && (
          <ValidationErrorModal
            error={state.validationErrorMessage}
            onClose={() =>
              setState({
                ...state,
                validationErrorMessage: null,
              })
            }
          />
        )}
      </div>
    </Modal>
  );
};
