import { useAppNavigate, useAppTranslation } from "../../../common";
import {
  DeleteIcon,
  FilterIcon,
  ModifyIcon,
  SortIcon,
} from "../../../components";
import { VersionEntity } from "../../../entity";
import { criticalFilterOptions, platformFilterOptions } from "../static";

type VersionColumnProps = {
  handlePlatformChange: (value: string) => void;
  handleCriticalChange: (value: string) => void;
  handleCreatedOrder: (value: string) => void;
  createdOrder: "ASC" | "DESC" | "";
  handleDeleteModalVisible: () => void;
  handleMutateModalVisible: (value: boolean) => void;
};

export function VersionColumns(props: VersionColumnProps) {
  const navigate = useAppNavigate();
  const { t } = useAppTranslation();

  const handleOpenDeleteModal = (id: number) => {
    props.handleDeleteModalVisible();
    navigate("", { state: { id } });
  };

  const handleOpenModifyModal = (version: VersionEntity) => {
    props.handleMutateModalVisible(true);
    navigate("", { state: { version } });
  };

  return [
    {
      title: "version-page-table-header-version",
      dataIndex: "version",
    },
    {
      title: "version-page-table-platform",
      dataIndex: "platform",
      suffix: (
        <FilterIcon
          onFilter={props.handlePlatformChange}
          filterOptions={platformFilterOptions}
        />
      ),
      render: (record: VersionEntity) => {
        const platform = platformFilterOptions.find(
          (platform) => platform.value === record?.platform
        );
        return <span>{t(platform?.label!)}</span>;
      },
    },
    {
      title: "version-page-table-critical",
      dataIndex: "critical",
      suffix: (
        <FilterIcon
          onFilter={props.handleCriticalChange}
          filterOptions={criticalFilterOptions}
        />
      ),
      render: (record: VersionEntity) => {
        return (
          <div
            className={`activity-circle ${record?.critical ? "" : "active"}`}
          />
        );
      },
    },
    {
      title: "version-page-table-header-created",
      dataIndex: "created",
      suffix: (
        <SortIcon
          order={props.createdOrder}
          orderFunction={props.handleCreatedOrder}
        />
      ),
      render: (record: VersionEntity) => {
        return <span>{new Date(record?.created!).toLocaleDateString()}</span>;
      },
    },
    {
      title: "version-page-table-header-updated",
      dataIndex: "updated",
      render: (record: VersionEntity) => {
        return <span>{new Date(record?.updated!).toLocaleDateString()}</span>;
      },
    },
    {
      title: "version-page-table-header-modify",
      dataIndex: "modify",
      render: (record: VersionEntity) => {
        return <ModifyIcon onClick={() => handleOpenModifyModal(record)} />;
      },
    },
    {
      title: "version-page-table-header-delete",
      dataIndex: "delete",
      render: (record: VersionEntity) => {
        return <DeleteIcon onClick={() => handleOpenDeleteModal(record?.id)} />;
      },
    },
  ];
}
