import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  QueryKeys,
  RoleEnum,
  passwordValidator,
  useAppNavigate,
  useAppState,
  useAppTranslation,
} from "../../common";
import { Button, ErrorModal, Form, Input, Tooltip } from "../../components";
import { fistLoginAdminAction } from "../../actions";
import "./first-login.scss";

export function FisrtLoginPage() {
  const queryClient = useQueryClient();
  const { t } = useAppTranslation();
  const navigate = useAppNavigate();
  const [state, setState] = useAppState({
    password: "",
    passwordCheck: "",
    modalVisible: false,
  });

  const firstLogin = useMutation({
    mutationKey: [QueryKeys.fisrtLoginMutationQuery],
    mutationFn: fistLoginAdminAction,
    onSuccess(data) {
      queryClient.removeQueries({
        queryKey: [QueryKeys.userQuery],
        exact: true,
      });
      navigate(
        data.role === RoleEnum.SUPERVISOR ? "/cms/admins" : "/cms/parents"
      );
    },
    onMutate() {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.userQuery] });
    },
    onError() {
      setState((prevState) => ({ ...prevState, modalVisible: true }));
    },
  });

  const handlePasswordCheckChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setState((prevState) => ({ ...prevState, passwordCheck: e.target.value }));
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, password: e.target.value }));
  };

  const handleChangePassword = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    firstLogin.mutate({
      password: state.password,
      newPassword: state.passwordCheck,
    });
  };

  const handleCloseModal = () => {
    setState((prevState) => ({ ...prevState, modalVisible: false }));
  };

  const isPasswordValid = (pwd: string) => {
    return passwordValidator({ password: pwd });
  };

  const isButtonDisabled =
    state.password !== state.passwordCheck ||
    !isPasswordValid(state.password) ||
    !isPasswordValid(state.passwordCheck);

  return (
    <main>
      <section>
        <div className="fisrt-login-title">
          <span>{t("first-login-page-title")}</span>
        </div>
      </section>
      <section className="login-form">
        <Form onSubmit={handleChangePassword}>
          <Tooltip
            content={
              state.password.length > 0 && !isPasswordValid(state.password)
                ? t("fist-login-page-password-input-error-text")
                : ""
            }
          >
            <Input
              type="password"
              className={`login-input ${
                state.password.length > 0 && !isPasswordValid(state.password)
                  ? "error"
                  : ""
              }`}
              value={state.password}
              onChange={handlePasswordChange}
              placeholder={t("fist-login-page-password-input-placeholder")}
            />
          </Tooltip>
          <Tooltip
            content={
              state.passwordCheck.length > 0 &&
              (!isPasswordValid(state.passwordCheck) ||
                state.passwordCheck !== state.password)
                ? t("fist-login-page-password-check-input-error-text")
                : ""
            }
          >
            <Input
              type="password"
              className={`login-input ${
                state.passwordCheck.length > 0 &&
                (!isPasswordValid(state.passwordCheck) ||
                  state.passwordCheck !== state.password)
                  ? "error"
                  : ""
              }`}
              value={state.passwordCheck}
              onChange={handlePasswordCheckChange}
              placeholder={t(
                "fist-login-page-password-check-input-placeholder"
              )}
            />
          </Tooltip>

          <div className="button-container">
            <Button
              htmlType="submit"
              title={t("fist-login-page-button-title")}
              loading={firstLogin.isPending}
              disabled={isButtonDisabled || firstLogin.isPending}
            />
          </div>
        </Form>
      </section>
      <section>
        {state.modalVisible && (
          <ErrorModal
            onClose={handleCloseModal}
            title={t("fist-login-page-modal-error-title")}
            error={firstLogin.error}
          />
        )}
      </section>
    </main>
  );
}
