export const typeFilterOptions = [
  { value: "LISTENER", label: "achievement-type-filter-listener-option" },
  {
    value: "DISAPPEARING_GAME",
    label: "achievement-type-filter-disappearing-game-option",
  },
  { value: "PUZZLE_GAME", label: "achievement-type-filter-puzzle-game-option" },
  { value: "EMOJI", label: "achievement-type-filter-emoji-option" },
  { value: "SOCCER", label: "achievement-type-filter-soccer-option" },
  { value: "RECITATION", label: "achievement-type-filter-recitation-option" },
  {
    value: "PARENT_RECITATION",
    label: "achievement-type-filter-parent-recitation-option",
  },
  { value: "QUIZ", label: "achievement-type-filter-quiz-option" },
  {
    value: "LEARNING_ROW",
    label: "achievement-type-filter-learning-row-option",
  },
  { value: "TIER", label: "achievement-type-filter-tier-option" },
  { value: "WEEKEND", label: "achievement-type-filter-weekend-option" },
];
