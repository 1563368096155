import { Expose } from "class-transformer";
import { IsDefined, IsString } from "class-validator";
import { RoleEnum } from "../../common";

export class ChangePassowrdDTO {
  @Expose()
  @IsDefined()
  @IsString()
  role!: RoleEnum;
}
