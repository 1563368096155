import { Expose } from "class-transformer";
import {
  IsDateString,
  IsDefined,
  IsNumber,
  IsOptional,
  IsString,
} from "class-validator";

export class LinesOfPoetriesEntity {
  @Expose()
  @IsDefined()
  @IsNumber()
  id!: number;

  @Expose()
  @IsDefined()
  @IsString()
  text!: string;

  @Expose()
  @IsOptional()
  @IsString()
  audioYoungUrl?: string | null;

  @Expose()
  @IsOptional()
  @IsString()
  audioOldUrl?: string | null;

  @Expose()
  @IsOptional()
  @IsNumber()
  index?: number;

  @Expose()
  @IsOptional()
  @IsNumber()
  stanza?: number;

  @Expose()
  @IsOptional()
  @IsNumber()
  unit?: number;

  @Expose()
  @IsOptional()
  @IsDateString()
  updated?: string;

  @Expose()
  @IsOptional()
  @IsDateString()
  created?: string;
}
