import { Expose } from "class-transformer";
import { IsDateString, IsDefined, IsNumber, IsOptional, IsString } from "class-validator";
export class CategoryEntity {
  @Expose()
  @IsDefined()
  @IsNumber()
  id!: number;

  @Expose()
  @IsDefined()
  @IsString()
  title!: string;

  @Expose()
  @IsOptional()
  @IsDateString()
  updated?: string;

  @Expose()
  @IsOptional()
  @IsDateString()
  created?: string;
}
