import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  QueryKeys,
  mutationHandler,
  useAppLocation,
  useAppState,
  useAppTranslation,
  PageType,
} from "../../../common";
import { ErrorModal, Modal } from "../../../components";
import { deleteCategoryAction } from "../../../actions";
import { CategoryEntity } from "../../../entity";

type CategoryDeleteModalProps = {
  handleDeleteModalVisible: () => void;
  handleSetPage: (value: number) => void;
};

export function CategoryDeleteModal(props: CategoryDeleteModalProps) {
  const { t } = useAppTranslation();
  const location = useAppLocation();

  const queryClient = useQueryClient();
  const [state, setState] = useAppState({
    errorModalVisible: false,
  });

  const handleModalVisibility = () => {
    setState((prevState) => ({
      ...prevState,
      errorModalVisible: !prevState.errorModalVisible,
    }));
  };

  const deleteCategory = useMutation({
    mutationKey: [QueryKeys.deleteCategoryQuery],
    mutationFn: deleteCategoryAction,
    onSuccess() {
      props.handleDeleteModalVisible();
      queryClient.invalidateQueries({ queryKey: [QueryKeys.categoryQuery] });
      let page = 1;
      queryClient.setQueryData(
        [QueryKeys.categoryQuery],
        (old: PageType<CategoryEntity>) => {
          const handler = mutationHandler({
            dataLength: old.data.length,
            pageSize: old.pageSize,
            count: old.count,
          });
          page = handler.page;
          return {
            page: old.page,
            pageSize: old.pageSize,
            count: handler.page,
            data: old.data,
          };
        }
      );
      props.handleSetPage(page);
    },
    onError() {
      handleModalVisibility();
    },
  });

  const handleDeleteCategory = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    deleteCategory.mutate({ id: location.state?.id });
  };

  return (
    <div>
      <section>
        <Modal
          onClose={props.handleDeleteModalVisible}
          title={t("category-page-delete-modal-title")}
          footer
          submitText={t("category-page-delete-submit-button-title")}
          cancelText={t("category-page-delete-cancel-button-title")}
          handleSubmit={handleDeleteCategory}
          loading={deleteCategory.isPending}
        >
          <span>{t("category-page-delete-category-question-label")}</span>
        </Modal>
      </section>
      <section>
        {state.errorModalVisible && (
          <ErrorModal
            error={deleteCategory.error}
            onClose={handleModalVisibility}
            title={t("category-page-delete-category-error-modal-title")}
          />
        )}
      </section>
    </div>
  );
}
