import { NavLink, useLocation } from "react-router-dom";
import { Logo } from "../icon";
import { menuButtons } from "./components";
import { RoleEnum, useAppTranslation } from "../../common";
import "./menu.scss";

type MenuProps = {
  adminRole: string;
};

export function Menu(props: MenuProps) {
  const { t } = useAppTranslation();
  const location = useLocation();

  const filteredMenuButtons = menuButtons.filter((mb) =>
    props.adminRole === RoleEnum.SUPERVISOR
      ? mb
      : mb.title !== "menu-admins-link"
  );

  return (
    <main className="menu">
      <section className="logo-container">
        <Logo />
      </section>
      <section className="menu-items">
        {filteredMenuButtons.map((mb, index) => (
          <NavLink
            key={index}
            className={`link ${
              location.pathname.includes(mb.navigation) ? "active" : ""
            }`}
            to={mb.navigation}
          >
            {t(mb.title)}
          </NavLink>
        ))}
      </section>
      <section className="rights">Verses app 2024 ©</section>
    </main>
  );
}
