import axios from "axios";
import { ListAllCategoryDTO } from "../../dto";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";

let body: ListAllCategoryDTO;

export async function listAllCategoryAction() {
  const token = localStorage.getItem("token");

  return await axios
    .get(`${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/categories/list-all`, {
      headers: {
        "Content-Type": "application/json",
        "app-platform": "cms",
        Authorization: `Bearer ${token}`,
      }
    })
    .then(async (res) => {
      body = plainToClass(ListAllCategoryDTO, res.data);
      const errors = await validate(body);

      if (errors.length > 0) {
        throw errors;
      }

      return res.data;
    });
}
