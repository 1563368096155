import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  QueryKeys,
  useAppLocation,
  useAppState,
  useAppTranslation,
} from "../../../common";
import {
    ErrorModal, Form, Input, Modal, Upload, ValidationErrorModal
} from "../../../components"
import { Selector } from "../../../components/selector/selector"
import {
  createAchievementAction,
  modifyAchievementAction,
} from "../../../actions";
import { levelFilterOptions, typeFilterOptions } from "../static";

type AchievementMutationFormProps = {
  modify: boolean;
  handleMutationModalVisible: (value: boolean) => void;
};

export function AchievementMutationForm(props: AchievementMutationFormProps) {
  const queryClient = useQueryClient();
  const location = useAppLocation();
  const { t } = useAppTranslation();

  const [state, setState] = useAppState({
    name: location.state?.achievement.name ?? "",
    description: location.state?.achievement.description ?? "",
    badge: null as unknown as File,
    type: location.state?.achievement.type ?? "Hallgató",
    level: location.state?.achievement.level ?? (1 as unknown as number),
    errorModalVisible: false,
    validationErrorMessage: null as string | null
  });

  const handleModalVisibility = () => {
    setState((prevState) => ({
      ...prevState,
      errorModalVisible: !prevState.errorModalVisible,
    }));
  };

  const mutateAchievement = useMutation({
    mutationKey: [
      props.modify
        ? QueryKeys.modifyAchievementQuery
        : QueryKeys.createAchievementQuery,
    ],
    mutationFn: (data: any) =>
      props.modify
        ? modifyAchievementAction(data)
        : createAchievementAction(data),
    onSuccess() {
      props.handleMutationModalVisible(false);
      queryClient.invalidateQueries({ queryKey: [QueryKeys.achievementQuery] });
    },
    onError(error) {
      console.log(error);
      handleModalVisibility();
    },
  });

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, name: e.target.value }));
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, description: e.target.value }));
  };

  const handleTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setState((prevState) => ({ ...prevState, type: e.target.value }));
  };

  const handleLevelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setState((prevState) => ({ ...prevState, level: e.target.value }));
  };

  const handleBadgeChange = (value: File) => {
    setState((prevState) => ({ ...prevState, badge: value }));
  };

    const checkForm = () => {
        if ( state.name.trim().length === 0 ) {
            setState( { ...state, validationErrorMessage: `A cím kitöltése kötelező!` } )
            return false
        }
        if ( state.description.trim().length === 0 ) {
            setState( { ...state, validationErrorMessage: `A leírás kitöltése kötelező!` } )
            return false
        }
        if ( state.type.trim().length === 0 ) {
            setState( { ...state, validationErrorMessage: `A típus kiválasztása kötelező!` } )
            return false
        }
        if ( state.level === null || (state.level.trim && state.level.trim().length === 0 ) ) {
            setState( { ...state, validationErrorMessage: `A szint kiválasztása kötelező!` } )
            return false
        }
        if ( !props.modify && state.badge === null ) {
            setState( { ...state, validationErrorMessage: `A kép kiválasztása kötelező!` } )
            return false
        }

        return true
    }

  const handleMutateAchievement = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!checkForm()) { return }
    mutateAchievement.mutate({
      name: state.name,
      description: state.description,
      type: state.type,
      level: state.level,
      badge: state.badge,
      id: props.modify ? location.state?.achievement.id : undefined!,
    });
  };

  return ( <div>
          <section>
              <Modal onClose={ () => props.handleMutationModalVisible( false ) } title={ props.modify ? t( "achievement-page-mutate-achievement-modal-modify-title" ) : t( "achievement-page-mutate-achievement-modal-create-title" ) } footer cancelText={ t( "achievement-page-mutate-achievement-modal-cancel-label" ) } submitText={ props.modify ? t( "achievement-page-mutate-achievement-modal-modify-submit-label" ) : t( "achievement-page-mutate-achievement-modal-create-submit-label" ) } handleSubmit={ handleMutateAchievement } loading={ mutateAchievement.isPending }>
                  <Form onSubmit={ handleMutateAchievement }>
                      <div className="achievement-form">
                          <Input type="text" maxLength={ 255 } className="mutate-modal-input" onChange={ handleNameChange } value={ state.name } placeholder={ t( "achievement-page-mutate-achievement-modal-name-placeholder" ) }/>
                          <Input type="text" maxLength={ 255 } className="mutate-modal-input" onChange={ handleDescriptionChange } value={ state.description } placeholder={ t( "achievement-page-mutate-achievement-modal-description-placeholder" ) }/>
                          <Selector name="type-selector" id="type-selector" handleSelectOption={ handleTypeChange } currentValue={ location.state?.achievement.type ?? "" } options={ typeFilterOptions } onlyLabel/>
                          <Selector name="level-selector" id="level-selector" handleSelectOption={ handleLevelChange } currentValue={ location.state?.achievement.level ?? "" } options={ levelFilterOptions } onlyLabel/>
                          <Upload type="picture" label={ t( "achievement-page-mutate-achievement-modal-badge-picture-title" ) } handleChangeUpload={ handleBadgeChange } file={ location.state?.achievement.badgeUrl ?? "" }/>
                      </div>
                  </Form>
              </Modal>
          </section>
          <section>
              { state.errorModalVisible && (
                  <ErrorModal error={ mutateAchievement.error } onClose={ handleModalVisibility } title={ props.modify ? t( "achievement-page-mutate-achievement-form-error-modal-modify-title" ) : t( "achievement-page-mutate-achievement-form-error-modal-create-title" ) }/> ) }
          </section>
          <section>
              { state.validationErrorMessage && (
                  <ValidationErrorModal error={ state.validationErrorMessage } onClose={ () => setState( {
                      ...state, validationErrorMessage: null
                  } ) }/> ) }
          </section>
      </div> );
}
