import { AchievementEntity } from "../../../entity";

export function ChildAchievementColumns() {
  return [
    {
      title: "child-page-child-achievement-table-header-achievement-picture",
      dataIndex: "pictureUrl",
      render: (record: AchievementEntity) => {
        return (
          <img
            className="child-profile-picture"
            src={record.badgeUrl ?? "/pictures/image-placeholder-icon.png"}
            alt={`${record.type}-${record.level}`}
          />
        );
      },
    },
    {
      title: "child-page-child-achievement-table-header-description",
      dataIndex: "description",
      render: (record: AchievementEntity) => {
        return <span>{record.description}</span>;
      },
    },
    {
      title: "child-page-child-achievement-table-header-type",
      dataIndex: "type",
      render: (record: AchievementEntity) => {
        return <span>{record.type}</span>;
      },
    },
    {
      title: "child-page-child-achievement-table-header-level",
      dataIndex: "level",
      render: (record: AchievementEntity) => {
        return <span>{record.level}</span>;
      },
    },
  ];
}
