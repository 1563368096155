import axios from "axios";
import { GetAllPoetryQuizDTO } from "../../dto";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";

type GetAllPoetryQuizProps = {
  page: number;
  pageSize: number;
  searchTerm?: string;
  poetryId: number;
};

let body: GetAllPoetryQuizDTO;

export async function getAllPoetryQuizAction(props: GetAllPoetryQuizProps) {
  const token = localStorage.getItem("token");

  const params = {
    page: props.page,
    pageSize: props.pageSize,
    question: props.searchTerm,
    poetryId: props.poetryId
  };

  return await axios
    .get(`${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/quizzes/get-all`, {
      headers: {
        "Content-Type": "application/json",
        "app-platform": "cms",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then(async (res) => {
      body = plainToClass(GetAllPoetryQuizDTO, res.data);
      const errors = await validate(body);

      if (errors.length > 0) {
        throw errors;
      }

      return res.data;
    });
}
