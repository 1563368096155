import { Expose, Type } from "class-transformer";
import { ValidateNested } from "class-validator";
import { AchievementEntity } from "../../entity";

export class MutateAchievementDTO {
  @Expose()
  @ValidateNested()
  @Type(() => AchievementEntity)
  achievement!: AchievementEntity;
}
