import axios from "axios";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { SuccessDTO } from "../../dto";

let body: SuccessDTO;

export async function validateTokenAction() {
  const getQueryParam = new URLSearchParams(window.location.search);
  const token = getQueryParam.get("token");
  console.log(token);

  return await axios
    .get(`${process.env.REACT_APP_API_ORIGIN}/1.0.0/auth/validate-token`, {
      headers: {
        "Content-Type": "application/json",
        "app-platform": "cms",
      },
      params: { token },
    })
    .then(async (res) => {
      console.log(res);
      body = plainToClass(SuccessDTO, res.data);
      await validate(body);
      const errors = await validate(body);
      if (errors.length > 0) {
        console.log(errors);
        throw errors;
      }

      return res.data;
    });
}
