type CloseIconProps = {
  onClick: () => void;
};

export function CloseIcon(props: CloseIconProps) {
  return (
    <img
      className="clickable-icon"
      src="/pictures/close-icon.png"
      alt="close-icon"
      onClick={props.onClick}
    />
  );
}
