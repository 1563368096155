import axios from "axios";
import { GetAllDisappearingDTO } from "../../dto";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";

type GetAllDisappearingProps = {
  lineOfPoetryId: number;
  page: number;
  pageSize: number;
  word?: string;
};

let body: GetAllDisappearingDTO;

export async function getAllDisappearingAction(props: GetAllDisappearingProps) {
  const token = localStorage.getItem("token");

  const params = {
    lineOfPoetryId: props.lineOfPoetryId,
    page: props.page,
    pageSize: props.pageSize,
    word: props.word,
  };

  return await axios
    .get(
      `${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/disappearing-game-options/get-all`,
      {
        headers: {
          "Content-Type": "application/json",
          "app-platform": "cms",
          Authorization: `Bearer ${token}`,
        },
        params,
      }
    )
    .then(async (res) => {
      body = plainToClass(GetAllDisappearingDTO, res.data);
      const errors = await validate(body);

      if (errors.length > 0) {
        throw errors;
      }

      return res.data;
    });
}
