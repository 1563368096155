import { useAppState, useAppTranslation } from "../../common";
import { InfoModal } from "../modal";
import "./upload.scss";

type UploadProps = {
  type: "picture" | "gif" | "audio";
  handleChangeUpload: (value: File) => void;
  file?: string;
  label: string;
  audioId?: string;
};

export function Upload(props: UploadProps) {
  const { t } = useAppTranslation();
  const [state, setState] = useAppState({
    errorMessage: "",
    infoModalVisible: false,
    previewImage: props.file ?? "",
  });

  const handleErrorModalVisible = (message: string) => {
    setState((prevState) => ({
      ...prevState,
      errorMessage: message,
      infoModalVisible: !prevState.infoModalVisible,
    }));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList && fileList.length > 0) {
      const selectedFile = fileList[0];

      if (selectedFile.size <= 5 * 1024 * 1024) {
        props.handleChangeUpload(selectedFile);
      } else {
        handleErrorModalVisible(t("upload-file-size-too-big"));
      }

      const reader = new FileReader();
      reader.onload = () => {
        if (typeof reader.result === "string") {
          setState((prevState) => ({
            ...prevState,
            previewImage: reader.result as string,
          }));
        }
      };
      reader.readAsDataURL(selectedFile);
      if (props.type === "audio") {
        const audioElement = document.getElementById(
          props.audioId!
        ) as HTMLAudioElement;
        audioElement.src = URL.createObjectURL(selectedFile);
        audioElement.load(); // Load the new audio source
      }
    }
  };

  return (
    <div>
      <section className="picture-container">
        <label className="content-label">{props.label}</label>
        {props.type === "audio" ? (
          <div className="file-container">
            <audio controls className="audio" id={props.audioId!}>
              <source src={state.previewImage} type="audio/mpeg" />
            </audio>
            <input
              className="file-input"
              type="file"
              name="file-input"
              id="file-input"
              accept={".mp3,.mp3s,.m4a,.flac,.wav"}
              onChange={handleFileChange}
            />
          </div>
        ) : (
          <div
            className="file-container"
            style={{
              backgroundImage: `${state.previewImage? `url(${state.previewImage})`: "url(/pictures/higher-res-image-placeholder.jpg)"}`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            <input
              className="file-input"
              type="file"
              name="file-input"
              id="file-input"
              accept={props.type === "picture" ? ".png,.jpg,.jpeg" : ".gif"}
              onChange={handleFileChange}
            />
          </div>
        )}
      </section>
      <section>
        {state.infoModalVisible && (
          <InfoModal
            type="error"
            message={state.errorMessage}
            onClose={() => handleErrorModalVisible("")}
          />
        )}
      </section>
    </div>
  );
}
