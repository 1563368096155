import { useQuery } from "@tanstack/react-query";
import {
  QueryKeys,
  useAppNavigate,
  useAppState,
  useAppTranslation,
  useDebounce,
} from "../../common";
import { Button, ErrorModal, Input, Table } from "../../components";
import "./version.page.scss";
import { getAllVersionAction } from "../../actions";
import {
  VersionColumns,
  VersionDeleteModal,
  VersionMutationForm,
} from "./components";

export function VersionPage() {
  const navigate = useAppNavigate();
  const { t } = useAppTranslation();

  const [state, setState] = useAppState({
    page: 1,
    pageSize: 10,
    version: "",
    platform: "" as unknown as "android" | "ios",
    critical: "" as unknown as "true" | "false",
    createdOrder: "" as "ASC" | "DESC" | "",
    mutateModalVisible: false,
    errorModalVisible: false,
    deleteModalVisible: false,
    modify: false,
    searchTerm: "",
  });

  const getAllVersion = useQuery({
    queryKey: [
      QueryKeys.versionQuery,
      {
        page: state.page,
        pageSize: state.pageSize,
        version: state.searchTerm,
        platform: state.platform,
        critical: state.critical,
        createdOrder: state.createdOrder,
      },
    ],
    queryFn: () =>
      getAllVersionAction({
        page: state.page,
        pageSize: state.pageSize,
        version: state.searchTerm,
        platform: state.platform,
        critical: state.critical,
        createdOrder: state.createdOrder,
      }),
  });

  const handleSearch = useDebounce((searchTerm: string) => {
    setState((prevState) => ({ ...prevState, searchTerm: searchTerm }));
  }, 250);

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, version: e.target.value }));

    handleSearch(e.target.value);
  };

  const handlePlatformChange = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      platform: value as "android" | "ios",
    }));
  };

  const handleCriticalChange = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      critical: value as "true" | "false",
    }));
  };

  const handleCreatedOrder = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      createdOrder: value as "ASC" | "DESC" | "",
    }));
  };

  const handleMutateModalVisible = (modify: boolean) => {
    navigate("", { state: undefined });
    setState((prevState) => ({
      ...prevState,
      mutateModalVisible: !prevState.mutateModalVisible,
      modify,
    }));
  };

  const handleDeleteModalVisible = () => {
    setState((prevState) => ({
      ...prevState,
      deleteModalVisible: !prevState.deleteModalVisible,
    }));
  };

  const handleSetPage = (value: number) => {
    setState((prevState) => ({ ...prevState, page: value }));
  };

  const handleSetPageSize = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setState((prevState) => ({
      ...prevState,
      pageSize: parseInt(e.target.value),
    }));
  };

  const handleCloseErrorModal = () => {
    getAllVersion.refetch();
  };

  return (
    <main className="page">
      <div className="version-page">
        <section className="title">{t("version-page-title")}</section>
        <section className="search-bar-container">
          <Input
            onChange={handleSearchTermChange}
            value={state.version}
            placeholder={t("version-page-search-bar-placeholder")}
            type="search"
            className="searchbar"
          />
          <Button
            onClick={() => handleMutateModalVisible(false)}
            title="+"
            htmlType="button"
            creationButton
          />
        </section>
        <section>
          <Table
            columns={Object.values(
              VersionColumns({
                handleDeleteModalVisible,
                handleMutateModalVisible,
                createdOrder: state.createdOrder,
                handleCreatedOrder,
                handlePlatformChange,
                handleCriticalChange,
              })
            )}
            paginatorNeeded
            data={getAllVersion.data?.data}
            loading={getAllVersion.isLoading}
            page={getAllVersion.data?.page}
            pageSize={getAllVersion.data?.pageSize}
            allPage={getAllVersion.data?.count}
            handleSetPage={handleSetPage}
            handleSetPageSize={handleSetPageSize}
          />
        </section>
      </div>
      <section>
        {state.mutateModalVisible && (
          <VersionMutationForm
            modify={state.modify}
            handleMutationModalVisible={handleMutateModalVisible}
            latestQueryKey={[
              QueryKeys.versionQuery,
              {
                page: state.page,
                pageSize: state.pageSize,
                version: state.searchTerm,
                platform: state.platform,
                critical: state.critical,
                createdOrder: state.createdOrder,
              },
            ]}
          />
        )}
      </section>
      <section>
        {state.deleteModalVisible && (
          <VersionDeleteModal
            handleDeleteModalVisible={handleDeleteModalVisible}
          />
        )}
      </section>
      <section>
        {getAllVersion.isError && (
          <ErrorModal
            onClose={handleCloseErrorModal}
            title={t("version-page-error-modal-title")}
            error={getAllVersion.error}
          />
        )}
      </section>
    </main>
  );
}
