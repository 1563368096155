import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  PoetrySubPages,
  QueryKeys,
  useAppParams,
  useAppState,
  useAppTranslation,
  useDebounce,
} from "../../common";
import { Button, ErrorModal, Input, Table } from "../../components";
import {
  PoetryQuizAnswersModal,
  PoetryQuizColumns,
  PoetryQuizDeleteModal,
  PoetryQuizModal,
} from "./components";
import { PoetryQuizEntity } from "../../entity";
import { getAllPoetryQuizAction } from "../../actions/poetry-quiz";
import { PoetryQuizInfosModal } from "./components/poetry-quiz-infos-modal";
import "./poetry-quiz.page.scss";

export function PoetryQuizPage() {
  const params = useAppParams();
  const { t } = useAppTranslation();
  const [state, setState] = useAppState({
    page: 1,
    pageSize: 10,
    search: "",
    indexOrder: "ASC" as "ASC" | "DESC" | "",
    deleteModalVisible: false,
    creationModalVisible: false,
    answersModalVisible: false,
    infosModalVisible: false,
    selectedPoetryQuiz: null as PoetryQuizEntity | null,
    poetryId: parseInt(params.poetryId!),
    searchTerm: "",
  });

  const getAllPoetryQuiz = useQuery({
    queryKey: [
      QueryKeys.poetryQuizQuery,
      {
        page: state.page,
        pageSize: state.pageSize,
        poetryId: state.poetryId,
        searchTerm: state.searchTerm,
      },
    ],
    queryFn: () =>
      getAllPoetryQuizAction({
        page: state.page,
        pageSize: state.pageSize,
        poetryId: state.poetryId,
        searchTerm: state.searchTerm,
      }),
  });

  const handleSearch = useDebounce((searchTerm: string) => {
    setState((prevState) => ({ ...prevState, searchTerm: searchTerm }));
  }, 250);

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, search: e.target.value }));

    handleSearch(e.target.value);
  };

  const handleSetPage = (value: number) => {
    setState((prevState) => ({ ...prevState, page: value }));
  };

  const handleSetPageSize = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setState((prevState) => ({
      ...prevState,
      pageSize: parseInt(e.target.value),
    }));
  };

  const handleDeleteModalVisible = () => {
    setState((prevState) => ({
      ...prevState,
      deleteModalVisible: !prevState.deleteModalVisible,
    }));
  };

  const handleCreationModalClose = () => {
    setState((prevState) => ({
      ...prevState,
      selectedPoetryQuiz: null,
      creationModalVisible: false,
    }));
  };

  const showModifyModal = (poetryQuiz: PoetryQuizEntity) => {
    setState((prevState) => ({
      ...prevState,
      selectedPoetryQuiz: poetryQuiz,
      creationModalVisible: true,
    }));
  };

  const showAnswersModal = (poetryQuiz: PoetryQuizEntity) => {
    setState((prevState) => ({
      ...prevState,
      selectedPoetryQuiz: poetryQuiz,
      answersModalVisible: true,
    }));
  };

  const showInfosModal = (poetryQuiz: PoetryQuizEntity) => {
    setState((prevState) => ({
      ...prevState,
      selectedPoetryQuiz: poetryQuiz,
      infosModalVisible: true,
    }));
  };

  useEffect(() => {
    if (state.selectedPoetryQuiz) {
      const selected = state.selectedPoetryQuiz.id;
      setState((prevState) => ({
        ...prevState,
        selectedPoetryQuiz: getAllPoetryQuiz.data?.data.find(
          (q: PoetryQuizEntity) => q.id === selected
        ),
      }));
    }
  }, [getAllPoetryQuiz.data, state.selectedPoetryQuiz, setState]);

  const handleCloseErrorModal = () => {
    getAllPoetryQuiz.refetch();
  };

  return (
    <main className="page">
      <div className="poetry-quiz-page">
        <section className="top-bar-container">
          <PoetrySubPages />
        </section>
        <section className="search-bar-container">
          <Input
            onChange={handleSearchTermChange}
            value={state.search}
            placeholder={t("parent-page-search-bar-placeholder")}
            type="search"
            className="searchbar"
          />
          <Button
            onClick={() =>
              setState({
                ...state,
                creationModalVisible: true,
              })
            }
            title="+"
            htmlType="button"
            creationButton
          />
        </section>
        <section>
          <Table
            columns={Object.values(
              PoetryQuizColumns({
                handleDeleteModalVisible,
                showModifyModal,
                showAnswersModal,
                showInfosModal,
              })
            )}
            paginatorNeeded
            data={getAllPoetryQuiz.data?.data}
            loading={getAllPoetryQuiz.isLoading}
            page={getAllPoetryQuiz.data?.page}
            pageSize={getAllPoetryQuiz.data?.pageSize}
            allPage={getAllPoetryQuiz.data?.count}
            handleSetPage={handleSetPage}
            handleSetPageSize={handleSetPageSize}
          />
        </section>
      </div>
      <section>
        {state.creationModalVisible && (
          <PoetryQuizModal
            poetryId={parseInt(params.poetryId!)}
            poetryQuiz={state.selectedPoetryQuiz}
            handleMutationModalVisible={handleCreationModalClose}
            latestQueryKey={[
              QueryKeys.poetryQuizQuery,
              {
                page: state.page,
                pageSize: state.pageSize,
                poetryId: state.poetryId,
                searchTerm: state.searchTerm,
              },
            ]}
          />
        )}
      </section>
      <section>
        {state.answersModalVisible && (
          <PoetryQuizAnswersModal
            poetryQuiz={state.selectedPoetryQuiz}
            close={() =>
              setState({
                ...state,
                selectedPoetryQuiz: null,
                answersModalVisible: false,
              })
            }
          />
        )}
      </section>
      <section>
        {state.infosModalVisible && (
          <PoetryQuizInfosModal
            poetryQuiz={state.selectedPoetryQuiz}
            close={() =>
              setState({
                ...state,
                selectedPoetryQuiz: null,
                infosModalVisible: false,
              })
            }
          />
        )}
      </section>
      <section>
        {state.deleteModalVisible && (
          <PoetryQuizDeleteModal
            handleDeleteModalVisible={handleDeleteModalVisible}
          />
        )}
      </section>
      <section>
        {getAllPoetryQuiz.isError && (
          <ErrorModal
            onClose={handleCloseErrorModal}
            title={t("poetry-quiz-page-error-modal-title")}
            error={getAllPoetryQuiz.error}
          />
        )}
      </section>
    </main>
  );
}
