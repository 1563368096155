import axios from "axios";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { MutateTierDTO } from "../../dto";

type ModifyTierProps = {
  id: number;
  practiceNeeded: string;
  picture?: File;
  name: string;
};

let body: MutateTierDTO;

export async function modifyTierAction(props: ModifyTierProps) {
  const token = localStorage.getItem("token");

  const params = new FormData();
  params.append("id", props.id.toString());
  if (props.picture) params.append("picture", props.picture);
  params.append("name", props.name);
  params.append("practiceNeeded", props.practiceNeeded);

  return await axios
    .patch(
      `${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/tiers/modify`,
      params,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "app-platform": "cms",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(async (res) => {
      body = plainToClass(MutateTierDTO, res.data);
      await validate(body);
      const errors = await validate(body);
      if (errors.length > 0) {
        throw errors;
      }

      return res.data;
    });
}
