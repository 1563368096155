import axios from "axios";
import { GetMeDTO } from "../../dto";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";

let body: GetMeDTO;

export async function getMeAction() {
  const token = localStorage.getItem("token");

  return await axios
    .get(`${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/admins/me`, {
      headers: {
        "Content-Type": "application/json",
        "app-platform": "cms",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async (res) => {
      body = plainToClass(GetMeDTO, res.data);
      const errors = await validate(body);

      if (errors.length > 0) {
        throw errors;
      }
      
      return res.data;
    });
}
