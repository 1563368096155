import { useAppNavigate } from "../../../common";
import { DeleteIcon, ModifyIcon } from "../../../components";
import { DisappearingEntity } from "../../../entity";

type DisappearingColumnProps = {
  handleDeleteModalVisible: () => void;
  handleMutateModalVisible: (value: boolean) => void;
  handleModifyCorrectAnswer: (id: number) => void;
};

export function DisappearingColumns(props: DisappearingColumnProps) {
  const navigate = useAppNavigate();

  const handleOpenDeleteModal = (id: number) => {
    props.handleDeleteModalVisible();
    navigate("", { state: { id } });
  };

  const handleOpenModifyModal = (disappearing: DisappearingEntity) => {
    props.handleMutateModalVisible(true);
    navigate("", { state: { disappearing } });
  };

  return [
    {
      title: "disappearing-page-table-header-word",
      dataIndex: "word",
    },
    {
      title: "disappearing-page-table-header-option",
      dataIndex: "option",
    },
    {
      title: "disappearing-page-table-header-isCorrect",
      dataIndex: "isCorrect",
      render: (record: DisappearingEntity) => {
        return (
          <div
            className={`activity-circle ${
              record?.isCorrect ? "active" : ""
            } clickable`}
            onClick={() => props.handleModifyCorrectAnswer(record?.id)}
          />
        );
      },
    },
    {
      title: "disappearing-page-table-header-created",
      dataIndex: "created",
      render: (record: DisappearingEntity) => {
        return <span>{new Date(record?.created!).toLocaleDateString()}</span>;
      },
    },
    {
      title: "disappearing-page-table-header-updated",
      dataIndex: "updated",

      render: (record: DisappearingEntity) => {
        return <span>{new Date(record?.updated!).toLocaleDateString()}</span>;
      },
    },
    {
      title: "disappearing-page-table-header-modify",
      dataIndex: "modify",
      render: (record: DisappearingEntity) => {
        return <ModifyIcon onClick={() => handleOpenModifyModal(record)} />;
      },
    },
    {
      title: "disappearing-page-table-header-delete",
      dataIndex: "delete",
      render: (record: DisappearingEntity) => {
        return <DeleteIcon onClick={() => handleOpenDeleteModal(record?.id)} />;
      },
    },
  ];
}
