import axios from "axios";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { ChangePassowrdDTO } from "../../dto";

type FisrtLoginAdminProps = {
  password: string;
  newPassword: string;
};

let body: ChangePassowrdDTO;

export async function fistLoginAdminAction(props: FisrtLoginAdminProps) {
  const token = localStorage.getItem("token");

  const params = {
    password: props.password,
    newPassword: props.newPassword,
  };

  return await axios
    .patch(
      `${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/admins/change-password`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          "app-platform": "cms",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(async (res) => {
      body = plainToClass(ChangePassowrdDTO, res.data);
      const errors = await validate(body);

      if (errors.length > 0) {
        throw errors;
      }

      return res.data;
    });
}
