import { useAppState, useAppTranslation } from "../../../common";
import { Button } from "../../button";
import "./filter.icon.scss";

type FilterIconProps = {
  onFilter: (value: string) => void;
  filterOptions: {
    label: string;
    value: number | string;
  }[];
};

export function FilterIcon(props: FilterIconProps) {
  const { t } = useAppTranslation();
  const [state, setState] = useAppState({
    filterDropdownVisible: false,
    filteredOne: "",
  });

  const handleFilterDropdownVisible = () => {
    setState((prevState) => ({
      ...prevState,
      filterDropdownVisible: !prevState.filterDropdownVisible,
    }));
  };

  const handleFilter = (value: string) => {
    props.onFilter(value);
    setState((prevState) => ({ ...prevState, filteredOne: value }));
    handleFilterDropdownVisible();
  };

  const handleResetFilter = () => {
    props.onFilter("");
    setState((prevState) => ({ ...prevState, filteredOne: "" }));
    handleFilterDropdownVisible();
  };

  return (
    <div className="filter-icon">
      <section>
        <img
          src="/pictures/filter-icon.png"
          alt="filter"
          className="clickable-icon"
          onClick={handleFilterDropdownVisible}
        />
      </section>
      <section>
        {state.filterDropdownVisible && (
          <div className="drpodown-menu">
            {props.filterOptions.map((option) => (
              <div key={option.label}>
                <label>
                  <input
                    className="radio-button"
                    type="radio"
                    value={option.value}
                    checked={option.value.toString() === state.filteredOne}
                    onChange={(e) =>
                      handleFilter(e.target.value ? e.target.value : "")
                    }
                  />
                  {t(option.label)}
                </label>
              </div>
            ))}
            <div className="button-contanier">
              <Button
                htmlType="reset"
                onClick={handleResetFilter}
                title={t("filter-dopdown-reset-button-title")}
              />
            </div>
          </div>
        )}
      </section>
    </div>
  );
}
