import { Expose } from "class-transformer";
import { IsDateString, IsDefined, IsNumber, IsString } from "class-validator";
export class AdminEntity {
  @Expose()
  @IsDefined()
  @IsNumber()
  id!: number;

  @Expose()
  @IsDefined()
  @IsString()
  email!: string;

  @Expose()
  @IsDefined()
  @IsDateString()
  updated!: string;

  @Expose()
  @IsDefined()
  @IsDateString()
  created!: string;
}
