import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ErrorModal, Form, Input, Modal, Selector, ValidationErrorModal } from "../../../components"
import { ChildEntity } from "../../../entity"
import { PageType, QueryKeys, mutationHandler, useAppState, useAppTranslation } from "../../../common";
import { modifyChildAction } from "../../../actions"

interface ChildEditModalProps {
  child: ChildEntity | null;
  close: () => void;
  latestQueryKey: any;
}

export const ChildEditModal = (props: ChildEditModalProps) => {
    const queryClient = useQueryClient();
  const { t } = useAppTranslation();

    const sleepTimes = [
        {value: 0, label: t(`sleep-time-filter-option-early-sleeping-time`)},
        {value: 1, label: t(`sleep-time-filter-option-mid-sleeping-time`)},
        {value: 2, label: t(`sleep-time-filter-option-late-sleeping-time`)}
    ]

  const [state, setState] = useAppState({
      nickname: props.child?.nickname || ``,
      sleepTime: props.child?.sleepTime || 0,
      learingPeriodFrom: props.child?.learingPeriodFrom || ``,
      learingPeriodTo: props.child?.learingPeriodTo || ``,
      validationErrorMessage: `` as string | null
  });


  const checkForm = () => {
      if (state.nickname.trim().length === 0) {
          setState({
              ...state,
              validationErrorMessage: `A becenév kitöltése kötelező!`,
          });
          return false;
      }

      if (!state.learingPeriodFrom.trim().match(/^[0-9][0-9]:[0-9][0-9]$/)) {
          setState({
              ...state,
              validationErrorMessage: `A tanulási időszak kezdetének ebben a formátumban kell lennie: 12:00!`,
          });
          return false;
      }

      if (!state.learingPeriodTo.trim().match(/^[0-9][0-9]:[0-9][0-9]$/)) {
          setState({
              ...state,
              validationErrorMessage: `A tanulási időszak végének ebben a formátumban kell lennie: 12:00!`,
          });
          return false;
      }

      return true;
  };

  const mutateChild = useMutation({
    mutationKey: [QueryKeys.mutateChildQuery],
    mutationFn: (data: any) => modifyChildAction(data),
    onMutate: async (newChild) => {
      await queryClient.cancelQueries({ queryKey: props.latestQueryKey });
      const previousChildren = queryClient.getQueryData([props.latestQueryKey]);
      queryClient.setQueryData(
        props.latestQueryKey,
        (old: PageType<ChildEntity>) => {
          const handler = mutationHandler({
            dataLength: old.data.length,
            pageSize: old.pageSize,
            count: old.count,
            oldData: old.data,
            newData: newChild,
          });
          return {
            page: old.page,
            pageSize: old.pageSize,
            count: handler.newCount,
            data: handler.newData,
          };
        }
      );

      return { previousChildren };
    },
    onSuccess() {
      props.close();
    },
    onError(error, variables, context) {
      queryClient.setQueryData(
        [props.latestQueryKey],
        context?.previousChildren
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.childrenQuery],
      });
    },
  });

  const handleCloseErrorModal = () => {
    mutateChild.reset();
  };

  const handleNicknameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      nickname: e.target.value,
    }));
  };

  const handleSleepTimeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setState((prevState) => ({
        ...prevState,
        sleepTime: e.target.value,
    }));
  };

    const handleFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prevState) => ({
            ...prevState,
            learingPeriodFrom: e.target.value,
        }));
    };

    const handleToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prevState) => ({
            ...prevState,
            learingPeriodTo: e.target.value,
        }));
    };

  const submit = async () => {
    if (!checkForm()) {
      return;
    }
    const children = {
      id: props.child ? props.child.id : null,
      nickname: state.nickname,
      sleepTime: state.sleepTime,
      learningPeriodFrom: state.learingPeriodFrom,
      learningPeriodTo: state.learingPeriodTo
    };

    mutateChild.mutate(children);
  };

  return (
    <div>
      <section>
        <Modal
          onClose={props.close}
          title={`${t(`child-page-child`)} ${ t(`child-page-edit`) }`}
          footer={true}
          submitText={`Módosítás`}
          cancelText={t(`child-page-delete-cancel-button-title`)}
          handleSubmit={submit}
          loading={mutateChild.isPending}
        >
          <div className="child-modal">
            <Form onSubmit={submit}>
              <Input
                onChange={handleNicknameChange}
                value={state.nickname}
                placeholder={t(`child-page-child-nickname`)}
                className="child-modal-input"
                type="text"
              />

                <Selector
                    name={t(`poetry-page-poetry-class`)}
                    id="poetry-class"
                    handleSelectOption={handleSleepTimeChange}
                    options={sleepTimes}
                    onlyLabel={true}
                    currentValue={state.sleepTime}
                />

                <div className="from-to">
                    <Input
                        onChange={handleFromChange}
                        value={state.learingPeriodFrom}
                        placeholder={t(`child-page-child-learning-period-from`)}
                        className="child-modal-input child-modal-input-from"
                        type="text"
                    />

                    <span>-</span>

                    <Input
                        onChange={handleToChange}
                        value={state.learingPeriodTo}
                        placeholder={t(`child-page-child-learning-period-to`)}
                        className="child-modal-input child-modal-input-to"
                        type="text"
                    />
                </div>
            </Form>

            {state.validationErrorMessage && (
              <ValidationErrorModal
                error={state.validationErrorMessage}
                onClose={() =>
                  setState({
                    ...state,
                    validationErrorMessage: null,
                  })
                }
              />
            )}
          </div>
        </Modal>
      </section>

      <section>
        {mutateChild.isError && (
          <ErrorModal
            error={mutateChild.error}
            onClose={handleCloseErrorModal}
            title={ t("child-page-mutate-child-form-error-modal-modify-title") }
          />
        )}
      </section>
    </div>
  );
};
