type PasswordIconProps = {
  visible: boolean;
};

export function PasswordIcon(props: PasswordIconProps) {
  return props.visible ? (
    <img
      className="clickable-icon"
      src="/pictures/show-password-icon.png"
      alt="show-pw"
    />
  ) : (
    <img
      className="clickable-icon"
      src="/pictures/hide-password-icon.png"
      alt="hide-pw"
    />
  );
}
