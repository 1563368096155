import axios from "axios";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { MutatePoetryDTO } from "../../dto";

let body: MutatePoetryDTO;

export async function createPoetryAction(poetry: any) {
  const token = localStorage.getItem("token");

  return await axios
    .post(
      `${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/poetries/create`,
      poetry,
      {
        headers: {
          "content-type": "application/json",
          "app-platform": "cms",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(async (res) => {
      body = plainToClass(MutatePoetryDTO, res.data);
      await validate(body);
      const errors = await validate(body);
      if (errors.length > 0) {
        throw errors;
      }

      return res.data;
    });
}
