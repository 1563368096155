import { useAppNavigate } from "../../../common";
import { DeleteIcon, ModifyIcon, SortIcon } from "../../../components";
import { PoetEntity } from "../../../entity";

type PoetColumnProps = {
  handleNameOrder: (value: string) => void;
  nameOrder: "ASC" | "DESC" | "";
  handleDeleteModalVisible: () => void;
  handleMutateModalVisible: (value: boolean) => void;
  handleActivatePoet: (id: number) => void;
};

export function PoetColumns(props: PoetColumnProps) {
  const navigate = useAppNavigate();

  const handleOpenDeleteModal = (id: number) => {
    props.handleDeleteModalVisible();
    navigate("", { state: { id } });
  };

  const handleOpenModifyModal = (poet: PoetEntity) => {
    props.handleMutateModalVisible(true);
    navigate("", { state: { poet } });
  };

  return [
    {
      title: "poet-page-table-header-young-avatar",
      dataIndex: "youngAvatar",
      render: (record: PoetEntity) => {
        return (
          <img
            className="poet-profile-picture"
            src={record?.youngAvatar ?? "/pictures/image-placeholder-icon.png"}
            alt={record?.name}
          />
        );
      },
    },
    {
      title: "poet-page-table-header-old-avatar",
      dataIndex: "oldAvatar",
      render: (record: PoetEntity) => {
        return (
          <img
            className="poet-profile-picture"
            src={record?.oldAvatar ?? "/pictures/image-placeholder-icon.png"}
            alt={record?.name}
          />
        );
      },
    },
    {
      title: "poet-page-table-header-happy-animation",
      dataIndex: "happyAnimation",
      render: (record: PoetEntity) => {
        return (
          <img
            className="poet-profile-picture"
            src={
              record?.happyAnimation ?? "/pictures/image-placeholder-icon.png"
            }
            alt={record?.name}
          />
        );
      },
    },
    {
      title: "poet-page-table-header-idle-animation",
      dataIndex: "idleAnimation",
      render: (record: PoetEntity) => {
        return (
          <img
            className="poet-profile-picture"
            src={
              record?.idleAnimation ?? "/pictures/image-placeholder-icon.png"
            }
            alt={record?.name}
          />
        );
      },
    },
    {
      title: "poet-page-table-header-name",
      dataIndex: "name",
      suffix: (
        <SortIcon
          order={props.nameOrder}
          orderFunction={props.handleNameOrder}
        />
      ),
    },
    {
      title: "poet-page-table-header-is-active",
      dataIndex: "isActive",
      render: (record: PoetEntity) => {
        return (
          <div
            className={`activity-circle ${
              record?.isActive ? "active" : ""
            } clickable`}
            onClick={() => props.handleActivatePoet(record?.id)}
          />
        );
      },
    },
    {
      title: "poet-page-table-header-created",
      dataIndex: "created",
      render: (record: PoetEntity) => {
        return <span>{new Date(record?.created!).toLocaleDateString()}</span>;
      },
    },
    {
      title: "poet-page-table-header-updated",
      dataIndex: "updated",

      render: (record: PoetEntity) => {
        return <span>{new Date(record?.updated!).toLocaleDateString()}</span>;
      },
    },
    {
      title: "poet-page-table-header-modify",
      dataIndex: "modify",
      render: (record: PoetEntity) => {
        return <ModifyIcon onClick={() => handleOpenModifyModal(record)} />;
      },
    },
    {
      title: "poet-page-table-header-delete",
      dataIndex: "delete",
      render: (record: PoetEntity) => {
        return <DeleteIcon onClick={() => handleOpenDeleteModal(record?.id)} />;
      },
    },
  ];
}
