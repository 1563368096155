import { Expose, Type } from "class-transformer";
import { ValidateNested } from "class-validator";
import { DisappearingEntity } from "../../entity";

export class MutateDisappearingDTO {
  @Expose()
  @ValidateNested()
  @Type(() => DisappearingEntity)
  disappearingGame!: DisappearingEntity;
}
