import { Modal } from "./modal";
import { useAppTranslation } from "../../common"

type ErrorModalProps = {
  error: any | null;
  onClose: () => void;
};

export function ValidationErrorModal(props: ErrorModalProps) {

  const [ t ] = useAppTranslation()

  return (
    <Modal onClose={ props.onClose } title={t( `validation-error-modal-title` )} footer={false}>
      <span>
        { props.error }
      </span>
    </Modal>
  );
}
