type MutationHandlerProps = {
  dataLength: number;
  pageSize?: number;
  count: number;
  oldData?: any;
  newData?: any;
};
export function mutationHandler(props: MutationHandlerProps) {
  const newCount =
    props?.pageSize &&
    props.dataLength === props.pageSize &&
    props.dataLength / props.pageSize === props.count
      ? props.count + 1
      : props.count;

  const newData = [
    ...props.oldData.filter((old: any) => old.id !== props.newData.id),
    props.newData,
  ].sort((a, b) => a.id - b.id);

  const page =
    props.dataLength === 1 && props.count > 1 ? props.count - 1 : props.count;

  return {
    newCount,
    newData,
    page,
  };
}
