import "./form.scss";

type FormProps = {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  children: JSX.Element | JSX.Element[];
};

export function Form(props: FormProps) {
  return (
    <form className="form" onSubmit={props.onSubmit}>
      {props.children}
    </form>
  );
}
