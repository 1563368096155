import { Expose, Type } from "class-transformer";
import { ValidateNested } from "class-validator";
import { PoetryQuizEntity } from "../../entity";

export class MutatePoetryQuizDTO {
  @Expose()
  @ValidateNested()
  @Type(() => PoetryQuizEntity)
  quiz!: PoetryQuizEntity;
}
