import axios from "axios";

type ForgotPasswordActionProps = {
  email: string;
};

export async function ForgotPasswordAction(props: ForgotPasswordActionProps) {
  const params = {
    email: props.email,
  };

  return await axios
    .post(
      `${process.env.REACT_APP_API_ORIGIN}/1.0.0/auth/forgot-password`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          "app-platform": "cms",
        },
      }
    )
    .then((res) => res.data);
}
