import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  QueryKeys,
  mutationHandler,
  useAppLocation,
  useAppState,
  useAppTranslation,
  PageType,
} from "../../../common";
import { createCategoryAction, modifyCategoryAction } from "../../../actions";
import {
  ErrorModal,
  Form,
  Input,
  Modal,
  ValidationErrorModal,
} from "../../../components";
import { CategoryEntity } from "../../../entity";

type CategoryMutationFormProps = {
  modify: boolean;
  handleMutationModalVisible: (value: boolean) => void;
  latestQueryKey: any;
};

export function CategoryMutationForm(props: CategoryMutationFormProps) {
  const queryClient = useQueryClient();
  const location = useAppLocation();
  const { t } = useAppTranslation();

  const [state, setState] = useAppState({
    title: location.state?.category.title ?? "",
    validationErrorMessage: null as string | null,
  });

  const mutateCategory = useMutation({
    mutationKey: [
      props.modify
        ? QueryKeys.modifyCategoryQuery
        : QueryKeys.createCategoryQuery,
    ],
    mutationFn: (data: any) =>
      props.modify ? modifyCategoryAction(data) : createCategoryAction(data),
    onMutate: async (newCategory) => {
      await queryClient.cancelQueries({ queryKey: props.latestQueryKey });
      const previousCategories = queryClient.getQueryData([
        props.latestQueryKey,
      ]);

      queryClient.setQueryData(
        props.latestQueryKey,
        (old: PageType<CategoryEntity>) => {
          const handler = mutationHandler({
            dataLength: old.data.length,
            pageSize: old.pageSize,
            count: old.count,
            oldData: old.data,
            newData: newCategory,
          });

          return {
            page: old.page,
            pageSize: old.pageSize,
            count: handler.newCount,
            data: handler.newData,
          };
        }
      );

      return { previousCategories };
    },
    onError(error, variables, context) {
      queryClient.setQueryData(
        [props.latestQueryKey],
        context?.previousCategories
      );
    },
    onSuccess() {
      props.handleMutationModalVisible(false);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.categoryQuery],
      });
    },
  });

  const handleCloseErrorModal = () => {
    mutateCategory.reset();
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, title: e.target.value }));
  };

  const checkForm = () => {
    if (state.title.trim().length === 0) {
      setState({
        ...state,
        validationErrorMessage: `A kategória kitöltése kötelező!`,
      });
      return false;
    }

    return true;
  };

  const handleMutateCategory = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!checkForm()) {
      return;
    }
    mutateCategory.mutate({
      id: props.modify ? location.state?.category.id : undefined!,
      title: state.title,
    });
  };

  return (
    <div>
      <section>
        <Modal
          onClose={() => props.handleMutationModalVisible(false)}
          title={
            props.modify
              ? t("category-page-mutate-category-modal-modify-title")
              : t("category-page-mutate-category-modal-create-title")
          }
          footer
          cancelText={t("category-page-mutate-category-modal-cancel-label")}
          submitText={
            props.modify
              ? t("category-page-mutate-category-modal-modify-submit-label")
              : t("category-page-mutate-category-modal-create-submit-label")
          }
          handleSubmit={handleMutateCategory}
          loading={mutateCategory.isPending}
        >
          <Form onSubmit={handleMutateCategory}>
            <Input
              type="text"
              maxLength={255}
              className="mutate-modal-input"
              onChange={handleTitleChange}
              value={state.title}
              placeholder={t(
                "category-page-mutate-category-modal-title-placeholder"
              )}
            />
          </Form>
        </Modal>
      </section>
      <section>
        {mutateCategory.isError && (
          <ErrorModal
            error={mutateCategory.error}
            onClose={handleCloseErrorModal}
            title={
              props.modify
                ? t(
                    "category-page-mutate-category-form-error-modal-modify-title"
                  )
                : t(
                    "category-page-mutate-category-form-error-modal-create-title"
                  )
            }
          />
        )}
      </section>
      <section>
        {state.validationErrorMessage && (
          <ValidationErrorModal
            error={state.validationErrorMessage}
            onClose={() =>
              setState({
                ...state,
                validationErrorMessage: null,
              })
            }
          />
        )}
      </section>
    </div>
  );
}
