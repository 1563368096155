import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  PageType,
  QueryKeys,
  mutationHandler,
  useAppLocation,
  useAppState,
  useAppTranslation,
} from "../../../common";
import {
  ErrorModal,
  Form,
  Input,
  Modal,
  Upload,
  ValidationErrorModal,
} from "../../../components";
import { createTierAction, modifyTierAction } from "../../../actions";
import { TierEntity } from "../../../entity";

type TierMutationFormProps = {
  modify: boolean;
  handleMutationModalVisible: (value: boolean) => void;
  latestQueryKey: any;
};

export function TierMutationForm(props: TierMutationFormProps) {
  const queryClient = useQueryClient();
  const location = useAppLocation();
  const { t } = useAppTranslation();

  const [state, setState] = useAppState({
    name: location.state?.tier.name ?? "",
    picture: null as unknown as File,
    validationErrorMessage: null as string | null,
    practiceNeeded: location.state?.tier.practiceNeeded ?? "",
  });

  const mutateTier = useMutation({
    mutationKey: [
      props.modify ? QueryKeys.modifyTierQuery : QueryKeys.createTierQuery,
    ],
    mutationFn: (data: any) =>
      props.modify ? modifyTierAction(data) : createTierAction(data),
    onMutate: async (newTier) => {
      await queryClient.cancelQueries({
        queryKey: props.latestQueryKey,
      });
      const previousTiers = queryClient.getQueryData([props.latestQueryKey]);

      queryClient.setQueryData(
        props.latestQueryKey,
        (old: PageType<TierEntity>) => {
          const handler = mutationHandler({
            dataLength: old.data.length,
            pageSize: old.pageSize,
            count: old.count,
            oldData: old.data,
            newData: newTier,
          });

          return {
            page: old.page,
            pageSize: old.pageSize,
            count: handler.newCount,
            data: handler.newData,
          };
        }
      );

      return { previousTiers };
    },
    onSuccess() {
      props.handleMutationModalVisible(false);
    },
    onError(error, variables, context) {
      queryClient.setQueryData([props.latestQueryKey], context?.previousTiers);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.tierQuery],
      });
    },
  });

  const handleCloseErrorModal = () => {
    mutateTier.reset();
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, name: e.target.value }));
  };

  const handlePracticeNeededChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setState((prevState) => ({ ...prevState, practiceNeeded: e.target.value }));
  };

  const handlePictureChange = (value: File) => {
    setState((prevState) => ({ ...prevState, picture: value }));
  };

  const checkForm = () => {
    if (state.name.trim().length === 0) {
      setState({
        ...state,
        validationErrorMessage: `A név kitöltése kötelező!`,
      });
      return false;
    }
    if (!props.modify && state.picture === null) {
      setState({
        ...state,
        validationErrorMessage: `A kép kiválasztása kötelező!`,
      });
      return false;
    }

    return true;
  };

  const handleMutateTier = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!checkForm()) {
      return;
    }
    mutateTier.mutate({
      name: state.name,
      picture: state.picture,
      practiceNeeded: state.practiceNeeded,
      id: props.modify ? location.state?.tier.id : undefined!,
    });
  };

  return (
    <div>
      <section>
        <Modal
          onClose={() => props.handleMutationModalVisible(false)}
          title={
            props.modify
              ? t("tier-page-mutate-tier-modal-modify-title")
              : t("tier-page-mutate-tier-modal-create-title")
          }
          footer
          cancelText={t("tier-page-mutate-tier-modal-cancel-label")}
          submitText={
            props.modify
              ? t("tier-page-mutate-tier-modal-modify-submit-label")
              : t("tier-page-mutate-tier-modal-create-submit-label")
          }
          handleSubmit={handleMutateTier}
          loading={mutateTier.isPending}
        >
          <Form onSubmit={handleMutateTier}>
            <div className="tier-form">
              <Input
                type="text"
                maxLength={255}
                className="mutate-modal-input"
                onChange={handleNameChange}
                value={state.name}
                placeholder={t("tier-page-mutate-tier-modal-name-placeholder")}
              />
              <Input
                type="text"
                maxLength={255}
                className="mutate-modal-input"
                onChange={handlePracticeNeededChange}
                value={state.practiceNeeded}
                placeholder={t(
                  "tier-page-mutate-tier-modal-practice-needed-placeholder"
                )}
              />
              <Upload
                type="picture"
                label={t("tier-page-mutate-tier-modal-picture-title")}
                handleChangeUpload={handlePictureChange}
                file={location.state?.tier.url ?? ""}
              />
            </div>
          </Form>
        </Modal>
      </section>
      <section>
        {mutateTier.isError && (
          <ErrorModal
            error={mutateTier.error}
            onClose={handleCloseErrorModal}
            title={
              props.modify
                ? t("tier-page-mutate-tier-form-error-modal-modify-title")
                : t("tier-page-mutate-tier-form-error-modal-create-title")
            }
          />
        )}
      </section>
      <section>
        {state.validationErrorMessage && (
          <ValidationErrorModal
            error={state.validationErrorMessage}
            onClose={() =>
              setState({
                ...state,
                validationErrorMessage: null,
              })
            }
          />
        )}
      </section>
    </div>
  );
}
