type DetailsIconProps = {
  onClick: () => void;
};

export function DetailsIcon(props: DetailsIconProps) {
  return (
    <img
      className="clickable-icon"
      onClick={props.onClick}
      src="/pictures/details-icon.png"
      alt="details"
    />
  );
}
