import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  QueryKeys,
  useAppLocation,
  useAppState,
  useAppTranslation,
} from "../../../common";
import { ErrorModal, Modal } from "../../../components";
import { deletePoetryChatAction } from "../../../actions/poetry-chat";

type PoetryChatDeleteModalProps = {
  handleDeleteModalVisible: () => void;
};

export function PoetryChatDeleteModal(props: PoetryChatDeleteModalProps) {
  const { t } = useAppTranslation();
  const location = useAppLocation();
  const queryClient = useQueryClient();

  const [state, setState] = useAppState({
    errorModalVisible: false,
  });

  const handleModalVisibility = () => {
    setState((prevState) => ({
      ...prevState,
      errorModalVisible: !prevState.errorModalVisible,
    }));
  };

  const deletePoetryChat = useMutation({
    mutationKey: [QueryKeys.deletePoetryChatMutationQuery],
    mutationFn: deletePoetryChatAction,
    onSuccess() {
      props.handleDeleteModalVisible();
      queryClient.invalidateQueries({ queryKey: [QueryKeys.poetryChatQuery] });
    },
    onError() {
      handleModalVisibility();
    },
  });

  const handleDeletePoetryChat = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    deletePoetryChat.mutate({ id: location.state?.id });
  };

  return (
    <div>
      <section>
        <Modal
          onClose={props.handleDeleteModalVisible}
          title={t("poetry-chat-page-delete-modal-title")}
          footer
          submitText={t("poetry-chat-page-delete-submit-button-title")}
          cancelText={t("poetry-chat-page-delete-cancel-button-title")}
          handleSubmit={handleDeletePoetryChat}
          loading={deletePoetryChat.isPending}
        >
          <span>{t("poetry-chat-page-delete-poetry-question-label")}</span>
        </Modal>
      </section>
      <section>
        {state.errorModalVisible && (
          <ErrorModal
            error={deletePoetryChat.error}
            onClose={handleModalVisibility}
            title={t("poetry-chat-page-delete-parent-error-modal-title")}
          />
        )}
      </section>
    </div>
  );
}
