import axios from "axios";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { MutateLineOfPoetryDTO } from "../../dto";

type ModifyLineOfPoetryIndexProps = {
  id: number;
  index: number;
};

let body: MutateLineOfPoetryDTO;

export async function modifyLineOfPoetryIndexAction(
  props: ModifyLineOfPoetryIndexProps
) {
  const token = localStorage.getItem("token");

  const params = {
    id: props.id,
    index: props.index,
  };

  return await axios
    .patch(
      `${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/lines-of-poetries/modify-index`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          "app-platform": "cms",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(async (res) => {
      body = plainToClass(MutateLineOfPoetryDTO, res.data);
      await validate(body);
      const errors = await validate(body);

      if (errors.length > 0) {
        throw errors;
      }

      return res.data;
    });
}
