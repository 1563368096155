import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QueryKeys, useAppNavigate } from "../../../common";
import { logoutAction } from "../../../actions";

export function LogoutIcon() {
  const navigate = useAppNavigate();
  const queryClient = useQueryClient();
  const handleLogout = () => {
    localStorage.removeItem("token");
    queryClient.removeQueries({
      queryKey: [
        QueryKeys.userQuery,
        QueryKeys.adminQuery,
        QueryKeys.forgotPasswordConfirmQuery,
        QueryKeys.getMeQuery,
        QueryKeys.verifyQuery,
        QueryKeys.profileMutationQuery,
        QueryKeys.loginMutationQuery,
        QueryKeys.logoutMutationQuery,
        QueryKeys.fisrtLoginMutationQuery,
        QueryKeys.parentsQuery,
        QueryKeys.deleteAdminMutatuionQuery,
        QueryKeys.deleteParentMutatuionQuery,
        QueryKeys.childrenQuery,
        QueryKeys.parentQuery,
        QueryKeys.deleteChildMutation,
        QueryKeys.poetryQuery,
        QueryKeys.deletePoetryMutatuionQuery,
        QueryKeys.poetQuery,
        QueryKeys.activatePoetQuery,
        QueryKeys.createPoetQuery,
        QueryKeys.modifyPoetQuery,
        QueryKeys.deletePoetQuery,
        QueryKeys.categoryQuery,
        QueryKeys.modifyCategoryQuery,
        QueryKeys.createCategoryQuery,
        QueryKeys.deleteCategoryQuery,
        QueryKeys.achievementQuery,
        QueryKeys.modifyAchievementQuery,
        QueryKeys.createAchievementQuery,
        QueryKeys.deleteAchievementQuery,
        QueryKeys.tierQuery,
        QueryKeys.modifyTierQuery,
        QueryKeys.createTierQuery,
        QueryKeys.deleteTierQuery,
        QueryKeys.versionQuery,
        QueryKeys.modifyVersionQuery,
        QueryKeys.createVersionQuery,
        QueryKeys.deleteVersionQuery,
        QueryKeys.factsQuery,
        QueryKeys.modifyFactQuery,
        QueryKeys.createFactQuery,
        QueryKeys.deleteFactQuery,
        QueryKeys.poetryChatQuery,
        QueryKeys.modifyPoetryChatQuery,
        QueryKeys.createPoetryChatQuery,
        QueryKeys.deletePoetryChatMutationQuery,
        QueryKeys.poetryQuizQuery,
        QueryKeys.modifyPoetryQuizQuery,
        QueryKeys.createPoetryQuizQuery,
        QueryKeys.deletePoetryQuizMutationQuery,
        QueryKeys.linesOfPoetryQuery,
        QueryKeys.modifyLineOfPoetry,
        QueryKeys.createLineOfPoetry,
        QueryKeys.deleteLineOfPoetry,
        QueryKeys.modifyLineOfPoetryIndex,
        QueryKeys.inlineInfos,
        QueryKeys.createInlineInfo,
        QueryKeys.modifyInlineInfo,
        QueryKeys.deleteInlineInfo,
        QueryKeys.disappearingGames,
        QueryKeys.createDisappearingGame,
        QueryKeys.modifyDisappearingGame,
        QueryKeys.modifyCorrectDisappearingGame,
        QueryKeys.deleteDisappearingGame,
      ],
    });
    navigate("/");
  };

  const logout = useMutation({
    mutationKey: [QueryKeys.logoutMutationQuery],
    mutationFn: logoutAction,
    onSuccess() {
      handleLogout();
    },
    onError() {
      handleLogout();
    },
  });

  return (
    <img
      className="clickable-icon"
      src="/pictures/logout-icon.png"
      alt="logout"
      onClick={() => logout.mutate()}
    />
  );
}
