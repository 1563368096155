import { Expose, Type } from "class-transformer";
import { ValidateNested } from "class-validator";
import { PoetEntity } from "../../entity";

export class MutatePoetDTO {
  @Expose()
  @ValidateNested()
  @Type(() => PoetEntity)
  poet!: PoetEntity;
}
