import { useQuery } from "@tanstack/react-query";
import {
  QueryKeys,
  useAppState,
  useAppTranslation,
  useDebounce,
} from "../../common";
import { ErrorModal, Input, Table } from "../../components";
import { getAllParentAction } from "../../actions/parent";
import { ParentColumns, ParentDeleteModal } from "./components";
import "./parent.page.scss";
import { ParentEditModal } from "./components/parent-edit-modal"
import { ParentEntity } from "../../entity"

export function ParentPage() {
  const { t } = useAppTranslation();
  const [state, setState] = useAppState({
    page: 1,
    pageSize: 10,
    search: "",
    nameOrder: "" as "ASC" | "DESC" | "",
    nicknameOrder: "" as "ASC" | "DESC" | "",
    emailOrder: "" as "ASC" | "DESC" | "",
    createdOrder: "" as "ASC" | "DESC" | "",
    updatedOrder: "" as "ASC" | "DESC" | "",
    deleteModalVisible: false,
    editModalVisible: false,
    searchTerm: "",
    currentParent: null as ParentEntity | null
  });

  const getAllParent = useQuery({
    queryKey: [
      QueryKeys.parentsQuery,
      {
        page: state.page,
        pageSize: state.pageSize,
        searchTerm: state.searchTerm,
        nameOrder: state.nameOrder,
        nicknameOrder: state.nicknameOrder,
        emailOrder: state.emailOrder,
        createdOrder: state.createdOrder,
        updatedOrder: state.updatedOrder,
      },
    ],
    queryFn: () =>
      getAllParentAction({
        page: state.page,
        pageSize: state.pageSize,
        searchTerm: state.searchTerm,
        nameOrder: state.nameOrder,
        nicknameOrder: state.nicknameOrder,
        emailOrder: state.emailOrder,
        createdOrder: state.createdOrder,
        updatedOrder: state.updatedOrder,
      }),
  });

  const handleSearch = useDebounce((searchTerm: string) => {
    setState((prevState) => ({ ...prevState, searchTerm: searchTerm }));
  }, 250);

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, search: e.target.value }));

    handleSearch(e.target.value);
  };

  const handleCreatedOrder = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      createdOrder: value as "ASC" | "DESC" | "",
    }));
  };

  const handleUpdatedOrder = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      updatedOrder: value as "ASC" | "DESC" | "",
    }));
  };

  const handleNameOrder = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      nameOrder: value as "ASC" | "DESC" | "",
    }));
  };

  const handleNicknameOrder = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      nicknameOrder: value as "ASC" | "DESC" | "",
    }));
  };

  const handleEmailOrder = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      emailOrder: value as "ASC" | "DESC" | "",
    }));
  };

  const handleSetPage = (value: number) => {
    setState((prevState) => ({ ...prevState, page: value }));
  };

  const handleSetPageSize = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setState((prevState) => ({
      ...prevState,
      pageSize: parseInt(e.target.value),
    }));
  };

  const handleDeleteModalVisible = () => {
    setState((prevState) => ({
      ...prevState,
      deleteModalVisible: !prevState.deleteModalVisible,
    }));
  };

  const handleEditModalVisible = (id?: number) => {
    if (id !== undefined) {
        setState((prevState) => ({
            ...prevState,
            currentParent: getAllParent.data?.data.find((p: ParentEntity) => p.id === id)
        }))
    }
    setState((prevState) => ({
        ...prevState,
        editModalVisible: !prevState.editModalVisible,
    }));
  };

  const handleCloseErrorModal = () => {
    getAllParent.refetch();
  };

  return ( <main className="page">
          <div className="parent-page">
              <section className="title">{ t( "parent-page-title" ) }</section>
              <section className="search-bar-container">
                  <Input onChange={ handleSearchTermChange } value={ state.searchTerm } placeholder={ t( "parent-page-search-bar-placeholder" ) } type="search" className="searchbar"/>
              </section>
              <section>
                  <Table columns={ Object.values( ParentColumns( {
                      nameOrder: state.nameOrder,
                      nicknameOrder: state.nicknameOrder,
                      emailOrder: state.emailOrder,
                      updatedOrder: state.updatedOrder,
                      createdOrder: state.createdOrder,
                      handleCreatedOrder,
                      handleUpdatedOrder,
                      handleNameOrder,
                      handleNicknameOrder,
                      handleEmailOrder,
                      handleDeleteModalVisible,
                      handleEditModalVisible
                  } ) ) } paginatorNeeded data={ getAllParent.data?.data } loading={ getAllParent.isLoading } page={ getAllParent.data?.page } pageSize={ getAllParent.data?.pageSize } allPage={ getAllParent.data?.count } handleSetPage={ handleSetPage } handleSetPageSize={ handleSetPageSize }/>
              </section>
          </div>
          <section>
              { state.deleteModalVisible && (
                  <ParentDeleteModal handleDeleteModalVisible={ handleDeleteModalVisible } handleSetPage={ handleSetPage }/> ) }
          </section>
          <section>
              { getAllParent.isError && (
                  <ErrorModal onClose={ handleCloseErrorModal } title={ t( "parent-page-error-modal-title" ) } error={ getAllParent.error }/> ) }
          </section>
          <section>
              { state.editModalVisible && (
                  <ParentEditModal close={ () => handleEditModalVisible() } parent={ state.currentParent! } latestQueryKey={[
                      QueryKeys.parentsQuery,
                      {
                          page: state.page,
                          pageSize: state.pageSize,
                          searchTerm: state.searchTerm,
                          nameOrder: state.nameOrder,
                          nicknameOrder: state.nicknameOrder,
                          emailOrder: state.emailOrder,
                          createdOrder: state.createdOrder,
                          updatedOrder: state.updatedOrder,
                      },
                  ]}/> ) }
          </section>
      </main> );
}
