import { useAppNavigate } from "../../../common";
import { DeleteIcon, DetailsIcon, EditIcon } from "../../../components";
import { PoetryQuizEntity } from "../../../entity";

type PoetryQuizColumnProps = {
  handleDeleteModalVisible: () => void;
  showModifyModal: (poetryQuiz: PoetryQuizEntity) => void;
  showAnswersModal: (poetryQuiz: PoetryQuizEntity) => void;
  showInfosModal: (poetryQuiz: PoetryQuizEntity) => void;
};

export function PoetryQuizColumns(props: PoetryQuizColumnProps) {
  const navigate = useAppNavigate();

  const handleOpenDeleteModal = (id: number) => {
    props.handleDeleteModalVisible();
    navigate("", { state: { id } });
  };

  return [
    {
      title: "poetry-quiz-page-table-header-question",
      dataIndex: "question",
      width: "40%",
    },
    {
      title: "poetry-quiz-page-table-header-answers",
      dataIndex: "answers",
      render: (record: PoetryQuizEntity) => {
        return <DetailsIcon onClick={() => props.showAnswersModal(record)} />;
      },
    },
    {
      title: "poetry-quiz-page-table-header-infos",
      dataIndex: "infos",
      render: (record: PoetryQuizEntity) => {
        return <DetailsIcon onClick={() => props.showInfosModal(record)} />;
      },
    },
    {
      title: "poetry-quiz-page-table-header-updated",
      dataIndex: "updated",
      render: (record: PoetryQuizEntity) => {
        return <span>{new Date(record?.updated).toLocaleDateString()}</span>;
      },
    },
    {
      title: "poetry-quiz-page-table-header-created",
      dataIndex: "created",
      render: (record: PoetryQuizEntity) => {
        return <span>{new Date(record?.created).toLocaleDateString()}</span>;
      },
    },
    {
      title: "poetry-quiz-page-table-header-edit",
      dataIndex: "children",
      render: (record: PoetryQuizEntity) => {
        return <EditIcon onClick={() => props.showModifyModal(record)} />;
      },
    },
    {
      title: "poetry-quiz-page-table-header-delete",
      dataIndex: "delete",
      render: (record: PoetryQuizEntity) => {
        return <DeleteIcon onClick={() => handleOpenDeleteModal(record?.id)} />;
      },
    },
  ];
}
