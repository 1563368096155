import { useAppNavigate } from "../../../common";
import {
  DeleteIcon,
  DetailsIcon,
  EditIcon,
  SortIcon,
} from "../../../components";
import { PoetryEntity } from "../../../entity";

type PoetryColumnProps = {
  handleTitleOrder: (value: string) => void;
  handleWritingDateOrder: (value: string) => void;
  titleOrder: "ASC" | "DESC" | "";
  writingDateOrder: "ASC" | "DESC" | "";

  handleDeleteModalVisible: () => void;
  showModifyModal: (poetry: PoetryEntity) => void;
  handleActivatePoetry: (id: number) => void;
  handleHighlightPoetry: (id: number) => void;
};

export function PoetryColumns(props: PoetryColumnProps) {
  const navigate = useAppNavigate();

  const handleOpenDeleteModal = (id: number) => {
    props.handleDeleteModalVisible();
    navigate("", { state: { id } });
  };

  return [
    {
      title: "poetry-page-table-header-title",
      dataIndex: "title",
      suffix: (
        <SortIcon
          order={props.titleOrder}
          orderFunction={props.handleTitleOrder}
        />
      ),
    },
    {
      title: "poetry-page-table-header-class",
      dataIndex: "class",
    },
    {
      title: "poetry-page-table-header-recommended",
      dataIndex: "recommended",
      render: (record: PoetryEntity) => {
        return (
          <div
            className={`activity-circle ${record?.recommended ? "active" : ""}`}
          />
        );
      },
    },
    {
      title: "poetry-page-table-header-highlighted",
      dataIndex: "highlighted",
      render: (record: PoetryEntity) => {
        return (
          <div
            className={`activity-circle ${
              record?.highlighted ? "active" : ""
            } clickable`}
            onClick={() => props.handleHighlightPoetry(record?.id)}
          />
        );
      },
    },
    {
      title: "poetry-page-table-header-writing-date",
      dataIndex: "timeOfWritting",
      suffix: (
        <SortIcon
          order={props.writingDateOrder}
          orderFunction={props.handleWritingDateOrder}
        />
      ),
    },
    {
      title: "poetry-page-table-header-poet",
      dataIndex: "poet",
      render: (record: PoetryEntity) => <>{record?.poet?.name}</>,
    },
    {
      title: "poetry-page-table-header-is-active",
      dataIndex: "isActive",
      render: (record: PoetryEntity) => {
        return (
          <div
            onClick={() => props.handleActivatePoetry(record?.id)}
            className={`activity-circle ${
              record?.isActive ? "active" : ""
            } clickable`}
          />
        );
      },
    },
    {
      title: "parent-page-table-header-updated",
      dataIndex: "updated",
      render: (record: PoetryEntity) => {
        return <span>{new Date(record?.updated).toLocaleDateString()}</span>;
      },
    },
    {
      title: "parent-page-table-header-created",
      dataIndex: "created",
      render: (record: PoetryEntity) => {
        return <span>{new Date(record?.created).toLocaleDateString()}</span>;
      },
    },
    {
      title: "poetry-page-table-header-details",
      dataIndex: "details",
      render: (record: PoetryEntity) => {
        return (
          <DetailsIcon
            onClick={() => navigate(`${record?.id}/${record?.title}/facts`)}
          />
        );
      },
    },
    {
      title: "poetry-page-table-header-edit",
      dataIndex: "edit",
      render: (record: PoetryEntity) => {
        return <EditIcon onClick={() => props.showModifyModal(record)} />;
      },
    },
    {
      title: "poetry-page-table-header-delete",
      dataIndex: "delete",
      render: (record: PoetryEntity) => {
        return <DeleteIcon onClick={() => handleOpenDeleteModal(record?.id)} />;
      },
    },
  ];
}
