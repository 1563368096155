import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  QueryKeys,
  useAppLocation,
  useAppState,
  useAppTranslation,
} from "../../../common";
import { ErrorModal, Modal } from "../../../components";
import { deleteAudiosOfLineOfPoetryAction } from "../../../actions";

type LineOfPoetryDeleteModalProps = {
  handleDeleteModalVisible: () => void;
};

export function LineOfPoetryDeleteAudioModal(
  props: LineOfPoetryDeleteModalProps
) {
  const { t } = useAppTranslation();
  const location = useAppLocation();

  const queryClient = useQueryClient();
  const [state, setState] = useAppState({
    errorModalVisible: false,
  });

  const handleModalVisibility = () => {
    setState((prevState) => ({
      ...prevState,
      errorModalVisible: !prevState.errorModalVisible,
    }));
  };

  const deleteLine = useMutation({
    mutationKey: [QueryKeys.deleteAudioOfLineOfPoetry],
    mutationFn: deleteAudiosOfLineOfPoetryAction,
    onSuccess() {
      props.handleDeleteModalVisible();
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.linesOfPoetryQuery],
      });
    },
    onError() {
      handleModalVisibility();
    },
  });

  const handleDeleteLine = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    deleteLine.mutate({ id: location.state?.audioId });
  };

  return (
    <div>
      <section>
        <Modal
          onClose={props.handleDeleteModalVisible}
          title={t("line-of-poetry-page-delete-audio-modal-title")}
          footer
          submitText={t("line-of-poetry-page-delete-audio-submit-button-title")}
          cancelText={t("line-of-poetry-page-delete-audio-cancel-button-title")}
          handleSubmit={handleDeleteLine}
          loading={deleteLine.isPending}
        >
          <span>{t("line-of-poetry-page-delete-audio-question-label")}</span>
        </Modal>
      </section>
      <section>
        {state.errorModalVisible && (
          <ErrorModal
            error={deleteLine.error}
            onClose={handleModalVisibility}
            title={t("line-of-poetry-page-delete-audio-error-modal-title")}
          />
        )}
      </section>
    </div>
  );
}
