import axios from "axios";
import { GetAllAchievementDTO } from "../../dto";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";

type GetAllAchievementProps = {
  page: number;
  pageSize: number;
  type?: string;
  searchTerm?: string;
  level?: string;
};

let body: GetAllAchievementDTO;

export async function getAllAchievementAction(props: GetAllAchievementProps) {
  const token = localStorage.getItem("token");

  const params = {
    page: props.page,
    pageSize: props.pageSize,
    type: props.type !== "" ? props.type : undefined,
    searchTerm: props.searchTerm !== "" ? props.searchTerm : "",
    level: props.level !== "" ? props.level : undefined,
  };

  return await axios
    .get(`${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/achievements/get-all`, {
      headers: {
        "Content-Type": "application/json",
        "app-platform": "cms",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then(async (res) => {
      body = plainToClass(GetAllAchievementDTO, res.data);
      const errors = await validate(body);

      if (errors.length > 0) {
        throw errors;
      }

      return res.data;
    });
}
