import { Expose } from "class-transformer";
import { IsDateString, IsDefined, IsNumber, IsOptional } from "class-validator";

export class SettingEntity {
  @Expose()
  @IsDefined()
  @IsNumber()
  id!: number;

  @IsOptional()
  @IsDateString()
  tierResetTime?: string;

  @IsOptional()
  @IsDateString()
  updated?: string;
}
