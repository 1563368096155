import axios from "axios";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { GetAllInlineInfoDTO } from "../../dto";

type GetAllTierProps = {
  lineId: number;
  page: number;
  pageSize: number;
  searchTerm?: string;
};

let body: GetAllInlineInfoDTO;

export async function getAllInlineInfoAction(props: GetAllTierProps) {
  const token = localStorage.getItem("token");

  const params = {
    linesOfPoetriesId: props.lineId,
    page: props.page,
    pageSize: props.pageSize,
    searchTerm: props.searchTerm,
  };

  return await axios
    .get(`${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/inline-infos/get-all`, {
      headers: {
        "Content-Type": "application/json",
        "app-platform": "cms",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then(async (res) => {
      body = plainToClass(GetAllInlineInfoDTO, res.data);
      const errors = await validate(body);

      if (errors.length > 0) {
        throw errors;
      }

      return res.data;
    });
}
