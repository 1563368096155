import { useAppState } from "../../common";
import "./tooltip.scss";

type TooltipProps = {
  content: string | undefined;
  children: JSX.Element;
};

export function Tooltip(props: TooltipProps) {
  const [state, setState] = useAppState({
    showTooltip: false,
  });

  const toggleTooltip = (show: boolean) => {
    setState((prevState) => ({ ...prevState, showTooltip: show }));
  };

  return (
    <div className="tooltip-container">
      <section>
        <div
          onMouseEnter={() => toggleTooltip(true)}
          onMouseLeave={() => toggleTooltip(false)}
        >
          {props.children}
        </div>
      </section>
      <section>
        {state.showTooltip && props.content && (
          <div className="tooltip-content">{props.content}</div>
        )}
      </section>
    </div>
  );
}
