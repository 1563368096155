import axios from "axios";

type LoginActionProps = {
  email: string;
  password: string;
};

export async function loginAction(props: LoginActionProps) {
  const params = {
    email: props.email,
    password: props.password,
  };

  return await axios
    .post(
      `${process.env.REACT_APP_API_ORIGIN}/1.0.0/auth/admin/login`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          "app-platform": "cms",
        },
      }
    )
    .then((res) => res.data)
}
