import { useAppNavigate, useAppTranslation } from "../../../common";
import { DeleteIcon, FilterIcon, ModifyIcon } from "../../../components";
import { AchievementEntity } from "../../../entity";
import { levelFilterOptions, typeFilterOptions } from "../static";

type AchievementColumnProps = {
  handleTypeChange: (value: string) => void;
  handleLevelChange: (value: string) => void;
  handleDeleteModalVisible: () => void;
  handleMutateModalVisible: (value: boolean) => void;
};

export function AchievementColumns(props: AchievementColumnProps) {
  const navigate = useAppNavigate();
  const { t } = useAppTranslation();

  const handleOpenDeleteModal = (id: number) => {
    props.handleDeleteModalVisible();
    navigate("", { state: { id } });
  };

  const handleOpenModifyModal = (achievement: AchievementEntity) => {
    props.handleMutateModalVisible(true);
    navigate("", { state: { achievement } });
  };

  return [
    {
      title: "achievement-page-table-header-badge-url",
      dataIndex: "badgeUrl",
      render: (record: AchievementEntity) => {
        return (
          <img
            className="achievement-badge-url"
            src={record?.badgeUrl ?? "/pictures/image-placeholder-icon.png"}
            alt={`${record?.type}-${record?.level}`}
          />
        );
      },
    },
    {
      title: "achievement-page-table-header-name",
      dataIndex: "name",
    },
    {
      title: "achievement-page-table-header-description",
      dataIndex: "description",
    },
    {
      title: "achievement-page-table-header-type",
      dataIndex: "type",
      suffix: (
        <FilterIcon
          onFilter={props.handleTypeChange}
          filterOptions={typeFilterOptions}
        />
      ),
      render: (record: AchievementEntity) => {
        const type = typeFilterOptions.find(
          (type) => type.value === record?.type
        );
        return <span>{t(type?.label!)}</span>;
      },
    },
    {
      title: "achievement-page-table-header-level",
      dataIndex: "level",
      suffix: (
        <FilterIcon
          onFilter={props.handleLevelChange}
          filterOptions={levelFilterOptions}
        />
      ),
      render: (record: AchievementEntity) => {
        const level = levelFilterOptions.find(
          (level) => level.value === record?.level
        );
        return <span>{t(level?.label!)}</span>;
      },
    },
    {
      title: "achievement-page-table-header-created",
      dataIndex: "created",
      render: (record: AchievementEntity) => {
        return <span>{new Date(record?.created!).toLocaleDateString()}</span>;
      },
    },
    {
      title: "achievement-page-table-header-updated",
      dataIndex: "updated",
      render: (record: AchievementEntity) => {
        return <span>{new Date(record?.updated!).toLocaleDateString()}</span>;
      },
    },
    {
      title: "achievement-page-table-header-modify",
      dataIndex: "modify",
      render: (record: AchievementEntity) => {
        return <ModifyIcon onClick={() => handleOpenModifyModal(record)} />;
      },
    },
    {
      title: "achievement-page-table-header-delete",
      dataIndex: "delete",
      render: (record: AchievementEntity) => {
        return <DeleteIcon onClick={() => handleOpenDeleteModal(record?.id)} />;
      },
    },
  ];
}
