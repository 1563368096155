import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  QueryKeys,
  useAppNavigate,
  useAppState,
  useAppTranslation,
} from "../../common";
import { Button, ErrorModal, Input, Table } from "../../components";
import {
  AchievementColumns,
  AchievementDeleteModal,
  AchievementMutationForm,
} from "./components";
import { getAllAchievementAction } from "../../actions";
import "./achievement.page.scss";

export function AchievementPage() {
  const navigate = useAppNavigate();
  const { t } = useAppTranslation();
  const [state, setState] = useAppState({
    page: 1,
    pageSize: 10,
    searchTerm: "",
    type: "" as
      | "LISTENER"
      | "DISAPPEARING_GAME"
      | "PUZZLE_GAME"
      | "EMOJI"
      | "SOCCER"
      | "RECITATION"
      | "PARENT_RECITATION"
      | "QUIZ"
      | "LEARNING_ROW"
      | "TIER"
      | "WEEKEND"
      | "",
    level: "" as "1" | "2" | "3" | "",
    mutateModalVisible: false,
    errorModalVisible: false,
    deleteModalVisible: false,
    modify: false,
  });

  const getAllAchievement = useQuery({
    queryKey: [QueryKeys.achievementQuery],
    queryFn: () => getAllAchievementAction(state),
  });

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, searchTerm: e.target.value }));
  };

  const handleMutateModalVisible = (modify: boolean) => {
    navigate("", { state: undefined });
    setState((prevState) => ({
      ...prevState,
      mutateModalVisible: !prevState.mutateModalVisible,
      modify,
    }));
  };

  const handleSetPage = (value: number) => {
    setState((prevState) => ({ ...prevState, page: value }));
  };

  const handleSetPageSize = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setState((prevState) => ({
      ...prevState,
      pageSize: parseInt(e.target.value),
    }));
  };

  const handleTypeChange = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      type: value as
        | "LISTENER"
        | "DISAPPEARING_GAME"
        | "PUZZLE_GAME"
        | "EMOJI"
        | "SOCCER"
        | "RECITATION"
        | "PARENT_RECITATION"
        | "QUIZ"
        | "LEARNING_ROW"
        | "TIER"
        | "WEEKEND"
        | "",
    }));
  };

  const handleLevelChange = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      level: value as "1" | "2" | "3" | "",
    }));
  };

  const handleDeleteModalVisible = () => {
    setState((prevState) => ({
      ...prevState,
      deleteModalVisible: !prevState.deleteModalVisible,
    }));
  };

  const handleCloseErrorModal = () => {
    setState((prevState) => ({ ...prevState, errorModalVisible: false }));
  };

  useEffect(() => {
    if (getAllAchievement.isError) {
      setState((prevState) => ({ ...prevState, errorModalVisible: true }));
    }
  }, [getAllAchievement.isError, setState]);

  useEffect(() => {
    getAllAchievement.refetch();
  }, [state, getAllAchievement]);

  return (
    <main className="page">
      <div className="achievement-page">
        <section className="title">{t("achievement-page-title")}</section>
        <section className="search-bar-container">
          <Input
            onChange={handleSearchTermChange}
            value={state.searchTerm}
            placeholder={t("achievement-page-search-bar-placeholder")}
            type="search"
            className="searchbar"
          />
          <Button
            onClick={() => handleMutateModalVisible(false)}
            title="+"
            htmlType="button"
            creationButton
          />
        </section>
        <section>
          <Table
            columns={Object.values(
              AchievementColumns({
                handleTypeChange,
                handleLevelChange,
                handleDeleteModalVisible,
                handleMutateModalVisible,
              })
            )}
            paginatorNeeded
            data={getAllAchievement.data?.data}
            loading={getAllAchievement.isLoading}
            page={getAllAchievement.data?.page}
            pageSize={getAllAchievement.data?.pageSize}
            allPage={getAllAchievement.data?.count}
            handleSetPage={handleSetPage}
            handleSetPageSize={handleSetPageSize}
          />
        </section>
      </div>
      <section>
        {state.mutateModalVisible && (
          <AchievementMutationForm
            modify={state.modify}
            handleMutationModalVisible={handleMutateModalVisible}
          />
        )}
      </section>
      <section>
        {state.deleteModalVisible && (
          <AchievementDeleteModal
            handleDeleteModalVisible={handleDeleteModalVisible}
          />
        )}
      </section>
      <section>
        {state.errorModalVisible && (
          <ErrorModal
            onClose={handleCloseErrorModal}
            title={t("achievement-page-error-modal-title")}
            error={getAllAchievement.error}
          />
        )}
      </section>
    </main>
  );
}
