import { LoadingIcon } from "../icon";
import "./button.scss";

type ButtonProps = {
  onClick?: (e: any) => void;
  title: string;
  htmlType: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
  loading?: boolean;
  creationButton?: boolean;
  poetryButton?: boolean;
  permanentActive?: boolean;
};

export function Button(props: ButtonProps) {
  return (
    <button
      type={props.htmlType}
      onClick={props.onClick}
      disabled={props.disabled}
      className={`button ${props.disabled ? "disabled" : ""} ${
        props.creationButton ? "creation-button" : ""
      } ${props.poetryButton ? "poetry-button" : ""} ${
        props.permanentActive ? "permanent-active" : ""
      }`}
    >
      {props.loading && <LoadingIcon />}
      {props.title}
    </button>
  );
}
