import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  QueryKeys,
  mutationHandler,
  useAppState,
  useAppTranslation,
  PageType,
} from "../../../common";
import { createAdminAction } from "../../../actions";
import {
  ErrorModal,
  Form,
  Input,
  Modal,
  ValidationErrorModal,
} from "../../../components";
import { AdminEntity } from "../../../entity";

type AdminMutationFormProps = {
  handleCreationModalVisible: () => void;
  latestQueryKey: any;
};

export function AdminMutationForm(props: AdminMutationFormProps) {
  const queryClient = useQueryClient();
  const { t } = useAppTranslation();
  const [state, setState] = useAppState({
    email: "",
    validationErrorMessage: null as string | null,
  });

  const createAdmin = useMutation({
    mutationKey: [QueryKeys.mutateAdminQuery],
    mutationFn: createAdminAction,
    onMutate: async (newAdmin) => {
      await queryClient.cancelQueries({
        queryKey: props.latestQueryKey,
      });
      const previousAdmins = queryClient.getQueryData([props.latestQueryKey]);

      queryClient.setQueryData(
        props.latestQueryKey,
        (old: PageType<AdminEntity>) => {
          const handler = mutationHandler({
            dataLength: old.data.length,
            pageSize: old.pageSize,
            count: old.count,
            oldData: old.data,
            newData: newAdmin,
          });

          return {
            page: old.page,
            pageSize: old.pageSize,
            count: handler.newCount,
            data: handler.newData,
          };
        }
      );

      return { previousAdmins };
    },

    onError(error, variables, context) {
      queryClient.setQueryData([props.latestQueryKey], context?.previousAdmins);
    },
    onSuccess() {
      props.handleCreationModalVisible();
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.adminQuery] });
    },
  });

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, email: e.target.value }));
  };

  const handleCloseErrorModal = () => {
    createAdmin.reset();
  };

  const checkForm = () => {
    if (state.email.trim().length === 0) {
      setState({
        ...state,
        validationErrorMessage: `Az e-mail cím kitöltése kötelező!`,
      });
      return false;
    }
    return true;
  };

  const handleCreateAdmin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!checkForm()) {
      return;
    }
    createAdmin.mutate({ email: state.email });
  };

  return (
    <div>
      <section>
        <Modal
          onClose={props.handleCreationModalVisible}
          title={t("admin-page-mutate-admin-modal-title")}
          footer
          cancelText={t("admin-page-mutate-admin-modal-cancel-label")}
          submitText={t("admin-page-mutate-admin-modal-submit-label")}
          handleSubmit={handleCreateAdmin}
          loading={createAdmin.isPending}
        >
          <Form onSubmit={handleCreateAdmin}>
            <Input
              type="text"
              maxLength={255}
              className="mutate-modal-input"
              onChange={handleEmailChange}
              value={state.email}
              placeholder={t("admin-page-mutate-admin-modal-email-placeholder")}
            />
          </Form>
        </Modal>
      </section>
      <section>
        {createAdmin.error && (
          <ErrorModal
            error={createAdmin.error}
            onClose={handleCloseErrorModal}
            title={t("admin-page-mutate-admin-form-error-modal-title")}
          />
        )}
      </section>
      <section>
        {state.validationErrorMessage && (
          <ValidationErrorModal
            error={state.validationErrorMessage}
            onClose={() => setState({ ...state, validationErrorMessage: null })}
          />
        )}
      </section>
    </div>
  );
}
