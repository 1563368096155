import { useMutation, useQuery } from "@tanstack/react-query";
import {
  QueryKeys,
  useAppNavigate,
  useAppState,
  useAppTranslation,
} from "../../common";
import { Button, ErrorModal, Form, Input } from "../../components";
import { modifyProfileAction } from "../../actions/admin/modify-profile.action";
import { getMeAction } from "../../actions";

import "./profile.page.scss";

export function ProfilePage() {
  const { t } = useAppTranslation();
  const navigate = useAppNavigate();
  const [state, setState] = useAppState({
    oldPassword: "",
    newPassword: "",
  });

  const getMe = useQuery({
    queryKey: [QueryKeys.userQuery],
    queryFn: getMeAction,
    staleTime: 1000 * 60 * 5,
  });

  const modifyProfile = useMutation({
    mutationKey: [QueryKeys.profileMutationQuery],
    mutationFn: modifyProfileAction,
    onSuccess() {
      navigate("/cms/parents");
    },
  });

  const handleCloseModal = () => {
    modifyProfile.reset();
  };

  const handleOldPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      oldPassword: e.target.value,
    }));
  };

  const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      newPassword: e.target.value,
    }));
  };

  const handleModifyPassword = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    modifyProfile.mutate({
      password: state.newPassword,
      passwordCheck: state.oldPassword,
    });
  };

  return (
    <main className="page">
      <div className="profile-page">
        <section className="title">{t("profile-page-title")}</section>
        <section className="profile-form">
          <Form onSubmit={handleModifyPassword}>
            <Input
              className="profile-input"
              type="text"
              value={getMe.data?.admin.email}
              onChange={() => null}
              disabled
              placeholder={getMe.data?.admin.email}
            />
            <Input
              className="profile-input"
              type="password"
              value={state.oldPassword}
              onChange={handleOldPasswordChange}
              placeholder={t("profile-page-old-password-placeholder")}
            />
            <Input
              className="profile-input"
              type="password"
              value={state.newPassword}
              onChange={handleNewPasswordChange}
              placeholder={t("profile-page-new-password-placeholder")}
            />
            <section className="profile-button">
              <Button
                htmlType="submit"
                title={t("profile-page-submit-button-title")}
                loading={modifyProfile.isPending}
              />
            </section>
          </Form>
        </section>
      </div>
      <section>
        {modifyProfile.isError && (
          <ErrorModal
            error={modifyProfile.error}
            title={t("profile-page-error-modal-title")}
            onClose={handleCloseModal}
          />
        )}
      </section>
    </main>
  );
}
