import { useAppNavigate, useAppTranslation } from "../../../common";
import {
    DeleteIcon, DetailsIcon, EditIcon, FilterIcon, SortIcon
} from "../../../components"
import { AchievementEntity, ChildEntity } from "../../../entity";
import { genderFilterOptions, sleeptimeFilterOptions } from "../static";
import React from "react"

type ChildColumnProps = {
  handleClassOrder: (value: string) => void;
  classOrder: "ASC" | "DESC" | "";
  handleNicknameOrder: (value: string) => void;
  nicknameOrder: "ASC" | "DESC" | "";
  handleGenderChange: (value: string) => void;
  gender: "1" | "2" | "";
  handleSleepTimeChange: (value: string) => void;
  sleepTime: "0" | "1" | "2" | "";
  handleActivityOrder: (value: string) => void;
  latestActivityOrder: "ASC" | "DESC" | "";
  handleEditModalVisible: (id?: number) => void;
  handleDeleteModalVisible: () => void;
  handleAchievementModalVisible: (
    achievements: AchievementEntity[],
    childName: string
  ) => void;
};

export function ChildColumns(props: ChildColumnProps) {
  const { t } = useAppTranslation();
  const navigate = useAppNavigate();

  const handleOpenDeleteModal = (id: number) => {
    props.handleDeleteModalVisible();
    navigate("", { state: { id } });
  };

    const handleOpenEditModal = (id: number) => {
        props.handleEditModalVisible(id);
        navigate("", { state: { id } });
    };

  return [
    {
      title: "child-page-table-header-profile-picture",
      dataIndex: "pictureUrl",
      render: (record: ChildEntity) => {
        return (
          <img
            className="child-profile-picture"
            src={record?.pictureUrl ?? "/pictures/image-placeholder-icon.png"}
            alt={record?.nickname}
          />
        );
      },
    },
    {
      title: "child-page-table-header-nickname",
      dataIndex: "nickname",
      suffix: (
        <SortIcon
          order={props.nicknameOrder}
          orderFunction={props.handleNicknameOrder}
        />
      ),
    },
    {
      title: "child-page-table-header-class",
      dataIndex: "class",
      suffix: (
        <SortIcon
          order={props.classOrder}
          orderFunction={props.handleClassOrder}
        />
      ),
    },
    {
      title: "child-page-table-header-gender",
      dataIndex: "gender",
      suffix: (
        <FilterIcon
          onFilter={props.handleGenderChange}
          filterOptions={genderFilterOptions}
        />
      ),
      render: (record: ChildEntity) => {
        const gender = genderFilterOptions.find(
          (gender) => gender.value === parseInt(record?.gender)
        );
        return <span>{t(gender?.label!)}</span>;
      },
    },
    {
      title: "child-page-table-header-sleep-time",
      dataIndex: "sleepTime",
      suffix: (
        <FilterIcon
          onFilter={props.handleSleepTimeChange}
          filterOptions={sleeptimeFilterOptions}
        />
      ),
      render: (record: ChildEntity) => {
        const sleepTime = sleeptimeFilterOptions.find(
          (sleep) => sleep.value === parseInt(record?.sleepTime)
        );
        return <span>{t(sleepTime?.label!)}</span>;
      },
    },
    {
      title: "child-page-table-header-learning-period",
      dataIndex: "learningPeriod",
      render: (record: ChildEntity) => {
        return (
          <span>
            {record?.learingPeriodFrom && record?.learingPeriodTo
              ? `${record?.learingPeriodFrom} - ${record?.learingPeriodTo}`
              : null}
          </span>
        );
      },
    },
    {
      title: "child-page-table-header-sound-notifications",
      dataIndex: "soundNotifications",
      render: (record: ChildEntity) => {
        return (
          <div
            className={`activity-circle ${
              record?.soundEffects ? "active" : ""
            }`}
          />
        );
      },
    },
    {
      title: "child-page-table-header-weekly-goal",
      dataIndex: "weeklyGoal",
      render: (record: ChildEntity) => {
        return (
          <div
            className={`activity-circle ${record?.weekGoal ? "active" : ""}`}
          />
        );
      },
    },
    {
      title: "child-page-table-header-latest-activity",
      dataIndex: "lastActivity",
      suffix: (
        <SortIcon
          order={props.latestActivityOrder}
          orderFunction={props.handleActivityOrder}
        />
      ),
      render: (record: ChildEntity) => {
        return <span>{record?.lastActivity?.toLocaleString()}</span>;
      },
    },
    {
      title: "child-page-table-header-achievements",
      dataIndex: "achievements",
      render: (record: ChildEntity) => {
        return (
          <DetailsIcon
            onClick={() =>
              props.handleAchievementModalVisible(
                record?.achievements,
                record?.nickname
              )
            }
          />
        );
      },
    },
    {
      title: "child-page-table-header-tier",
      dataIndex: "tier",
      render: (record: ChildEntity) => {
        return <span>{record?.tier?.name}</span>;
      },
    },
    {
      title: "child-page-table-header-created",
      dataIndex: "created",
      render: (record: ChildEntity) => {
        return <span>{new Date(record?.created).toLocaleDateString()}</span>;
      },
    },
    {
      title: "child-page-table-header-edit",
      dataIndex: "edit",
      render: (record: ChildEntity) => {
          return <EditIcon onClick={() => handleOpenEditModal(record?.id)} />;
      },
    },
    {
      title: "child-page-table-header-delete",
      dataIndex: "delete",
      render: (record: ChildEntity) => {
        return <DeleteIcon onClick={() => handleOpenDeleteModal(record?.id)} />;
      },
    },
  ];
}
