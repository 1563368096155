import { Expose, Type } from "class-transformer";
import { ValidateNested } from "class-validator";
import { FactEntity } from "../../entity";

export class MutateFactDTO {
  @Expose()
  @ValidateNested()
  @Type(() => FactEntity)
  fact!: FactEntity;
}
