import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  QueryKeys,
  useAppState,
  useAppTranslation,
  useDebounce,
} from "../../common";
import { Button, ErrorModal, Input, Table } from "../../components";
import {
  changePoetryActivationAction,
  changePoetryActivationHighlight,
  getAllPoetryAction,
} from "../../actions/poetry";
import { PoetryColumns, PoetryDeleteModal, PoetryModal } from "./components";
import { PoetryEntity } from "../../entity";
import "./poetry.page.scss";

export function PoetryPage() {
  const queryClient = useQueryClient();
  const { t } = useAppTranslation();
  const [state, setState] = useAppState({
    page: 1,
    pageSize: 10,
    search: "",
    titleOrder: "" as "ASC" | "DESC" | "",
    writingDateOrder: "" as "ASC" | "DESC" | "",
    deleteModalVisible: false,
    creationModalVisible: false,
    selectedPoetry: null as PoetryEntity | null,
    searchTerm: "",
  });

  const handleSearch = useDebounce((searchTerm: string) => {
    setState((prevState) => ({ ...prevState, searchTerm: searchTerm }));
  }, 250);

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, search: e.target.value }));

    handleSearch(e.target.value);
  };

  const getAllPoetry = useQuery({
    queryKey: [
      QueryKeys.poetryQuery,
      {
        page: state.page,
        pageSize: state.pageSize,
        searchTerm: state.searchTerm,
        titleOrder: state.titleOrder,
        writingDateOrder: state.writingDateOrder,
      },
    ],
    queryFn: () =>
      getAllPoetryAction({
        page: state.page,
        pageSize: state.pageSize,
        searchTerm: state.searchTerm,
        titleOrder: state.titleOrder,
        writingDateOrder: state.writingDateOrder,
      }),
  });

  const handleTitleOrder = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      titleOrder: value as "ASC" | "DESC" | "",
    }));
  };

  const handleWritingDateOrder = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      writingDateOrder: value as "ASC" | "DESC" | "",
    }));
  };

  const handleSetPage = (value: number) => {
    setState((prevState) => ({ ...prevState, page: value }));
  };

  const handleSetPageSize = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setState((prevState) => ({
      ...prevState,
      pageSize: parseInt(e.target.value),
    }));
  };

  const handleDeleteModalVisible = () => {
    setState((prevState) => ({
      ...prevState,
      deleteModalVisible: !prevState.deleteModalVisible,
    }));
  };

  const handleCreationModalClose = () => {
    setState((prevState) => ({
      ...prevState,
      selectedPoetry: null,
      creationModalVisible: false,
    }));
  };

  const showModifyModal = (poetry: PoetryEntity) => {
    setState({ ...state, selectedPoetry: poetry, creationModalVisible: true });
  };

  const poetryActivationMutation = useMutation({
    mutationKey: [QueryKeys.activatePoetryQuery],
    mutationFn: changePoetryActivationAction,
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.poetryQuery] });
    },
  });

  const poetryHighlightMutation = useMutation({
    mutationKey: [QueryKeys.highlightPoetryQuery],
    mutationFn: changePoetryActivationHighlight,
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.poetryQuery] });
    },
  });

  const handleActivatePoetry = (id: number) => {
    poetryActivationMutation.mutate({ id });
  };

  const handleHighlightPoetry = (id: number) => {
    poetryHighlightMutation.mutate({ id });
  };

  const handleCloseErrorModal = () => {
    if (getAllPoetry.isError) {
      getAllPoetry.refetch();
    } else {
      poetryActivationMutation.reset();
    }
  };

  return (
    <main className="page">
      <div className="poetry-page">
        <section className="title">{t("poetry-page-title")}</section>
        <section className="search-bar-container">
          <Input
            onChange={handleSearchTermChange}
            value={state.search}
            placeholder={t("parent-page-search-bar-placeholder")}
            type="search"
            className="searchbar"
          />
          <Button
            onClick={() => setState({ ...state, creationModalVisible: true })}
            title="+"
            htmlType="button"
            creationButton
          />
        </section>
        <section>
          <Table
            columns={Object.values(
              PoetryColumns({
                titleOrder: state.titleOrder,
                writingDateOrder: state.writingDateOrder,
                handleTitleOrder,
                handleWritingDateOrder,
                handleDeleteModalVisible,
                showModifyModal,
                handleActivatePoetry,
                handleHighlightPoetry,
              })
            )}
            paginatorNeeded
            data={getAllPoetry.data?.data}
            loading={getAllPoetry.isLoading}
            page={getAllPoetry.data?.page}
            pageSize={getAllPoetry.data?.pageSize}
            allPage={getAllPoetry.data?.count}
            handleSetPage={handleSetPage}
            handleSetPageSize={handleSetPageSize}
          />
        </section>
      </div>
      <section>
        {state.creationModalVisible && (
          <PoetryModal
            poetry={state.selectedPoetry}
            close={handleCreationModalClose}
            latestQueryKey={[
              QueryKeys.poetryQuery,
              {
                page: state.page,
                pageSize: state.pageSize,
                searchTerm: state.searchTerm,
                titleOrder: state.titleOrder,
                writingDateOrder: state.writingDateOrder,
              },
            ]}
          />
        )}
      </section>
      <section>
        {state.deleteModalVisible && (
          <PoetryDeleteModal
            handleDeleteModalVisible={handleDeleteModalVisible}
          />
        )}
      </section>
      <section>
        {(getAllPoetry.isError || poetryActivationMutation.isError) && (
          <ErrorModal
            onClose={handleCloseErrorModal}
            title={
              getAllPoetry.error
                ? t("parent-page-error-modal-title")
                : t("poetry-page-activation-error-modal-title")
            }
            error={getAllPoetry.error ?? poetryActivationMutation.error}
          />
        )}
      </section>
    </main>
  );
}
