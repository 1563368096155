import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  PageType,
  QueryKeys,
  mutationHandler,
  useAppLocation,
  useAppState,
  useAppTranslation,
} from "../../../common";
import { ErrorModal, Modal } from "../../../components";
import { deleteInlineInfoAction } from "../../../actions";
import { InlineInfoEntity } from "../../../entity";

type InlineInfoDeleteModalProps = {
  handleDeleteModalVisible: () => void;
  handleSetPage: (value: number) => void;
};

export function InlineInfoDeleteModal(props: InlineInfoDeleteModalProps) {
  const { t } = useAppTranslation();
  const location = useAppLocation();

  const queryClient = useQueryClient();
  const [state, setState] = useAppState({
    errorModalVisible: false,
  });

  const handleModalVisibility = () => {
    setState((prevState) => ({
      ...prevState,
      errorModalVisible: !prevState.errorModalVisible,
    }));
  };

  const deleteInfo = useMutation({
    mutationKey: [QueryKeys.deleteInlineInfo],
    mutationFn: deleteInlineInfoAction,
    onSuccess() {
      props.handleDeleteModalVisible();
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.inlineInfos],
      });
      let page = 1;

      queryClient.setQueryData(
        [QueryKeys.inlineInfos],
        (old: PageType<InlineInfoEntity>) => {
          const handler = mutationHandler({
            dataLength: old.data.length,
            pageSize: old.pageSize,
            count: old.count,
          });
          page = handler.page;

          return {
            page: old.page,
            pageSize: old.pageSize,
            count: handler.page,
            data: old.data,
          };
        }
      );
      props.handleSetPage(page);
    },
    onError() {
      handleModalVisibility();
    },
  });

  const handleDeleteInfo = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    deleteInfo.mutate({ id: location.state?.id });
  };

  return (
    <div>
      <section>
        <Modal
          onClose={props.handleDeleteModalVisible}
          title={t("inline-info-page-delete-modal-title")}
          footer
          submitText={t("inline-info-page-delete-submit-button-title")}
          cancelText={t("inline-info-page-delete-cancel-button-title")}
          handleSubmit={handleDeleteInfo}
          loading={deleteInfo.isPending}
        >
          <span>{t("inline-info-page-delete-info-question-label")}</span>
        </Modal>
      </section>
      <section>
        {state.errorModalVisible && (
          <ErrorModal
            error={deleteInfo.error}
            onClose={handleModalVisibility}
            title={t("inline-info-page-delete-info-error-modal-title")}
          />
        )}
      </section>
    </div>
  );
}
