import { useLocation } from "react-router-dom";
import { useAppNavigate, useAppParams, useAppTranslation } from "../../common";
import { Button, PaginatorIcon } from "../../components";
import "./poetry-subpages.scss";

export function PoetrySubPages() {
  const navigate = useAppNavigate();
  const params = useAppParams();
  const { t } = useAppTranslation();
  const location = useLocation();

  const handleNavigate = (to: string) => {
    navigate(`/cms/poetry/${params.poetryId}/${params.poetryTitle}/${to}`);
  };

  return (
    <div className="poetry-subpage-breadrcumb">
      <PaginatorIcon
        type="previous"
        onClick={() => navigate("/cms/poetry")}
        active
      />
      <span className="poetry-subpage-breadcrumb-title">
        {params.poetryTitle}
      </span>
      <Button
        onClick={() => handleNavigate("facts")}
        title={t("poetry-facts-button-title")}
        htmlType="button"
        poetryButton
        permanentActive={location.pathname.includes("facts")}
      />
      <Button
        onClick={() => handleNavigate("chats")}
        title={t("poetry-chats-button-title")}
        htmlType="button"
        poetryButton
        permanentActive={location.pathname.includes("chats")}
      />
      <Button
        onClick={() => handleNavigate("lines")}
        title={t("poetry-lines-button-title")}
        htmlType="button"
        poetryButton
        permanentActive={location.pathname.includes("lines")}
      />
      <Button
        onClick={() => handleNavigate("quizzes")}
        title={t("poetry-quizzes-button-title")}
        htmlType="button"
        poetryButton
        permanentActive={location.pathname.includes("quizzes")}
      />
    </div>
  );
}
