import { Expose, Type } from "class-transformer";
import { IsArray, IsNumber, ValidateNested } from "class-validator";
import { PoetryChatEntity } from "../../entity";

export class GetAllPoetryChatDTO {
  @Expose()
  @ValidateNested()
  @IsArray()
  @Type(() => PoetryChatEntity)
  data!: PoetryChatEntity[];

  @Expose()
  @IsNumber()
  count!: number;

  @Expose()
  @IsNumber()
  page!: number;

  @Expose()
  @IsNumber()
  pageSize!: number;
}
