import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  PageType,
  QueryKeys,
  mutationHandler,
  useAppLocation,
  useAppParams,
  useAppState,
  useAppTranslation,
} from "../../../common";
import {
  ErrorModal,
  Form,
  Input,
  Modal,
  Upload,
  ValidationErrorModal,
} from "../../../components";
import {
  createLineOfPoetryAction,
  modifyLineOfPoetryAction,
} from "../../../actions";
import { LinesOfPoetriesEntity } from "../../../entity";

type LineOfPoetryMutationFormProps = {
  modify: boolean;
  handleMutationModalVisible: (value: boolean) => void;
  latestQueryKey: any;
};

export function LineOfPoetryMutationForm(props: LineOfPoetryMutationFormProps) {
  const queryClient = useQueryClient();
  const location = useAppLocation();
  const params = useAppParams();
  const { t } = useAppTranslation();

  const [state, setState] = useAppState({
    text: location.state?.line.text ?? "",
    audioYoung: null as unknown as File,
    audioOld: null as unknown as File,
    validationErrorMessage: null as string | null,
    stanza: location.state?.line.stanza ?? 1,
    unit: location.state?.line.unit ?? 1,
  });

  const mutateLine = useMutation({
    mutationKey: [
      props.modify
        ? QueryKeys.modifyLineOfPoetry
        : QueryKeys.createLineOfPoetry,
    ],
    mutationFn: (data: any) =>
      props.modify
        ? modifyLineOfPoetryAction(data)
        : createLineOfPoetryAction(data),
    onMutate: async (newLine) => {
      await queryClient.cancelQueries({
        queryKey: props.latestQueryKey,
      });

      const previousLines = queryClient.getQueryData([props.latestQueryKey]);
      queryClient.setQueryData(
        props.latestQueryKey,
        (old: PageType<LinesOfPoetriesEntity>) => {
          const handler = mutationHandler({
            dataLength: old.data.length,
            pageSize: old.pageSize,
            count: old.count,
            oldData: old.data,
            newData: newLine,
          });

          return {
            page: old.page,
            pageSize: old.pageSize,
            count: handler.newCount,
            data: handler.newData,
          };
        }
      );

      return { previousLines };
    },
    onSuccess() {
      props.handleMutationModalVisible(false);
    },
    onError(error, variables, context) {
      queryClient.setQueryData([props.latestQueryKey], context?.previousLines);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.linesOfPoetryQuery],
      });
    },
  });

  const handleCloseErrorModal = () => {
    mutateLine.reset();
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, text: e.target.value }));
  };

  const handleStanzaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const trueValue = Number(e.target.value);
    if (isNaN(trueValue) || trueValue < 0) return;

    setState((prevState) => ({ ...prevState, stanza: trueValue }));
  };

  const handleUnitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const trueValue = Number(e.target.value);
    if (isNaN(trueValue) || trueValue < 0) return;

    setState((prevState) => ({ ...prevState, unit: trueValue }));
  };

  const handleYoungAudioChange = (value: File) => {
    setState((prevState) => ({ ...prevState, audioYoung: value }));
  };

  const handleOldAudioChange = (value: File) => {
    setState((prevState) => ({ ...prevState, audioOld: value }));
  };

  const checkForm = () => {
    if (state.text.trim().length === 0) {
      setState({
        ...state,
        validationErrorMessage: `A verssor kitöltése kötelező!`,
      });
      return false;
    }

    return true;
  };

  const handleMutateLine = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!checkForm()) {
      return;
    }
    mutateLine.mutate({
      poetryId: params.poetryId,
      audioYoung: state.audioYoung,
      audioOld: state.audioOld,
      text: state.text,
      stanza: state.stanza,
      unit: state.unit,
      id: props.modify ? location.state?.line.id : undefined!,
    });
  };

  return (
    <div>
      <section>
        <Modal
          onClose={() => props.handleMutationModalVisible(false)}
          title={
            props.modify
              ? t("line-of-poetry-page-mutate-line-modal-modify-title")
              : t("line-of-poetry-page-mutate-line-modal-create-title")
          }
          footer
          cancelText={t("line-of-poetry-page-mutate-line-modal-cancel-label")}
          submitText={
            props.modify
              ? t("line-of-poetry-page-mutate-line-modal-modify-submit-label")
              : t("line-of-poetry-page-mutate-line-modal-create-submit-label")
          }
          handleSubmit={handleMutateLine}
          loading={mutateLine.isPending}
        >
          <Form onSubmit={handleMutateLine}>
            <div className="line-of-poetry-input">
              <Input
                type="text"
                maxLength={255}
                className="mutate-modal-input"
                onChange={handleTextChange}
                value={state.text}
                placeholder={t(
                  "line-of-poetry-page-mutate-line-modal-text-placeholder"
                )}
              />

              <Input
                type="text"
                maxLength={255}
                className="mutate-modal-input"
                onChange={handleStanzaChange}
                value={state.stanza}
                placeholder={t(
                  "line-of-poetry-page-mutate-line-modal-stanza-placeholder"
                )}
              />

              <Input
                type="text"
                maxLength={255}
                className="mutate-modal-input"
                onChange={handleUnitChange}
                value={String(state.unit)}
                placeholder={t(
                  "line-of-poetry-page-mutate-line-modal-unit-placeholder"
                )}
              />
            </div>

            <div className="picture-upload">
              <div className="picture-row">
                <Upload
                  type="audio"
                  label={t(
                    "line-of-poetry-page-mutate-line-modal-young-audio-title"
                  )}
                  handleChangeUpload={handleYoungAudioChange}
                  file={location.state?.line.audioYoungUrl ?? ""}
                  audioId="young-audio"
                />
                <Upload
                  label={t(
                    "line-of-poetry-page-mutate-line-modal-old-audio-title"
                  )}
                  type="audio"
                  handleChangeUpload={handleOldAudioChange}
                  file={location.state?.line.audioOldUrl ?? ""}
                  audioId="old-audio"
                />
              </div>
            </div>
          </Form>
        </Modal>
      </section>
      <section>
        {mutateLine.isError && (
          <ErrorModal
            error={mutateLine.error}
            onClose={handleCloseErrorModal}
            title={
              props.modify
                ? t(
                    "line-of-poetry-page-mutate-line-form-error-modal-modify-title"
                  )
                : t(
                    "line-of-poetry-page-mutate-line-form-error-modal-create-title"
                  )
            }
          />
        )}
      </section>
      <section>
        {state.validationErrorMessage && (
          <ValidationErrorModal
            error={state.validationErrorMessage}
            onClose={() =>
              setState({
                ...state,
                validationErrorMessage: null,
              })
            }
          />
        )}
      </section>
    </div>
  );
}
