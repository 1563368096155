import { Expose, Type } from "class-transformer";
import { IsArray, ValidateNested } from "class-validator";
import { PoetEntity } from "../../entity";

export class ListAllPoetDTO {
  @Expose()
  @ValidateNested()
  @IsArray()
  @Type(() => PoetEntity)
  poets!: PoetEntity[];
}
