type ModifyIconProps = {
  onClick: () => void;
};

export function ModifyIcon(props: ModifyIconProps) {
  return (
    <img
      src="/pictures/modify-icon.png"
      alt="modify"
      onClick={props.onClick}
      className="clickable-icon"
    />
  );
}
