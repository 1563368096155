import { NavLink } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  QueryKeys,
  RoleEnum,
  emailValidator,
  useAppNavigate,
  useAppState,
  useAppTranslation,
} from "../../common";
import { LoginDTO } from "../../dto";
import { Button, ErrorModal, Form, Input, Tooltip } from "../../components";
import { loginAction } from "../../actions";
import "./login.scss";

export function LoginPage() {
  const { t } = useAppTranslation();
  const navigate = useAppNavigate();
  const queryClient = useQueryClient();
  const [state, setState] = useAppState({
    email: "",
    password: "",
    modalVisible: false,
  });

  const login = useMutation({
    mutationKey: [QueryKeys.loginMutationQuery],
    mutationFn: loginAction,
    onSuccess(data: LoginDTO) {
      localStorage.setItem("token", data.admin.token);
      queryClient.setQueryData([QueryKeys.userQuery], data);
      if (data.admin.role === RoleEnum.SUPERVISOR) {
        navigate("/cms/admins");
      } else {
        navigate("/cms/parents");
      }
    },
    onError() {
      setState((prevState) => ({ ...prevState, modalVisible: true }));
    },
  });

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, email: e.target.value }));
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, password: e.target.value }));
  };

  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login.mutate({
      email: state.email,
      password: state.password,
    });
  };

  const handleCloseModal = () => {
    setState((prevState) => ({ ...prevState, modalVisible: false }));
  };

  const isEmailValid = (email: string) => {
    return emailValidator({ email });
  };

  return (
    <main>
      <section className="login-form">
        <Form onSubmit={handleLogin}>
          <Tooltip
            content={
              state.email.length > 0 && !isEmailValid(state.email)
                ? t("invalid-email-format")
                : ""
            }
          >
            <Input
              type="text"
              className={`login-input ${
                state.email.length > 0 && !isEmailValid(state.email)
                  ? "error"
                  : ""
              }`}
              value={state.email}
              onChange={handleEmailChange}
              placeholder={t("login-page-input-placeholder-email")}
            />
          </Tooltip>

          <Input
            type="password"
            className="login-input"
            value={state.password}
            onChange={handlePasswordChange}
            placeholder={t("login-page-password-input-placeholder")}
          />
          <div className="button-container">
            <Button
              htmlType="submit"
              title={t("login-page-button-title")}
              loading={login.isPending}
              disabled={
                state.password.length === 0 ||
                state.email.length === 0 ||
                !isEmailValid(state.email) ||
                login.isPending
              }
            />
          </div>
        </Form>
        <span className="forgot-password">
          <NavLink className={"link"} to={"forgot-password"}>
            {t("login-page-forgot-password-label")}
          </NavLink>
        </span>
      </section>
      {state.modalVisible && (
        <ErrorModal
          onClose={handleCloseModal}
          title={t("login-page-modal-error-title")}
          error={login.error}
        />
      )}
    </main>
  );
}
