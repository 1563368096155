import axios from "axios";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { MutatePoetDTO } from "../../dto";

type ModifyPoetProps = {
  youngAvatar?: File;
  oldAvatar?: File;
  idleAnimation?: File;
  happyAnimation?: File;
  name: string;
  id: number;
};

let body: MutatePoetDTO;

export async function modifyPoetAction(props: ModifyPoetProps) {
  const token = localStorage.getItem("token");

  const params = new FormData();
  if (props.youngAvatar) params.append("youngAvatar", props.youngAvatar);
  if (props.oldAvatar) params.append("oldAvatar", props.oldAvatar);
  if (props.idleAnimation) params.append("idleAnimation", props.idleAnimation);
  if (props.happyAnimation)
    params.append("happyAnimation", props.happyAnimation);
  params.append("name", props.name);
  params.append("id", props.id.toString());

  return await axios
    .patch(
      `${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/poets/modify`,
      params,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "app-platform": "cms",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(async (res) => {
      body = plainToClass(MutatePoetDTO, res.data);
      await validate(body);
      const errors = await validate(body);
      if (errors.length > 0) {
        throw errors;
      }

      return res.data;
    });
}
