import React from "react";
import { useAppState } from "../../common";
import { PasswordIcon } from "../icon/password";
import { SearchIcon } from "../icon";
import "./input.scss";

type InputProps = {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  placeholder: string;
  className: string;
  type: "text" | "password" | "search";
  disabled?: boolean;
  maxLength?: number;
};

export function Input(props: InputProps) {
  const [state, setState] = useAppState({
    passwordVisibility: false,
  });

  const handleVisibility = () => {
    setState((prevState) => ({
      ...prevState,
      passwordVisibility: !prevState.passwordVisibility,
    }));
  };

  return (
    <div className="input-container">
      <input
        type={
          props.type === "password"
            ? state.passwordVisibility
              ? "text"
              : "password"
            : "text"
        }
        className={props.className}
        onChange={props.onChange}
        value={props.value}
        placeholder={props.placeholder}
        disabled={props.disabled}
        maxLength={props.maxLength}
      />
      {props.type === "password" && (
        <div className="postfix-container">
          <span onClick={handleVisibility} className="icon-container">
            <PasswordIcon visible={state.passwordVisibility} />
          </span>
        </div>
      )}

      {props.type === "search" && (
        <div className="postfix-container">
          <span className="icon-container">
            <SearchIcon />
          </span>
        </div>
      )}
    </div>
  );
}
