import { Expose, Type } from "class-transformer"
import { IsDateString, IsDefined, IsNumber, IsString, ValidateNested } from "class-validator"
import { PoetryQuizAnswerEntity } from "./poetry-quiz-answer.entity"
import { PoetryQuizInfoEntity } from "./poetry-quiz-info.entity"

export class PoetryQuizEntity {
    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number;


    @Expose()
    @IsDefined()
    @IsString()
    question!: string;

    @Expose()
    @IsDefined()
    @ValidateNested()
    @Type( () => PoetryQuizAnswerEntity )
    answers!: PoetryQuizAnswerEntity[];

    @Expose()
    @IsDefined()
    @ValidateNested()
    @Type( () => PoetryQuizInfoEntity )
    infos!: PoetryQuizInfoEntity[];


    @Expose()
    @IsDefined()
    @IsDateString()
    created!: Date

    @Expose()
    @IsDefined()
    @IsDateString()
    updated!: Date
}
