import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Navigate } from "react-router-dom";
import { QueryKeys } from "../query-keys";
import { verifyAction } from "../../actions";

type RequireAuthProps = {
  children: JSX.Element;
};

export const AuthHandler = (props: RequireAuthProps) => {
  const queryClient = useQueryClient();
  const verify = useQuery({
    queryKey: [QueryKeys.verifyQuery],
    queryFn: verifyAction,
    staleTime: 1000 * 60 * 5,
    retry: 0,
  });

  if (
    !verify.isLoading &&
    (verify.data === null || verify.data === undefined || verify.error)
  ) {
    if (
      window.location.pathname.includes("/cms") ||
      window.location.pathname === "/first-login"
    ) {
      localStorage.removeItem("token");
      queryClient.removeQueries({
        queryKey: [
          QueryKeys.userQuery,
          QueryKeys.adminQuery,
          QueryKeys.forgotPasswordConfirmQuery,
          QueryKeys.getMeQuery,
          QueryKeys.verifyQuery,
          QueryKeys.profileMutationQuery,
          QueryKeys.loginMutationQuery,
          QueryKeys.logoutMutationQuery,
          QueryKeys.fisrtLoginMutationQuery,
          QueryKeys.parentsQuery,
          QueryKeys.deleteAdminMutatuionQuery,
          QueryKeys.deleteParentMutatuionQuery,
          QueryKeys.childrenQuery,
          QueryKeys.parentQuery,
          QueryKeys.deleteChildMutation,
          QueryKeys.poetryQuery,
          QueryKeys.deletePoetryMutatuionQuery,
          QueryKeys.poetQuery,
          QueryKeys.activatePoetQuery,
          QueryKeys.createPoetQuery,
          QueryKeys.modifyPoetQuery,
          QueryKeys.deletePoetQuery,
          QueryKeys.categoryQuery,
          QueryKeys.modifyCategoryQuery,
          QueryKeys.createCategoryQuery,
          QueryKeys.deleteCategoryQuery,
          QueryKeys.achievementQuery,
          QueryKeys.modifyAchievementQuery,
          QueryKeys.createAchievementQuery,
          QueryKeys.deleteAchievementQuery,
          QueryKeys.tierQuery,
          QueryKeys.modifyTierQuery,
          QueryKeys.createTierQuery,
          QueryKeys.deleteTierQuery,
          QueryKeys.versionQuery,
          QueryKeys.modifyVersionQuery,
          QueryKeys.createVersionQuery,
          QueryKeys.deleteVersionQuery,
          QueryKeys.factsQuery,
          QueryKeys.modifyFactQuery,
          QueryKeys.createFactQuery,
          QueryKeys.deleteFactQuery,
          QueryKeys.poetryChatQuery,
          QueryKeys.modifyPoetryChatQuery,
          QueryKeys.createPoetryChatQuery,
          QueryKeys.deletePoetryChatMutationQuery,
          QueryKeys.poetryQuizQuery,
          QueryKeys.modifyPoetryQuizQuery,
          QueryKeys.createPoetryQuizQuery,
          QueryKeys.deletePoetryQuizMutationQuery,
          QueryKeys.linesOfPoetryQuery,
          QueryKeys.modifyLineOfPoetry,
          QueryKeys.createLineOfPoetry,
          QueryKeys.deleteLineOfPoetry,
          QueryKeys.modifyLineOfPoetryIndex,
          QueryKeys.inlineInfos,
          QueryKeys.createInlineInfo,
          QueryKeys.modifyInlineInfo,
          QueryKeys.deleteInlineInfo,
          QueryKeys.disappearingGames,
          QueryKeys.createDisappearingGame,
          QueryKeys.modifyDisappearingGame,
          QueryKeys.modifyCorrectDisappearingGame,
          QueryKeys.deleteDisappearingGame,
        ],
      });
      return <Navigate to={"/"} />;
    }
  }

  return <main>{props.children}</main>;
};
