import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  DeleteIcon,
  EditIcon,
  ErrorModal,
  Modal,
  ValidationErrorModal,
} from "../../../components";
import { PoetryQuizInfoEntity, PoetryQuizEntity } from "../../../entity";
import { QueryKeys, useAppState, useAppTranslation } from "../../../common";
import {
  createPoetryQuizInfoAction,
  deletePoetryQuizInfoAction,
  modifyPoetryQuizInfoAction,
} from "../../../actions";
import { InputModal } from "./input-modal";

interface PoetryQuizInfosModalProps {
  poetryQuiz: PoetryQuizEntity | null;
  close: () => void;
}

export const PoetryQuizInfosModal = (props: PoetryQuizInfosModalProps) => {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();
  const [state, setState] = useAppState({
    showInput: false,
    selectedInfo: null as PoetryQuizInfoEntity | null,
    validationErrorMessage: null as string | null,
  });

  const mutateQuizInfo = useMutation({
    mutationKey: [
      state.selectedInfo === null
        ? QueryKeys.createQuizInfoQuery
        : QueryKeys.modifyQuizInfoQuery,
    ],
    mutationFn: (data: any) =>
      state.selectedInfo === null
        ? createPoetryQuizInfoAction(data)
        : modifyPoetryQuizInfoAction(data),
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.poetryQuizQuery],
      });
      setState({ ...state, selectedInfo: null, showInput: false });
    },
  });

  const handleCloseErrorModal = () => {
    if (mutateQuizInfo.isError) {
      mutateQuizInfo.reset();
    } else {
      deleteFact.reset();
    }
  };

  const checkForm = (info: string) => {
    if (info.trim().length === 0) {
      setState({
        ...state,
        validationErrorMessage: `Az infó kitöltése kötelező!`,
      });
      return false;
    }

    return true;
  };

  const handleMutateFact = (info: string) => {
    if (!checkForm(info)) {
      return;
    }
    mutateQuizInfo.mutate({
      id: state.selectedInfo?.id,
      info: info,
      quizId: props.poetryQuiz!.id,
    });
  };

  const deleteFact = useMutation({
    mutationKey: [QueryKeys.deleteQuizInfoQuery],
    mutationFn: deletePoetryQuizInfoAction,
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.poetryQuizQuery],
      });
    },
  });

  const handleDeleteFact = (id: number) => {
    deleteFact.mutate({ id });
  };

  return (
    <Modal
      onClose={props.close}
      title={t(`poetry-quiz-page-poetry-infos`)}
      footer={true}
      cancelText={t(`poetry-quiz-infos-modal-cancel-button-title`)}
    >
      <div className="poetry-quiz-infos-modal">
        <Button
          onClick={() =>
            setState({ ...state, selectedInfo: null, showInput: true })
          }
          title="+"
          htmlType="button"
          creationButton
        />
        <div className="infos-container">
          {props.poetryQuiz!.infos.map((info) => (
            <div className="info" key={info.id}>
              <div className="info__text">{info.info}</div>

              <div className="info__edit">
                <EditIcon
                  onClick={() =>
                    setState({ ...state, selectedInfo: info, showInput: true })
                  }
                />
              </div>

              <div className="info__delete">
                <DeleteIcon onClick={() => handleDeleteFact(info.id)} />
              </div>
            </div>
          ))}

          {props.poetryQuiz!.infos.length === 0 ? (
            <div style={{ padding: 15 }}>
              {t(`poetry-quiz-info-page-no-info`)}
            </div>
          ) : null}
        </div>

        {state.showInput ? (
          <InputModal
            title={
              state.selectedInfo
                ? t(`poetry-quiz-info-page-modify-info`)
                : t(`poetry-quiz-info-page-create-info`)
            }
            cancelText={t(`poetry-quiz-info-page-info-modal-cancel`)}
            submitText={t(`poetry-quiz-info-page-info-modal-save`)}
            placeholder={t(`poetry-quiz-info-page-info`)}
            save={handleMutateFact}
            close={() =>
              setState({ ...state, showInput: false, selectedInfo: null })
            }
            text={state.selectedInfo ? state.selectedInfo.info : undefined}
          />
        ) : null}

        <section>
          {(mutateQuizInfo.isError || deleteFact.isError) && (
            <ErrorModal
              error={mutateQuizInfo.error || deleteFact.error}
              onClose={handleCloseErrorModal}
              title={
                deleteFact.isError
                  ? t("poetry-quiz-infos-delete-info-form-error")
                  : state.selectedInfo !== null
                  ? t(
                      "poetry-quiz-infos-mutate-info-form-error-modal-modify-title"
                    )
                  : t(
                      "poetry-quiz-infos-page-mutate-info-form-error-modal-create-title"
                    )
              }
            />
          )}
        </section>

        {state.validationErrorMessage && (
          <ValidationErrorModal
            error={state.validationErrorMessage}
            onClose={() =>
              setState({
                ...state,
                validationErrorMessage: null,
              })
            }
          />
        )}
      </div>
    </Modal>
  );
};
