import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  ErrorModal,
  Form,
  Input,
  Modal,
  ValidationErrorModal,
} from "../../../components";
import { PoetryQuizEntity, QuizEntity } from "../../../entity";
import {
  PageType,
  QueryKeys,
  mutationHandler,
  useAppState,
  useAppTranslation,
} from "../../../common";
import {
  createPoetryQuizAction,
  modifyPoetryQuizAction,
} from "../../../actions/poetry-quiz";

interface PoetryQuizModalProps {
  poetryId: number;
  poetryQuiz: PoetryQuizEntity | null;
  handleMutationModalVisible: () => void;
  latestQueryKey: any;
}

export const PoetryQuizModal = (props: PoetryQuizModalProps) => {
  const queryClient = useQueryClient();
  const { t } = useAppTranslation();
  const [state, setState] = useAppState({
    question: props.poetryQuiz ? props.poetryQuiz.question : ``,
    validationErrorMessage: null as string | null,
  });

  const checkForm = () => {
    if (state.question.trim().length === 0) {
      setState({
        ...state,
        validationErrorMessage: `A kérdés kitöltése kötelező!`,
      });
      return false;
    }

    return true;
  };

  const mutateQuiz = useMutation({
    mutationKey: [
      props.poetryQuiz?.id === null
        ? QueryKeys.modifyPoetryQuizQuery
        : QueryKeys.createPoetryQuizQuery,
    ],
    mutationFn: (data: any) =>
      props.poetryQuiz?.id
        ? modifyPoetryQuizAction(data)
        : createPoetryQuizAction(data),
    onMutate: async (newQuiz) => {
      await queryClient.cancelQueries({ queryKey: props.latestQueryKey });
      const previousQuiz = queryClient.getQueryData([props.latestQueryKey]);

      queryClient.setQueryData(
        props.latestQueryKey,
        (old: PageType<QuizEntity>) => {
          const handler = mutationHandler({
            dataLength: old.data.length,
            pageSize: old.pageSize,
            count: old.count,
            oldData: old.data,
            newData: newQuiz,
          });

          return {
            page: old.page,
            pageSize: old.pageSize,
            count: handler.newCount,
            data: handler.newData,
          };
        }
      );
      return { previousQuiz };
    },
    onSuccess() {
      props.handleMutationModalVisible();
    },
    onError(error, variables, context) {
      queryClient.setQueryData([props.latestQueryKey], context?.previousQuiz);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.poetryQuizQuery],
      });
    },
  });

  const handleCloseErrorModal = () => {
    mutateQuiz.reset();
  };

  const handleMutateQuiz = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!checkForm()) {
      return;
    }
    mutateQuiz.mutate({
      id: props.poetryQuiz ? props.poetryQuiz.id : null,
      question: state.question,
      poetryId: props.poetryId,
    });
  };

  return (
    <Modal
      onClose={() => props.handleMutationModalVisible()}
      title={`${t(`poetry-quiz-page-poetry-quiz`)} ${
        props.poetryQuiz
          ? t(`poetry-quiz-page-edit`)
          : t(`poetry-quiz-page-create`)
      }`}
      footer={true}
      submitText={
        props.poetryQuiz
          ? t(`poetry-quiz-modal-edit-button-title`)
          : t(`poetry-quiz-modal-create-button-title`)
      }
      cancelText={t(`poetry-quiz-modal-cancel-button-title`)}
      handleSubmit={handleMutateQuiz}
      loading={mutateQuiz.isPending}
    >
      <div className="poetry-quiz-modal">
        <Form onSubmit={handleMutateQuiz}>
          <Input
            onChange={(e) =>
              setState({
                ...state,
                question: e.target.value,
              })
            }
            value={state.question}
            placeholder={t(`poetry-quiz-question`)}
            className="poetry-quiz-modal-input"
            type="text"
          />
        </Form>
        <section>
          {mutateQuiz.isError && (
            <ErrorModal
              error={mutateQuiz.error}
              onClose={handleCloseErrorModal}
              title={
                props.poetryQuiz?.id
                  ? t(
                      "poetry-quiz-page-mutate-quiz-form-error-modal-modify-title"
                    )
                  : t(
                      "poetry-quiz-page-mutate-quiz-form-error-modal-create-title"
                    )
              }
            />
          )}
        </section>
        <section>
          {state.validationErrorMessage && (
            <ValidationErrorModal
              error={state.validationErrorMessage}
              onClose={() =>
                setState({
                  ...state,
                  validationErrorMessage: null,
                })
              }
            />
          )}
        </section>
      </div>
    </Modal>
  );
};
