type RefreshIconProps = {
  handleRefresh: () => void;
};

export function RefreshIcon(props: RefreshIconProps) {
  return (
    <img
      className="clickable-icon"
      src="/pictures/refresh-icon.png"
      alt="refresh-icon"
      onClick={props.handleRefresh}
    />
  );
}
