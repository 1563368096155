import { Expose, Type } from "class-transformer";
import { IsArray, IsNumber, ValidateNested } from "class-validator";
import { InlineInfoEntity } from "../../entity";

export class GetAllInlineInfoDTO {
  @Expose()
  @ValidateNested()
  @IsArray()
  @Type(() => InlineInfoEntity)
  data!: InlineInfoEntity[];

  @Expose()
  @IsNumber()
  count!: number;

  @Expose()
  @IsNumber()
  page!: number;

  @Expose()
  @IsNumber()
  pageSize!: number;
}
