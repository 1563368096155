import { useAppTranslation } from "../../common";
import "./not-found.page.scss";

export function NotFoundPage() {
  const { t } = useAppTranslation();
  return (
    <div className="error-container">
      <div className="error-title">404</div>
      <div className="error-subtitle">{t("page-not-found-title")}</div>
      <div>{t("page-not-found-subtitle")}</div>
    </div>
  );
}
