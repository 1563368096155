import { Expose, Type } from "class-transformer";
import { ValidateNested } from "class-validator";
import { AdminEntity } from "../../entity";

export class CreateAdminDTO {
  @Expose()
  @ValidateNested()
  @Type(() => AdminEntity)
  admin!: AdminEntity;
}
