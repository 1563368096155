import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  PageType,
  QueryKeys,
  mutationHandler,
  useAppLocation,
  useAppState,
  useAppTranslation,
} from "../../../common";
import {
  ErrorModal,
  Form,
  Input,
  Modal,
  Upload,
  ValidationErrorModal,
} from "../../../components";
import { createPoetAction, modifyPoetAction } from "../../../actions";
import { PoetEntity } from "../../../entity";

type PoetMutationFormProps = {
  modify: boolean;
  handleMutationModalVisible: (value: boolean) => void;
  latestQueryKey: any;
};

export function PoetMutationForm(props: PoetMutationFormProps) {
  const queryClient = useQueryClient();
  const location = useAppLocation();
  const { t } = useAppTranslation();

  const [state, setState] = useAppState({
    name: location.state?.poet.name ?? "",
    youngAvatar: null as unknown as File,
    oldAvatar: null as unknown as File,
    happyAnimation: null as unknown as File,
    idleAnimation: null as unknown as File,
    validationErrorMessage: null as string | null,
  });

  const mutatePoet = useMutation({
    mutationKey: [
      props.modify ? QueryKeys.modifyPoetQuery : QueryKeys.createPoetQuery,
    ],
    mutationFn: (data: any) =>
      props.modify ? modifyPoetAction(data) : createPoetAction(data),
    onMutate: async (newPoet) => {
      await queryClient.cancelQueries({ queryKey: props.latestQueryKey });
      const previousPoets = queryClient.getQueryData([props.latestQueryKey]);

      queryClient.setQueryData(
        props.latestQueryKey,
        (old: PageType<PoetEntity>) => {
          const handler = mutationHandler({
            dataLength: old.data.length,
            pageSize: old.pageSize,
            count: old.count,
            oldData: old.data,
            newData: newPoet,
          });

          return {
            page: old.page,
            pageSize: old.pageSize,
            count: handler.newCount,
            data: handler.newData,
          };
        }
      );

      return { previousPoets };
    },
    onSuccess() {
      props.handleMutationModalVisible(false);
    },
    onError(error, variables, context) {
      queryClient.setQueryData([props.latestQueryKey], context?.previousPoets);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.poetQuery],
      });
    },
  });

  const handleCloseErrorModal = () => {
    mutatePoet.reset();
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, name: e.target.value }));
  };

  const handleYoungAvatarChange = (value: File) => {
    setState((prevState) => ({ ...prevState, youngAvatar: value }));
  };

  const handleOldAvatarChange = (value: File) => {
    setState((prevState) => ({ ...prevState, oldAvatar: value }));
  };

  const handleHappyAnimationChange = (value: File) => {
    setState((prevState) => ({ ...prevState, happyAnimation: value }));
  };

  const handleIdleAnimationChange = (value: File) => {
    setState((prevState) => ({ ...prevState, idleAnimation: value }));
  };

  const checkForm = () => {
    if (state.name.trim().length === 0) {
      setState({
        ...state,
        validationErrorMessage: `A név kitöltése kötelező!`,
      });
      return false;
    }
    if (!props.modify && state.youngAvatar === null) {
      setState({
        ...state,
        validationErrorMessage: `A fiatal avatar kiválasztása kötelező!`,
      });
      return false;
    }
    if (!props.modify && state.oldAvatar === null) {
      setState({
        ...state,
        validationErrorMessage: `Az idős avatar kiválasztása kötelező!`,
      });
      return false;
    }
    if (!props.modify && state.happyAnimation === null) {
      setState({
        ...state,
        validationErrorMessage: `A boldog avatar kiválasztása kötelező!`,
      });
      return false;
    }
    if (!props.modify && state.idleAnimation === null) {
      setState({
        ...state,
        validationErrorMessage: `A tétlen avatar kiválasztása kötelező!`,
      });
      return false;
    }
    return true;
  };

  const handleMutatePoet = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!checkForm()) {
      return;
    }
    mutatePoet.mutate({
      youngAvatar: state.youngAvatar,
      oldAvatar: state.oldAvatar,
      idleAnimation: state.idleAnimation,
      happyAnimation: state.happyAnimation,
      name: state.name,
      id: props.modify ? location.state?.poet.id : undefined!,
    });
  };

  return (
    <div>
      <section>
        <Modal
          onClose={() => props.handleMutationModalVisible(false)}
          title={
            props.modify
              ? t("poet-page-mutate-poet-modal-modify-title")
              : t("poet-page-mutate-poet-modal-create-title")
          }
          footer
          cancelText={t("poet-page-mutate-poet-modal-cancel-label")}
          submitText={
            props.modify
              ? t("poet-page-mutate-poet-modal-modify-submit-label")
              : t("poet-page-mutate-poet-modal-create-submit-label")
          }
          handleSubmit={handleMutatePoet}
          loading={mutatePoet.isPending}
        >
          <Form onSubmit={handleMutatePoet}>
            <Input
              type="text"
              maxLength={255}
              className="mutate-modal-input"
              onChange={handleNameChange}
              value={state.name}
              placeholder={t("poet-page-mutate-poet-modal-name-placeholder")}
            />
            <div className="picture-upload">
              <div className="picture-row">
                <Upload
                  type="picture"
                  label={t(
                    "poet-page-mutate-poet-modal-young-avatar-picture-title"
                  )}
                  handleChangeUpload={handleYoungAvatarChange}
                  file={location.state?.poet.youngAvatar ?? ""}
                />
                <Upload
                  label={t(
                    "poet-page-mutate-poet-modal-old-avatar-picture-title"
                  )}
                  type="picture"
                  handleChangeUpload={handleOldAvatarChange}
                  file={location.state?.poet.oldAvatar ?? ""}
                />
              </div>
              <div className="picture-row">
                <Upload
                  type="gif"
                  label={t(
                    "poet-page-mutate-poet-modal-happy-avatar-picture-title"
                  )}
                  handleChangeUpload={handleHappyAnimationChange}
                  file={location.state?.poet.happyAnimation ?? ""}
                />
                <Upload
                  type="gif"
                  label={t(
                    "poet-page-mutate-poet-modal-idle-avatar-picture-title"
                  )}
                  handleChangeUpload={handleIdleAnimationChange}
                  file={location.state?.poet.idleAnimation ?? ""}
                />
              </div>
            </div>
          </Form>
        </Modal>
      </section>
      <section>
        {mutatePoet.isError && (
          <ErrorModal
            error={mutatePoet.error}
            onClose={handleCloseErrorModal}
            title={
              props.modify
                ? t("poet-page-mutate-poet-form-error-modal-modify-title")
                : t("poet-page-mutate-poet-form-error-modal-create-title")
            }
          />
        )}
      </section>
      <section>
        {state.validationErrorMessage && (
          <ValidationErrorModal
            error={state.validationErrorMessage}
            onClose={() =>
              setState({
                ...state,
                validationErrorMessage: null,
              })
            }
          />
        )}
      </section>
    </div>
  );
}
