import { Expose } from "class-transformer";
import {IsDateString, IsDefined, IsNumber, IsString, ValidateIf} from "class-validator";
import { PoetryChatTypeEnum } from "../common";

export class PoetryChatEntity {
  @Expose()
  @IsDefined()
  @IsNumber()
  id!: number;

  @Expose()
  @IsDefined()
  @IsString()
  text!: string;

  @Expose()
  @IsDefined()
  @IsString()
  @ValidateIf((object, value) => value !== null)
  audioUrl!: string | null;

  @Expose()
  @IsDefined()
  @IsNumber()
  index!: number[];

  @Expose()
  @IsDefined()
  @IsString()
  type!: PoetryChatTypeEnum;

  @Expose()
  @IsDefined()
  @IsDateString()
  created!: Date;

  @Expose()
  @IsDefined()
  @IsDateString()
  updated!: Date;
}
