import { Expose } from "class-transformer";
import { IsDateString, IsDefined, IsNumber, IsString } from "class-validator";

export class PoetryQuizInfoEntity {
  @Expose()
  @IsDefined()
  @IsNumber()
  id!: number;

  @Expose()
  @IsDefined()
  @IsString()
  info!: string;

  @Expose()
  @IsDefined()
  @IsDateString()
  created!: Date;

  @Expose()
  @IsDefined()
  @IsDateString()
  updated!: Date;
}
