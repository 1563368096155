import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  QueryKeys,
  useAppNavigate,
  useAppState,
  useAppTranslation,
  useDebounce,
} from "../../common";
import { Button, ErrorModal, Input, Table } from "../../components";
import { getAllPoetAction, poetActivetionAction } from "../../actions";
import { PoetColumns, PoetDeleteModal, PoetMutationForm } from "./components";
import "./poet.page.scss";

export function PoetPage() {
  const queryClient = useQueryClient();
  const navigate = useAppNavigate();
  const { t } = useAppTranslation();
  const [state, setState] = useAppState({
    page: 1,
    pageSize: 10,
    name: "",
    nameOrder: "" as "ASC" | "DESC" | "",
    mutateModalVisible: false,
    deleteModalVisible: false,
    modify: false,
    searchTerm: "",
  });

  const getAllPoet = useQuery({
    queryKey: [
      QueryKeys.poetQuery,
      {
        page: state.page,
        pageSize: state.pageSize,
        name: state.searchTerm,
        nameOrder: state.nameOrder,
      },
    ],
    queryFn: () =>
      getAllPoetAction({
        page: state.page,
        pageSize: state.pageSize,
        name: state.searchTerm,
        nameOrder: state.nameOrder,
      }),
  });

  const handleSearch = useDebounce((searchTerm: string) => {
    setState((prevState) => ({ ...prevState, searchTerm: searchTerm }));
  }, 250);

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, name: e.target.value }));

    handleSearch(e.target.value);
  };

  const handleMutateModalVisible = (modify: boolean) => {
    navigate("", { state: undefined });
    setState((prevState) => ({
      ...prevState,
      mutateModalVisible: !prevState.mutateModalVisible,
      modify,
    }));
  };

  const handleDeleteModalVisible = () => {
    setState((prevState) => ({
      ...prevState,
      deleteModalVisible: !prevState.deleteModalVisible,
    }));
  };

  const handleSetPage = (value: number) => {
    setState((prevState) => ({ ...prevState, page: value }));
  };

  const handleSetPageSize = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setState((prevState) => ({
      ...prevState,
      pageSize: parseInt(e.target.value),
    }));
  };

  const handleNameOrder = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      nameOrder: value as "ASC" | "DESC" | "",
    }));
  };

  const activatePoet = useMutation({
    mutationKey: [QueryKeys.activatePoetQuery],
    mutationFn: poetActivetionAction,
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.poetQuery] });
    },
  });

  const handleActivatePoet = (id: number) => {
    activatePoet.mutate({ id });
  };

  const handleCloseErrorModal = () => {
    if (getAllPoet.isError) {
      getAllPoet.refetch();
    } else {
      activatePoet.reset();
    }
  };

  return (
    <main className="page">
      <div className="poet-page">
        <section className="title">{t("poet-page-title")}</section>
        <section className="search-bar-container">
          <Input
            onChange={handleSearchTermChange}
            value={state.name}
            placeholder={t("poet-page-search-bar-placeholder")}
            type="search"
            className="searchbar"
          />
          <Button
            onClick={() => handleMutateModalVisible(false)}
            title="+"
            htmlType="button"
            creationButton
          />
        </section>
        <section>
          <Table
            columns={Object.values(
              PoetColumns({
                nameOrder: state.nameOrder,
                handleNameOrder,
                handleDeleteModalVisible,
                handleMutateModalVisible,
                handleActivatePoet,
              })
            )}
            paginatorNeeded
            data={getAllPoet.data?.data}
            loading={getAllPoet.isLoading}
            page={getAllPoet.data?.page}
            pageSize={getAllPoet.data?.pageSize}
            allPage={getAllPoet.data?.count}
            handleSetPage={handleSetPage}
            handleSetPageSize={handleSetPageSize}
          />
        </section>
      </div>
      <section>
        {state.mutateModalVisible && (
          <PoetMutationForm
            modify={state.modify}
            handleMutationModalVisible={handleMutateModalVisible}
            latestQueryKey={[
              QueryKeys.poetQuery,
              {
                page: state.page,
                pageSize: state.pageSize,
                name: state.searchTerm,
                nameOrder: state.nameOrder,
              },
            ]}
          />
        )}
      </section>
      <section>
        {state.deleteModalVisible && (
          <PoetDeleteModal
            handleDeleteModalVisible={handleDeleteModalVisible}
            handleSetPage={handleSetPage}
          />
        )}
      </section>
      <section>
        {(getAllPoet.isError || activatePoet.isError) && (
          <ErrorModal
            onClose={handleCloseErrorModal}
            title={
              getAllPoet.isError
                ? t("poet-page-error-modal-get-all-title")
                : t("poet-page-error-modal-mutation-title")
            }
            error={getAllPoet.error || activatePoet.error}
          />
        )}
      </section>
    </main>
  );
}
