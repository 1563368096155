import { Expose } from "class-transformer";
import { IsBoolean, IsDefined, IsString } from "class-validator";
import { RoleEnum } from "../common";

export class GetMeEntity {
  @Expose()
  @IsDefined()
  @IsString()
  email!: string;

  @Expose()
  @IsDefined()
  @IsString()
  role!: RoleEnum;

  @Expose()
  @IsDefined()
  @IsBoolean()
  firstLogin!: boolean;
}
