type DeleteIconProps = {
  onClick: () => void;
};

export function DeleteIcon(props: DeleteIconProps) {
  return (
    <img
      className="clickable-icon"
      src="/pictures/delete-icon.png"
      alt="delete-icon"
      onClick={props.onClick}
    />
  );
}
