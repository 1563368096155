import { removeHTMLTags, useAppNavigate } from "../../../common";
import { DeleteIcon, EditIcon, SortIcon } from "../../../components";
import { PoetryChatEntity } from "../../../entity";

type PoetryChatColumnProps = {
  handleIndexOrder: (value: string) => void;
  indexOrder: "ASC" | "DESC" | "";
  handleDeleteModalVisible: () => void;
  showModifyModal: (poetryChat: PoetryChatEntity) => void;
};

export function PoetryChatColumns(props: PoetryChatColumnProps) {
  const navigate = useAppNavigate();

  const handleOpenDeleteModal = (id: number) => {
    props.handleDeleteModalVisible();
    navigate("", { state: { id } });
  };

  return [
    {
      title: "poetry-chat-page-table-header-text",
      dataIndex: "text",
      render: (record: PoetryChatEntity) => {
        return <span>{removeHTMLTags(record?.text)}</span>;
      },
    },
    {
      title: "poetry-chat-page-table-header-index",
      dataIndex: "index",
      suffix: (
        <SortIcon
          order={props.indexOrder}
          orderFunction={props.handleIndexOrder}
        />
      ),
    },
    {
      title: "poetry-chat-page-table-header-updated",
      dataIndex: "updated",
      render: (record: PoetryChatEntity) => {
        return <span>{new Date(record?.updated).toLocaleDateString()}</span>;
      },
    },
    {
      title: "poetry-chat-page-table-header-created",
      dataIndex: "created",
      render: (record: PoetryChatEntity) => {
        return <span>{new Date(record?.created).toLocaleDateString()}</span>;
      },
    },
    {
      title: "poetry-chat-page-table-header-edit",
      dataIndex: "children",
      render: (record: PoetryChatEntity) => {
        return <EditIcon onClick={() => props.showModifyModal(record)} />;
      },
    },
    {
      title: "poetry-chat-page-table-header-delete",
      dataIndex: "delete",
      render: (record: PoetryChatEntity) => {
        return <DeleteIcon onClick={() => handleOpenDeleteModal(record?.id)} />;
      },
    },
  ];
}
