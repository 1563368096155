export enum ClassEnum {
    FIRST = '1',
    SECOND = '2',
    THIRD = '3',
    FOURTH = '4',
    FIFTH = '5',
    SIXTH = '6',
    SEVENTH = '7',
    EIGHTH = '8',
    NINETH = '9',
    TENTH = '10',
    ELEVENTH = '11',
    TWELVETH = '12',
}