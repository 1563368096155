import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  ErrorModal,
  Form,
  Modal,
  RichTextEditor, Upload,
  ValidationErrorModal,
} from "../../../components";
import { Selector } from "../../../components/selector/selector";
import { PoetryChatEntity } from "../../../entity";
import {
  PageType,
  QueryKeys,
  mutationHandler,
  useAppState,
  useAppTranslation, useAppLocation,
} from "../../../common";
import {
  createPoetryChatAction,
  modifyPoetryChatAction,
} from "../../../actions/poetry-chat";

interface PoetryChatModalProps {
  poetryId: number;
  poetryChat: PoetryChatEntity | null;
  handleMutationModalVisible: () => void;
  latestQueryKey: any;
}

export const PoetryChatModal = (props: PoetryChatModalProps) => {
  const queryClient = useQueryClient();
  const { t } = useAppTranslation();
  const location = useAppLocation();
  const [state, setState] = useAppState({
    categoriesModalVisible: false,
    text: props.poetryChat ? props.poetryChat.text : ``,
    type: props.poetryChat ? props.poetryChat.type : `0`,
    audio: null as unknown as File,
    validationErrorMessage: null as string | null,
  });

  const mutateChat = useMutation({
    mutationKey: [QueryKeys.createPoetryChatQuery],
    mutationFn: (data: any) =>
      props.poetryChat
        ? modifyPoetryChatAction(data)
        : createPoetryChatAction(data),
    onMutate: async (newPreChat) => {
      await queryClient.cancelQueries({ queryKey: props.latestQueryKey });
      const previousChats = queryClient.getQueryData([props.latestQueryKey]);

      queryClient.setQueryData(
        props.latestQueryKey,
        (old: PageType<PoetryChatEntity>) => {
          const handler = mutationHandler({
            dataLength: old.data.length,
            pageSize: old.pageSize,
            count: old.count,
            oldData: old.data,
            newData: newPreChat,
          });

          return {
            page: old.page,
            pageSize: old.pageSize,
            count: handler.newCount,
            data: handler.newData,
          };
        }
      );

      return { previousChats };
    },
    onSuccess() {
      props.handleMutationModalVisible();
    },
    onError(error, variables, context) {
      queryClient.setQueryData([props.latestQueryKey], context?.previousChats);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.poetryChatQuery],
      });
    },
  });

  const handleAudioChange = (value: File) => {
    setState((prevState) => ({ ...prevState, audio: value }));
  };

  const handleCloseErrorModal = () => {
    mutateChat.reset();
  };

  const checkForm = () => {
    if (state.text.trim().length === 0) {
      setState({
        ...state,
        validationErrorMessage: `A leírás kitöltése kötelező!`,
      });
      return false;
    }

    return true;
  };

  const handleMutateChat = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!checkForm()) {
      return;
    }
    mutateChat.mutate({
      id: props.poetryChat ? props.poetryChat.id : null,
      text: state.text,
      type: state.type,
      audio: state.type === "1" ? null : state.audio,
      poetryId: props.poetryId,
    });
  };

  return (
    <div>
      <section>
        <Modal
          onClose={props.handleMutationModalVisible}
          title={`${t(`poetry-chat-page-poetry-chat`)} ${
            props.poetryChat
              ? t(`poetry-chat-page-edit`)
              : t(`poetry-chat-page-create`)
          }`}
          footer={true}
          submitText={
            props.poetryChat
              ? t(`poetry-chat-modal-edit-button-title`)
              : t(`poetry-chat-modal-create-button-title`)
          }
          cancelText={t(`poetry-chat-modal-cancel-button-title`)}
          handleSubmit={handleMutateChat}
          loading={mutateChat.isPending}
        >
          <div className="poetry-chat-modal">
            <Form onSubmit={handleMutateChat}>
              <RichTextEditor
                onChange={(e) =>
                  setState({
                    ...state,
                    text: e,
                  })
                }
                value={state.text}
                placeholder={t(
                  "inline-info-page-mutate-info-modal-text-placeholder"
                )}
              />

              <Selector
                name={t(`poetry-chat-type`)}
                id="poetry-chat-type"
                handleSelectOption={(e) =>
                  setState({
                    ...state,
                    type: e.target.value,
                  })
                }
                options={[
                  { value: `0`, label: `Kezdő chat` },
                  { value: `1`, label: `Végző chat` },
                ]}
                onlyLabel={true}
                currentValue={state.type}
              />

              { state.type === "0" ? (

                  <Upload
                      type="audio"
                      label={t(
                          "poetry-chat-modal-audio-title"
                      )}
                      handleChangeUpload={handleAudioChange}
                      file={props.poetryChat?.audioUrl ?? ""}
                      audioId="audioUrl"
                  />

              ) : <></> }

            </Form>
          </div>
        </Modal>
      </section>

      <section>
        {mutateChat.isError && (
          <ErrorModal
            error={mutateChat.error}
            onClose={handleCloseErrorModal}
            title={
              props.poetryChat
                ? t(
                    "poetry-chat-page-mutate-chat-form-error-modal-modify-title"
                  )
                : t(
                    "poetry-chat-page-mutate-chat-form-error-modal-create-title"
                  )
            }
          />
        )}
      </section>
      <section>
        {state.validationErrorMessage && (
          <ValidationErrorModal
            error={state.validationErrorMessage}
            onClose={() =>
              setState({
                ...state,
                validationErrorMessage: null,
              })
            }
          />
        )}
      </section>
    </div>
  );
};
