import { useEffect } from "react";
import { CloseIcon } from "../icon";
import { Button } from "../button";
import "./modal.scss";

type ModalProps = {
  onClose: () => void;
  title: string;
  children: JSX.Element;
  footer: boolean;
  handleSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
  submitText?: string;
  cancelText?: string;
  loading?: boolean;
};

export function Modal(props: ModalProps) {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div className="modal-container">
      <section className="relative">
        <div className="modal">
          <div className="modal-header">
            {props.title} <CloseIcon onClick={props.onClose} />
          </div>
          <div className="modal-content">{props.children}</div>
          {props.footer && (
            <div className="modal-footer">
              <Button
                title={props.cancelText ?? "Ok"}
                onClick={props.onClose}
                htmlType="button"
              />
              {props.submitText && (
                <Button
                  title={props.submitText}
                  onClick={props.handleSubmit}
                  htmlType="submit"
                  loading={props.loading}
                  disabled={props.loading}
                />
              )}
            </div>
          )}
        </div>
      </section>
    </div>
  );
}
