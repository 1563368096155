import { useAppNavigate } from "../../../common";
import { DeleteIcon, ModifyIcon } from "../../../components";
import { FactEntity } from "../../../entity";

type FactsColumnProps = {
  handleDeleteModalVisible: () => void;
  handleMutateModalVisible: (value: boolean) => void;
  handleOpenErrorModal: () => void;
};

export function FactsColumns(props: FactsColumnProps) {
  const navigate = useAppNavigate();

  const handleOpenDeleteModal = (id: number) => {
    props.handleDeleteModalVisible();
    navigate("", { state: { id } });
  };

  const handleOpenModifyModal = (fact: FactEntity) => {
    props.handleMutateModalVisible(true);
    navigate("", { state: { fact } });
  };

  return [
    {
      title: "fact-page-table-header-title",
      dataIndex: "title",
    },
    {
      title: "fact-page-table-header-description",
      dataIndex: "description",
    },
    {
      title: "fact-page-table-header-created",
      dataIndex: "created",
      render: (record: FactEntity) => {
        return <span>{new Date(record?.created!).toLocaleDateString()}</span>;
      },
    },
    {
      title: "fact-page-table-header-updated",
      dataIndex: "updated",
      render: (record: FactEntity) => {
        return <span>{new Date(record?.updated!).toLocaleDateString()}</span>;
      },
    },
    {
      title: "fact-page-table-header-modify",
      dataIndex: "modify",
      render: (record: FactEntity) => {
        return <ModifyIcon onClick={() => handleOpenModifyModal(record)} />;
      },
    },
    {
      title: "fact-page-table-header-delete",
      dataIndex: "delete",
      render: (record: FactEntity) => {
        return <DeleteIcon onClick={() => handleOpenDeleteModal(record?.id)} />;
      },
    },
  ];
}
