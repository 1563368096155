type EmailValidatorProps = {
  email: string;
};

export function emailValidator(props: EmailValidatorProps) {
  if (props.email.length > 0) {
    // eslint-disable-next-line
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return emailRegex.test(props.email);
  }
}
