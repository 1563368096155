import axios from "axios";

export async function logoutAction() {
  const token = localStorage.getItem("token");

  return await axios
    .post(`${process.env.REACT_APP_API_ORIGIN}/1.0.0/auth/logout`, undefined, {
      headers: {
        "Content-Type": "application/json",
        "app-platform": "cms",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data);
}
