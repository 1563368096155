import axios from "axios";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { SuccessDTO } from "../../dto";

let body: SuccessDTO;

export async function verifyAction() {
  const token = localStorage.getItem("token");

  return await axios
    .get(`${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/admins/verify`, {
      headers: {
        "Content-Type": "application/json",
        "app-platform": "cms",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async (res) => {
      body = plainToClass(SuccessDTO, res.data);
      await validate(body);
      const errors = await validate(body);
      if (errors.length > 0) {
        throw errors;
      }

      return res.data;
    });
}
