import { Modal } from "../../../components";
import { CategoryEntity } from "../../../entity";
import { useAppTranslation } from "../../../common";

interface CategoriesModalProps {
  selectedCategories: number[];
  categories: CategoryEntity[];
  onChange: (categories: number[]) => void;
  onClose: () => void;
}

export const CategoriesModal = (props: CategoriesModalProps) => {
  const { t } = useAppTranslation();
  const toggleCategory = (id: number) => {
    let categories = props.selectedCategories;
    if (categories.includes(id)) {
      categories = categories.filter((c) => c !== id);
    } else {
      categories.push(id);
    }
    props.onChange([...categories]);
  };

  return (
    <Modal
      onClose={props.onClose}
      footer={true}
      title={t(`poetry-page-poetry-categories`)}
    >
      <div className="categories-list">
        {props.categories.map((category) => (
          <div
            onClick={() => toggleCategory(category.id)}
            key={category.id}
            className={`category ${
              props.selectedCategories.includes(category.id) ? `selected` : ``
            }`}
          >
            {category.title}
          </div>
        ))}
      </div>
    </Modal>
  );
};
