import { Expose } from "class-transformer";
import {
  IsBoolean,
  IsDateString,
  IsDefined,
  IsNumber,
  IsOptional,
  IsString,
} from "class-validator";

export class MyPoetryEntity {
  @Expose()
  @IsDefined()
  @IsNumber()
  id!: number;

  @Expose()
  @IsOptional()
  @IsNumber()
  grade?: number;

  @Expose()
  @IsOptional()
  @IsDateString()
  targetDate?: string;

  @Expose()
  @IsOptional()
  @IsNumber()
  paragraphsToLearn?: number;

  @Expose()
  @IsOptional()
  @IsDateString()
  learnEndDate?: string;

  @Expose()
  @IsOptional()
  @IsBoolean()
  learnEndNotification?: boolean;

  @Expose()
  @IsOptional()
  @IsNumber()
  learningTime?: number;

  @Expose()
  @IsOptional()
  @IsString()
  status?: string;

  @Expose()
  @IsOptional()
  @IsBoolean()
  listening?: boolean;

  @Expose()
  @IsOptional()
  @IsBoolean()
  recording?: boolean;

  @Expose()
  @IsOptional()
  @IsBoolean()
  recitation?: boolean;

  @Expose()
  @IsOptional()
  @IsDateString()
  gradeObtained?: string;

  @Expose()
  @IsOptional()
  @IsNumber()
  learningStatus?: number;

  @Expose()
  @IsOptional()
  @IsNumber()
  practiceStatus?: number;

  @Expose()
  @IsOptional()
  @IsNumber()
  disappearingPercentage?: number;

  @Expose()
  @IsOptional()
  @IsNumber()
  puzzlePercentage?: number;

  @Expose()
  @IsOptional()
  @IsNumber()
  associationPercentage?: number;

  @Expose()
  @IsOptional()
  @IsNumber()
  quizPercentage?: number;

  @Expose()
  @IsOptional()
  @IsDateString()
  lastLearned?: string;

  @Expose()
  @IsOptional()
  @IsBoolean()
  preLearning?: boolean;
}
