import { Editor } from "@tinymce/tinymce-react";

type RichTextProps = {
  placeholder: string;
  onChange: (value: string) => void;
  value: string;
};

export function RichTextEditor(props: RichTextProps) {
  return (
    <Editor
      onEditorChange={props.onChange}
      value={props.value}
      apiKey={process.env.REACT_APP_RICH_TEXT_EDITOR_API_KEY}
      init={{
        entity_encoding: "raw",
        language: "hu_HU",
        placeholder: props.placeholder,
        plugins:
          "anchor autolink charmap image link lists media searchreplace table visualblocks wordcount",
        toolbar:
          "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | numlist bullist indent outdent | removeformat",
      }}
    />
  );
}
