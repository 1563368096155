import { ReactNode, useEffect } from "react";
import { useAppState, useAppTranslation } from "../../common";
import { LoadingIcon } from "../icon";
import { Paginator } from "../paginator";
import "./table.scss";

type TableColumn = {
  title: string;
  dataIndex: string;
  width?: string;
  suffix?: JSX.Element;
  render?: (record: any, index: number) => JSX.Element;
};

type TableProps = {
  columns: TableColumn[];
  data: any[];
  loading: boolean;
  page?: number;
  allPage?: number;
  pageSize?: number;
  handleSetPage?: (value: number) => void;
  handleSetPageSize?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  paginatorNeeded?: boolean;
  nonHeight?: boolean;
  dragable?: boolean;
  handleDrop?: (id: number, index: number) => void;
} & (
  | {
      paginatorNeeded: boolean;
      page: number;
      allPage: number;
      pageSize: number;
      handleSetPage: (value: number) => void;
      handleSetPageSize: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    }
  | {
      paginatorNeeded?: never;
      page?: never;
      allPage?: never;
      pageSize?: never;
      handleSetPage?: never;
      handleSetPageSize?: never;
    }
);

export function Table(props: TableProps) {
  const { t } = useAppTranslation();
  const [state, setState] = useAppState({
    draggedRow: null as any,
    loader: false,
  });

  const handleDragStart = (
    event: React.DragEvent<HTMLTableRowElement>,
    row: any
  ) => {
    setState((prevState) => ({ ...prevState, draggedRow: row }));
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.setData("text/html", event.currentTarget as any);
  };

  const handleDragOver = (event: React.DragEvent<HTMLTableRowElement>) => {
    event.preventDefault();
  };

  const handleDrop = (
    event: React.DragEvent<HTMLTableRowElement>,
    row: any
  ) => {
    event.preventDefault();

    if (props.handleDrop) props.handleDrop(state.draggedRow.id, row.index);
  };

  const handleDragEnd = () => {
    setState((prevState) => ({ ...prevState, draggedRow: null }));
  };

  useEffect(() => {
    if (props.loading) {
      setState((prevState) => ({ ...prevState, loader: true }));
      setTimeout(() => {
        setState((prevState) => ({ ...prevState, loader: false }));
      }, 250);
    }
  }, [props.loading, setState]);

  return (
    <div className={`table-container ${props.nonHeight ? "non-height" : ""}`}>
      <section className="table-section">
        <table className="data-table">
          <thead className="table-header">
            <tr>
              {props.columns.map((col, index) => (
                <th
                  key={index}
                  className="header-string"
                  style={{ width: col.width }}
                >
                  <div className="inline-header">
                    <span>{t(col.title)}</span>
                    {col.suffix && (
                      <span className="header-suffix">{col.suffix}</span>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {!state.loader ? (
              props.data?.map((row) => (
                <tr
                  key={row.id}
                  draggable={props.dragable}
                  onDragStart={(e) => handleDragStart(e, row)}
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, row)}
                  onDragEnd={handleDragEnd}
                >
                  {props.columns.map((column, index) => (
                    <td
                      className="data-string"
                      key={`${row.id}-${column.dataIndex}`}
                    >
                      {column.render
                        ? column.render(row, index)
                        : (row[column.dataIndex] as ReactNode)}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td className="loading-table" colSpan={props.columns.length}>
                  <LoadingIcon />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
      {props.paginatorNeeded && (
        <section>
          <div className="paginator-container">
            <Paginator
              page={props.page}
              allPage={props.allPage}
              pagesize={props.pageSize}
              handleSetPage={props.handleSetPage}
              handleSetPageSize={props.handleSetPageSize}
            />
          </div>
        </section>
      )}
    </div>
  );
}
