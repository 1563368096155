import { Expose, Type } from "class-transformer";
import {
  IsBoolean,
  IsDateString,
  IsDefined,
  IsNumber,
  IsString,
  ValidateNested,
} from "class-validator";
import { ClassEnum } from "../common";
import { CategoryEntity } from "./category.entity";
import { FactEntity } from "./fact.entity";
import { LinesOfPoetriesEntity } from "./lines-of-poetries.entity";
import { PoetEntity } from "./poet.entity";
import { PreparationChatEntity } from "./preparation-chat.entity";
import { QuizEntity } from "./quiz.entity";

export class PoetryEntity {
  @Expose()
  @IsDefined()
  @IsNumber()
  id!: number;

  @Expose()
  @IsDefined()
  @IsString()
  analysis!: string;

  @Expose()
  @IsDefined()
  @ValidateNested()
  @Type(() => CategoryEntity)
  categories!: CategoryEntity[];

  @Expose()
  @IsDefined()
  @IsString()
  class!: ClassEnum;

  @Expose()
  @IsDefined()
  @ValidateNested()
  @Type(() => FactEntity)
  facts!: FactEntity[];

  @Expose()
  @IsDefined()
  @IsString()
  info!: string;

  @Expose()
  @IsDefined()
  @IsBoolean()
  isActive!: boolean;

  @Expose()
  @IsDefined()
  @IsBoolean()
  highlighted!: boolean;

  @Expose()
  @IsDefined()
  @ValidateNested()
  @Type(() => LinesOfPoetriesEntity)
  linesOfPoetries!: LinesOfPoetriesEntity[];

  @Expose()
  @IsDefined()
  @ValidateNested()
  @Type(() => PoetEntity)
  poet!: PoetEntity;

  @Expose()
  @IsDefined()
  @ValidateNested()
  @Type(() => PreparationChatEntity)
  preparationChats!: PreparationChatEntity[];

  @Expose()
  @IsDefined()
  @ValidateNested()
  @Type(() => QuizEntity)
  quizzes!: QuizEntity[];

  @Expose()
  @IsDefined()
  @IsBoolean()
  recommended!: boolean;

  @Expose()
  @IsDefined()
  @IsString()
  timeOfWritting!: string;

  @Expose()
  @IsDefined()
  @IsString()
  title!: string;

  @Expose()
  @IsDefined()
  @IsDateString()
  created!: Date;

  @Expose()
  @IsDefined()
  @IsDateString()
  updated!: Date;
}
