import { Expose, Type } from "class-transformer";
import { ValidateNested } from "class-validator";
import { LinesOfPoetriesEntity } from "../../entity";

export class MutateLineOfPoetryDTO {
  @Expose()
  @ValidateNested()
  @Type(() => LinesOfPoetriesEntity)
  lineOfPoetry!: LinesOfPoetriesEntity;
}
