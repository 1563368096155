import { RefreshIcon } from "../refresh";
import "./sort.icon.scss";

type SortIconProps = {
  order: "ASC" | "DESC" | "";
  orderFunction: (order: string) => void;
};

export function SortIcon(props: SortIconProps) {
  const handleOrderChange = () => {
    if (props.order === "") {
      props.orderFunction("ASC");
    }
    if (props.order === "ASC") {
      props.orderFunction("DESC");
    }
    if (props.order === "DESC") {
      props.orderFunction("");
    }
  };

  return props.order === "" || props.order === "ASC" ? (
    <img
      className={`sort-icon ${props.order === "ASC" ? "rotate-180" : ""}`}
      src="/pictures/sort-icon.png"
      alt="sort-icon"
      onClick={handleOrderChange}
    />
  ) : (
    <RefreshIcon handleRefresh={handleOrderChange} />
  );
}
