import axios from "axios";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { GetParentDTO } from "../../dto";

type GetParentProps = {
  id: string;
};

let body: GetParentDTO;

export async function getParentAction(props: GetParentProps) {
  const token = localStorage.getItem("token");

  return await axios
    .get(`${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/parents/get-one`, {
      headers: {
        "Content-Type": "application/json",
        "app-platform": "cms",
        Authorization: `Bearer ${token}`,
      },
      params: props,
    })
    .then(async (res) => {
      body = plainToClass(GetParentDTO, res.data);
      const errors = await validate(body);

      if (errors.length > 0) {
        throw errors;
      }

      return res.data;
    });
}
