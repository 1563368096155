import { Expose } from "class-transformer";
import { IsBoolean, IsDateString, IsDefined, IsNumber, IsOptional, IsString } from "class-validator";

export class PoetEntity {
  @Expose()
  @IsDefined()
  @IsNumber()
  id!: number;

  @Expose()
  @IsDefined()
  @IsString()
  name!: string;

  @Expose()
  @IsDefined()
  @IsString()
  happyAnimation!: string;

  @Expose()
  @IsDefined()
  @IsString()
  idleAnimation!: string;

  @Expose()
  @IsDefined()
  @IsString()
  oldAvatar!: string;

  @Expose()
  @IsDefined()
  @IsString()
  youngAvatar!: string;

  @Expose()
  @IsOptional()
  @IsBoolean()
  isActive?: boolean;

  @Expose()
  @IsOptional()
  @IsDateString()
  updated?: string;

  @Expose()
  @IsOptional()
  @IsDateString()
  created?: string;
}