import { Expose } from "class-transformer";
import { ValidateNested } from "class-validator";

export class LoginDTO {
  @Expose()
  @ValidateNested()
  admin!: {
    email: string;
    firstLogin: boolean;
    role: string;
    token: string;
  };
}
