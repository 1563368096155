import { Expose, Type } from "class-transformer";
import { ValidateNested } from "class-validator";
import { PoetryQuizAnswerEntity } from "../../entity"

export class MutatePoetryQuizAnswerDTO {
  @Expose()
  @ValidateNested()
  @Type(() => PoetryQuizAnswerEntity)
  answer!: PoetryQuizAnswerEntity;
}
