import React from "react";
import "./date-selector.scss";

type InputProps = {
  onChange: (date: Date) => void;
  value: Date;
  placeholder: string;
  className: string;
  disabled?: boolean;
};

export function DateSelector(props: InputProps) {
  return (
    <div className="input-container">
      <input
        className={props.className}
        onChange={(e) => props.onChange(new Date(e.target.value))}
        value={`${props.value?.getFullYear()}-${(props.value?.getMonth() + 1)
          .toString()
          .padStart(2, `0`)}-${props.value
          ?.getDate()
          .toString()
          .padStart(2, `0`)}`}
        placeholder={props.placeholder}
        disabled={props.disabled}
        type="date"
      />
    </div>
  );
}
