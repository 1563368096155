import axios from "axios";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { CreateAdminDTO } from "../../dto";

type CreateAdminProps = {
  email: string;
};

let body: CreateAdminDTO;

export async function createAdminAction(props: CreateAdminProps) {
  const token = localStorage.getItem("token");

  const params = {
    email: props.email,
  };

  return await axios
    .post(
      `${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/admins/create`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          "app-platform": "cms",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(async (res) => {
      body = plainToClass(CreateAdminDTO, res.data);
      await validate(body);
      const errors = await validate(body);
      if (errors.length > 0) {
        throw errors;
      }

      return res.data;
    });
}
