type PasswordValidatorProps = {
  password: string;
};

export function passwordValidator(props: PasswordValidatorProps) {
  if (props.password.length > 0) {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/;
    return passwordRegex.test(props.password);
  }
}
