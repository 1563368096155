import { useAppNavigate } from "../../../common";
import { DeleteIcon, ModifyIcon } from "../../../components";
import { TierEntity } from "../../../entity";

type TierColumnProps = {
  handleDeleteModalVisible: () => void;
  handleMutateModalVisible: (value: boolean) => void;
};

export function TierColumns(props: TierColumnProps) {
  const navigate = useAppNavigate();

  const handleOpenDeleteModal = (id: number) => {
    props.handleDeleteModalVisible();
    navigate("", { state: { id } });
  };

  const handleOpenModifyModal = (tier: TierEntity) => {
    props.handleMutateModalVisible(true);
    navigate("", { state: { tier } });
  };

  return [
    {
      title: "tier-page-table-header-url",
      dataIndex: "url",
      render: (record: TierEntity) => {
        return (
          <img
            className="tier-badge-url"
            src={record?.url ?? "/pictures/image-placeholder-icon.png"}
            alt={`${record?.name}`}
          />
        );
      },
    },
    {
      title: "tier-page-table-header-name",
      dataIndex: "name",
    },
    {
      title: "tier-page-table-header-practice-needed",
      dataIndex: "practiceNeeded",
    },
    {
      title: "tier-page-table-header-created",
      dataIndex: "created",
      render: (record: TierEntity) => {
        return <span>{new Date(record?.created!).toLocaleDateString()}</span>;
      },
    },
    {
      title: "tier-page-table-header-updated",
      dataIndex: "updated",
      render: (record: TierEntity) => {
        return <span>{new Date(record?.updated!).toLocaleDateString()}</span>;
      },
    },
    {
      title: "tier-page-table-header-modify",
      dataIndex: "modify",
      render: (record: TierEntity) => {
        return <ModifyIcon onClick={() => handleOpenModifyModal(record)} />;
      },
    },
    {
      title: "tier-page-table-header-delete",
      dataIndex: "delete",
      render: (record: TierEntity) => {
        return <DeleteIcon onClick={() => handleOpenDeleteModal(record?.id)} />;
      },
    },
  ];
}
