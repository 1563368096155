import { Expose, Type } from "class-transformer";
import { IsArray, IsNumber, ValidateNested } from "class-validator";
import { FactEntity } from "../../entity";

export class GetAllFactDTO {
  @Expose()
  @ValidateNested()
  @IsArray()
  @Type(() => FactEntity)
  data!: FactEntity[];

  @Expose()
  @IsNumber()
  count!: number;

  @Expose()
  @IsNumber()
  page!: number;

  @Expose()
  @IsNumber()
  pageSize!: number;
}
