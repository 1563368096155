import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  QueryKeys,
  mutationHandler,
  useAppLocation,
  useAppParams,
  useAppState,
  useAppTranslation,
  PageType,
} from "../../../common";
import {
  ErrorModal,
  Form,
  Input,
  Modal,
  ValidationErrorModal,
} from "../../../components";
import {
  createDisappearingAction,
  modifyDisappearingAction,
} from "../../../actions";
import { DisappearingEntity } from "../../../entity";

type DisappearingMutationFormProps = {
  modify: boolean;
  handleMutationModalVisible: (value: boolean) => void;
  latestQueryKey: any;
};

export function DisappearingMutationForm(props: DisappearingMutationFormProps) {
  const queryClient = useQueryClient();
  const location = useAppLocation();
  const params = useAppParams();
  const { t } = useAppTranslation();

  const [state, setState] = useAppState({
    option: location.state?.disappearing.option ?? "",
    word: location.state?.disappearing.word ?? "",
    validationErrorMessage: null as string | null,
  });

  const mutateDisappearing = useMutation({
    mutationKey: [
      props.modify
        ? QueryKeys.modifyDisappearingGame
        : QueryKeys.createDisappearingGame,
    ],
    mutationFn: (data: any) =>
      props.modify
        ? modifyDisappearingAction(data)
        : createDisappearingAction(data),
    onMutate: async (newDisapp) => {
      await queryClient.cancelQueries({
        queryKey: props.latestQueryKey,
      });

      const previousDisappGames = queryClient.getQueryData([
        props.latestQueryKey,
      ]);

      queryClient.setQueryData(
        props.latestQueryKey,
        (old: PageType<DisappearingEntity>) => {
          const handler = mutationHandler({
            dataLength: old.data.length,
            pageSize: old.pageSize,
            count: old.count,
            oldData: old.data,
            newData: newDisapp,
          });

          return {
            page: old.page,
            pageSize: old.pageSize,
            count: handler.newCount,
            data: handler.newData,
          };
        }
      );

      return { previousDisappGames };
    },
    onError(error, variables, context) {
      queryClient.setQueryData(
        [props.latestQueryKey],
        context?.previousDisappGames
      );
    },
    onSuccess() {
      props.handleMutationModalVisible(false);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.disappearingGames],
      });
    },
  });

  const handleCloseErrorModal = () => {
    mutateDisappearing.reset();
  };

  const handleWordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, word: e.target.value }));
  };

  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, option: e.target.value }));
  };

  const checkForm = () => {
    if (state.word.trim().length === 0) {
      setState({
        ...state,
        validationErrorMessage: `A szó kitöltése kötelező!`,
      });
      return false;
    }
    if (state.option.trim().length === 0) {
      setState({
        ...state,
        validationErrorMessage: `Az opció kitöltése kötelező!`,
      });
      return false;
    }

    return true;
  };

  const handleMutateDisappearing = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!checkForm()) {
      return;
    }
    mutateDisappearing.mutate({
      lineOfPoetryId: parseInt(params.lineId!),
      option: state.option,
      word: state.word,
      id: props.modify ? location.state?.disappearing.id : undefined!,
    });
  };

  return (
    <div>
      <section>
        <Modal
          onClose={() => props.handleMutationModalVisible(false)}
          title={
            props.modify
              ? t("disappearing-page-mutate-disappearing-modal-modify-title")
              : t("disappearing-page-mutate-disappearing-modal-create-title")
          }
          footer
          cancelText={t(
            "disappearing-page-mutate-disappearing-modal-cancel-label"
          )}
          submitText={
            props.modify
              ? t(
                  "disappearing-page-mutate-disappearing-modal-modify-submit-label"
                )
              : t(
                  "disappearing-page-mutate-disappearing-modal-create-submit-label"
                )
          }
          handleSubmit={handleMutateDisappearing}
          loading={mutateDisappearing.isPending}
        >
          <Form onSubmit={handleMutateDisappearing}>
            <div className="disappearing-form">
              <Input
                type="text"
                maxLength={255}
                className="mutate-modal-input"
                onChange={handleWordChange}
                value={state.word}
                placeholder={t(
                  "disappearing-page-mutate-disappearing-modal-word-placeholder"
                )}
              />
              <Input
                type="text"
                maxLength={255}
                className="mutate-modal-input"
                onChange={handleOptionChange}
                value={state.option}
                placeholder={t(
                  "disappearing-page-mutate-disappearing-modal-option-placeholder"
                )}
              />
            </div>
          </Form>
        </Modal>
      </section>
      <section>
        {mutateDisappearing.isError && (
          <ErrorModal
            error={mutateDisappearing.error}
            onClose={handleCloseErrorModal}
            title={
              props.modify
                ? t(
                    "disappearing-page-mutate-disappearing-form-error-modal-modify-title"
                  )
                : t(
                    "disappearing-page-mutate-disappearing-form-error-modal-create-title"
                  )
            }
          />
        )}
      </section>
      <section>
        {state.validationErrorMessage && (
          <ValidationErrorModal
            error={state.validationErrorMessage}
            onClose={() =>
              setState({
                ...state,
                validationErrorMessage: null,
              })
            }
          />
        )}
      </section>
    </div>
  );
}
