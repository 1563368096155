import "./loading.icon.scss";

export function LoadingIcon() {
  return (
    <img
      className="loading-icon"
      src="/pictures/loading-icon.png"
      alt="loading-icon"
    />
  );
}
