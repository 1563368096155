import { Modal, Table } from "../../../components";
import { AchievementEntity } from "../../../entity";
import { ChildAchievementColumns } from "./child-achievement-table-columns";

type AchievementModalProps = {
  achievements: AchievementEntity[];
  childName: string;
  handleModalClose: (
    achievements: AchievementEntity[],
    childName: string
  ) => void;
};

export function ChildAchievementModal(props: AchievementModalProps) {
  return (
    <Modal
      onClose={() => props.handleModalClose([], "")}
      title={props.childName}
      footer={false}
    >
      <div className="achievement-table-container">
        <Table
          columns={Object.values(ChildAchievementColumns())}
          data={props.achievements}
          loading={false}
          nonHeight
        />
      </div>
    </Modal>
  );
}
