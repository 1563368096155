import axios from "axios";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { MutateVersionDTO } from "../../dto";

type ModifyVersionProps = {
  id: number;
  version: string;
  platform: "android" | "ios";
  critical: "Nem kritikus" | "Kritikus";
};

let body: MutateVersionDTO;

export async function modifyVersionAction(props: ModifyVersionProps) {
  const token = localStorage.getItem("token");

  const params = {
    id: props.id,
    version: props.version,
    platform: props.platform,
    critical: props.critical === "Kritikus",
  };

  return await axios
    .patch(
      `${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/versions/modify`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          "app-platform": "cms",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(async (res) => {
      body = plainToClass(MutateVersionDTO, res.data);
      await validate(body);
      const errors = await validate(body);
      if (errors.length > 0) {
        throw errors;
      }

      return res.data;
    });
}
