import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  QueryKeys,
  useAppNavigate,
  useAppParams,
  useAppState,
  useAppTranslation,
  useDebounce,
} from "../../common";
import {
  Button,
  ErrorModal,
  Input,
  PaginatorIcon,
  Table,
} from "../../components";
import {
  getAllDisappearingAction,
  modifyCorrectDisappearingAction,
} from "../../actions";
import {
  DisappearingColumns,
  DisappearingDeleteModal,
  DisappearingMutationForm,
} from "./components";
import "./disappearing.page.scss";

export function DisappearingPage() {
  const queryClient = useQueryClient();
  const navigate = useAppNavigate();
  const params = useAppParams();
  const { t } = useAppTranslation();

  const [state, setState] = useAppState({
    page: 1,
    pageSize: 10,
    word: "",
    mutateModalVisible: false,
    deleteModalVisible: false,
    modify: false,
    searchTerm: "",
  });

  const getAllDisappearing = useQuery({
    queryKey: [
      QueryKeys.disappearingGames,
      {
        lineOfPoetryId: parseInt(params.lineId!),
        page: state.page,
        pageSize: state.pageSize,
        word: state.word,
      },
    ],
    queryFn: () =>
      getAllDisappearingAction({
        lineOfPoetryId: parseInt(params.lineId!),
        page: state.page,
        pageSize: state.pageSize,
        word: state.searchTerm,
      }),
    refetchOnReconnect: "always",
    refetchOnWindowFocus: "always",
  });

  const handleSearch = useDebounce((searchTerm: string) => {
    setState((prevState) => ({ ...prevState, searchTerm: searchTerm }));
  }, 250);

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, word: e.target.value }));

    handleSearch(e.target.value);
  };

  const handleMutateModalVisible = (modify: boolean) => {
    navigate("", { state: undefined });
    setState((prevState) => ({
      ...prevState,
      mutateModalVisible: !prevState.mutateModalVisible,
      modify,
    }));
  };

  const handleDeleteModalVisible = () => {
    setState((prevState) => ({
      ...prevState,
      deleteModalVisible: !prevState.deleteModalVisible,
    }));
  };

  const handleSetPage = (value: number) => {
    setState((prevState) => ({ ...prevState, page: value }));
  };

  const handleSetPageSize = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setState((prevState) => ({
      ...prevState,
      pageSize: parseInt(e.target.value),
    }));
  };

  const modifyCorrectAnswer = useMutation({
    mutationKey: [QueryKeys.modifyCorrectDisappearingGame],
    mutationFn: modifyCorrectDisappearingAction,
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.disappearingGames],
      });
    },
  });

  const handleModifyCorrectAnswer = (id: number) => {
    modifyCorrectAnswer.mutate({
      id,
      lineOfPoetryId: parseInt(params.lineId!),
    });
  };

  const handleCloseErrorModal = () => {
    if (getAllDisappearing.isError) {
      getAllDisappearing.refetch();
    } else {
      modifyCorrectAnswer.reset();
    }
  };

  return (
    <main className="page">
      <div className="disappearing-page">
        <div className="line-of-poetry-subpages-breadcrumb">
          <PaginatorIcon
            type="previous"
            onClick={() =>
              navigate(
                `/cms/poetry/${params.poetryId}/${params.poetryTitle}/lines`
              )
            }
            active
          />
          <span className="poetry-subpage-breadcrumb-title">
            {params.poetryTitle}
          </span>
          <span>-</span>
          <span className="subtitle">{t("disappearing-page-subtitle")}</span>
          <span>-</span>
          <span className="line-text">{params.line}</span>
        </div>

        <section className="search-bar-container">
          <Input
            onChange={handleSearchTermChange}
            value={state.word}
            placeholder={t("disappearing-page-search-bar-placeholder")}
            type="search"
            className="searchbar"
          />
          <Button
            onClick={() => handleMutateModalVisible(false)}
            title="+"
            htmlType="button"
            creationButton
          />
        </section>
        <section>
          <Table
            columns={Object.values(
              DisappearingColumns({
                handleDeleteModalVisible,
                handleMutateModalVisible,
                handleModifyCorrectAnswer,
              })
            )}
            paginatorNeeded
            data={getAllDisappearing.data?.data}
            loading={getAllDisappearing.isLoading}
            page={getAllDisappearing.data?.page}
            pageSize={getAllDisappearing.data?.pageSize}
            allPage={getAllDisappearing.data?.count}
            handleSetPage={handleSetPage}
            handleSetPageSize={handleSetPageSize}
          />
        </section>
      </div>
      <section>
        {state.mutateModalVisible && (
          <DisappearingMutationForm
            modify={state.modify}
            handleMutationModalVisible={handleMutateModalVisible}
            latestQueryKey={[
              QueryKeys.disappearingGames,
              {
                lineOfPoetryId: parseInt(params.lineId!),
                page: state.page,
                pageSize: state.pageSize,
                word: state.word,
              },
            ]}
          />
        )}
      </section>
      <section>
        {state.deleteModalVisible && (
          <DisappearingDeleteModal
            handleSetPage={handleSetPage}
            handleDeleteModalVisible={handleDeleteModalVisible}
          />
        )}
      </section>
      <section>
        {(getAllDisappearing.isError || modifyCorrectAnswer.isError) && (
          <ErrorModal
            onClose={handleCloseErrorModal}
            title={
              getAllDisappearing.isError
                ? t("disappearing-page-error-modal-get-all-title")
                : t("disappearing-page-error-modal-correct-answer-title")
            }
            error={
              getAllDisappearing.isError
                ? getAllDisappearing.error
                : modifyCorrectAnswer.error
            }
          />
        )}
      </section>
    </main>
  );
}
