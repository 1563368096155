import { useEffect } from "react";
import { CloseIcon, ErrorIcon, InfoIcon, SuccessIcon } from "../icon";

type InfoModalProps = {
  onClose: () => void;
  message: string;
  type: "info" | "success" | "error";
};

const iconMap: Record<string, React.ComponentType<{}>> = {
  success: SuccessIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

export function InfoModal(props: InfoModalProps) {
  const IconComponent = iconMap[props.type];

  useEffect(() => {
    return () => {
      setTimeout(() => {
        props.onClose();
      }, 5000);
    };
  }, [props]);

  return (
    <div className="info-modal-container">
      <section className={`info-modal ${props.type}`}>
        <div className="info-modal-header">
          <CloseIcon onClick={props.onClose} />
        </div>
        <div className="info-modal-body">
          <div className="info-icon">
            <IconComponent />
          </div>
          {props.message}
        </div>
      </section>
    </div>
  );
}
