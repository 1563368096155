import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  QueryKeys,
  mutationHandler,
  useAppLocation,
  useAppState,
  useAppTranslation,
  PageType,
} from "../../../common";
import { deleteAdminAction } from "../../../actions";
import { ErrorModal, Modal } from "../../../components";
import { AdminEntity } from "../../../entity";

type AdminDeleteModalProps = {
  handleDeleteModalVisible: () => void;
  handleSetPage: (value: number) => void;
};

export function AdminDeleteModal(props: AdminDeleteModalProps) {
  const { t } = useAppTranslation();
  const location = useAppLocation();

  const queryClient = useQueryClient();
  const [state, setState] = useAppState({
    errorModalVisible: false,
  });

  const handleModalVisibility = () => {
    setState((prevState) => ({
      ...prevState,
      errorModalVisible: !prevState.errorModalVisible,
    }));
  };

  const deleteAdmin = useMutation({
    mutationKey: [QueryKeys.deleteAdminMutatuionQuery],
    mutationFn: deleteAdminAction,
    onSuccess() {
      props.handleDeleteModalVisible();
      queryClient.invalidateQueries({ queryKey: [QueryKeys.adminQuery] });
      let page = 1;

      queryClient.setQueryData(
        [QueryKeys.adminQuery],
        (old: PageType<AdminEntity>) => {
          const handler = mutationHandler({
            dataLength: old.data.length,
            pageSize: old.pageSize,
            count: old.count,
          });
          page = handler.page;
          return {
            page: old.page,
            pageSize: old.pageSize,
            count: handler.page,
            data: old.data,
          };
        }
      );
      props.handleSetPage(page);
    },
    onError() {
      handleModalVisibility();
    },
  });

  const handleDeleteAdmin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    deleteAdmin.mutate({ id: location.state?.id });
  };

  return (
    <div>
      <section>
        <Modal
          onClose={props.handleDeleteModalVisible}
          title={t("admin-page-delete-modal-title")}
          footer
          submitText={t("admin-page-delete-submit-button-title")}
          cancelText={t("admin-page-delete-cancel-button-title")}
          handleSubmit={handleDeleteAdmin}
          loading={deleteAdmin.isPending}
        >
          <span>{t("admin-page-delete-admin-question-label")}</span>
        </Modal>
      </section>
      <section>
        {state.errorModalVisible && (
          <ErrorModal
            error={deleteAdmin.error}
            onClose={handleModalVisibility}
            title={t("admin-page-delete-admin-error-modal-title")}
          />
        )}
      </section>
    </div>
  );
}
