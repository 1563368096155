import { Expose } from "class-transformer";
import {
  IsBoolean,
  IsDateString,
  IsDefined,
  IsNumber,
  IsOptional,
  IsString,
} from "class-validator";
export class ParentEntity {
  @Expose()
  @IsDefined()
  @IsNumber()
  id!: number;

  @Expose()
  @IsDefined()
  @IsString()
  name!: string;

  @Expose()
  @IsDefined()
  @IsString()
  nickname!: string;

  @Expose()
  @IsOptional()
  @IsString()
  email?: string | null;

  @Expose()
  @IsOptional()
  @IsString()
  pictureUrl?: string | null;

  @Expose()
  @IsOptional()
  @IsBoolean()
  soundEffects?: string | null;

  @Expose()
  @IsOptional()
  @IsBoolean()
  notifications?: string | null;

  @Expose()
  @IsDefined()
  @IsDateString()
  updated!: string;

  @Expose()
  @IsDefined()
  @IsDateString()
  created!: string;
}
