import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  QueryKeys,
  useAppLocation,
  useAppState,
  useAppTranslation,
} from "../../../common";
import { ErrorModal, Modal } from "../../../components";
import { deleteVersionAction } from "../../../actions";

type VersionDeleteModalProps = {
  handleDeleteModalVisible: () => void;
};

export function VersionDeleteModal(props: VersionDeleteModalProps) {
  const { t } = useAppTranslation();
  const location = useAppLocation();

  const queryClient = useQueryClient();
  const [state, setState] = useAppState({
    errorModalVisible: false,
  });

  const handleModalVisibility = () => {
    setState((prevState) => ({
      ...prevState,
      errorModalVisible: !prevState.errorModalVisible,
    }));
  };

  const deleteVersion = useMutation({
    mutationKey: [QueryKeys.deleteVersionQuery],
    mutationFn: deleteVersionAction,
    onSuccess() {
      props.handleDeleteModalVisible();
      queryClient.invalidateQueries({ queryKey: [QueryKeys.versionQuery] });
    },
    onError() {
      handleModalVisibility();
    },
  });

  const handleDeleteVersion = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    deleteVersion.mutate({ id: location.state?.id });
  };

  return (
    <div>
      <section>
        <Modal
          onClose={props.handleDeleteModalVisible}
          title={t("version-page-delete-modal-title")}
          footer
          submitText={t("version-page-delete-submit-button-title")}
          cancelText={t("version-page-delete-cancel-button-title")}
          handleSubmit={handleDeleteVersion}
          loading={deleteVersion.isPending}
        >
          <span>{t("version-page-delete-version-question-label")}</span>
        </Modal>
      </section>
      <section>
        {state.errorModalVisible && (
          <ErrorModal
            error={deleteVersion.error}
            onClose={handleModalVisibility}
            title={t("version-page-delete-version-error-modal-title")}
          />
        )}
      </section>
    </div>
  );
}
