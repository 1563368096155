import { Expose, Type } from "class-transformer";
import { ValidateNested } from "class-validator";
import { PoetryEntity } from "../../entity"

export class MutatePoetryDTO {
  @Expose()
  @ValidateNested()
  @Type(() => PoetryEntity)
  poetry!: PoetryEntity;
}
