import { Navigate, Outlet } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { ErrorModal, Header, Menu } from "../../components";
import {
  QueryKeys,
  RoleEnum,
  useAppState,
  useAppTranslation,
} from "../../common";
import { getMeAction } from "../../actions";
import "./default.layout.scss";

export function DefaultLayout() {
  const { t } = useAppTranslation();
  const [state, setState] = useAppState({
    errorModalVisible: false,
  });

  const getMe = useQuery({
    queryKey: [QueryKeys.userQuery],
    queryFn: getMeAction,
    retryOnMount: false,
  });

  if (!getMe.isLoading && getMe.data?.admin.firstLogin) {
    return <Navigate to={"/first-login"} />;
  }

  if (
    getMe.data?.admin.role !== RoleEnum.SUPERVISOR &&
    window.location.pathname.includes("admins")
  ) {
    return <Navigate to={"/cms/parents"} />;
  }

  const handleCloseModal = () => {
    setState((prevState) => ({
      ...prevState,
      modalVisible: !prevState.errorModalVisible,
    }));
  };

  if (getMe.isError) {
    handleCloseModal();
  }

  return (
    <main className="default-layout">
      {!getMe.isLoading && (
        <div>
          <section>
            <Header email={getMe.data?.admin.email} />
          </section>
          <section>
            <Menu adminRole={getMe.data?.admin.role} />
          </section>
          <div className="background-overlay"></div>
          <Outlet />
          <section>
            {state.errorModalVisible && getMe.isError && (
              <ErrorModal
                error={getMe.error}
                onClose={handleCloseModal}
                title={t("header-error-modal-title")}
              />
            )}
          </section>
        </div>
      )}
    </main>
  );
}
