import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  PoetrySubPages,
  QueryKeys,
  useAppNavigate,
  useAppParams,
  useAppState,
  useAppTranslation,
  useDebounce,
} from "../../common";
import { Button, ErrorModal, Input, Table } from "../../components";
import {
  getAllLineOfPoetryAction,
  modifyLineOfPoetryIndexAction,
} from "../../actions";
import {
  LineOfPoetryColumns,
  LineOfPoetryDeleteAudioModal,
  LineOfPoetryDeleteModal,
  LineOfPoetryMutationForm,
} from "./components";
import "./line-of-poetry.page.scss";

export function LineOfPoetryPage() {
  const queryClient = useQueryClient();
  const navigate = useAppNavigate();
  const { t } = useAppTranslation();
  const params = useAppParams();
  const [state, setState] = useAppState({
    page: 1,
    pageSize: 10,
    text: "",
    indexOrder: "" as "ASC" | "DESC" | "",
    mutateModalVisible: false,
    deleteAudioModalVisible: false,
    deleteModalVisible: false,
    modify: false,
    searchTerm: "",
  });

  const getAllLine = useQuery({
    queryKey: [
      QueryKeys.linesOfPoetryQuery,
      {
        poetryId: parseInt(params.poetryId!),
        page: state.page,
        pageSize: state.pageSize,
        text: state.searchTerm,
        indexOrder: state.indexOrder,
      },
    ],
    queryFn: () =>
      getAllLineOfPoetryAction({
        poetryId: parseInt(params.poetryId!),
        page: state.page,
        pageSize: state.pageSize,
        text: state.searchTerm,
        indexOrder: state.indexOrder,
      }),
  });

  const handleSearch = useDebounce((searchTerm: string) => {
    setState((prevState) => ({ ...prevState, searchTerm: searchTerm }));
  }, 250);

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, text: e.target.value }));

    handleSearch(e.target.value);
  };

  const handleIndexOrder = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      indexOrder: value as "ASC" | "DESC" | "",
    }));
  };

  const handleMutateModalVisible = (modify: boolean) => {
    navigate("", { state: undefined });
    setState((prevState) => ({
      ...prevState,
      mutateModalVisible: !prevState.mutateModalVisible,
      modify,
    }));
  };

  const handleDeleteModalVisible = () => {
    setState((prevState) => ({
      ...prevState,
      deleteModalVisible: !prevState.deleteModalVisible,
    }));
  };

  const handleDeleteAudioModalVisible = () => {
    setState((prevState) => ({
      ...prevState,
      deleteAudioModalVisible: !prevState.deleteAudioModalVisible,
    }));
  };

  const handleSetPage = (value: number) => {
    setState((prevState) => ({ ...prevState, page: value }));
  };

  const handleSetPageSize = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setState((prevState) => ({
      ...prevState,
      pageSize: parseInt(e.target.value),
    }));
  };

  const mutateLineIndex = useMutation({
    mutationKey: [QueryKeys.modifyLineOfPoetryIndex],
    mutationFn: modifyLineOfPoetryIndexAction,
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.linesOfPoetryQuery],
      });
    },
  });

  const handleMutateLineIndex = (id: number, index: number) => {
    mutateLineIndex.mutate({
      id,
      index,
    });
  };

  const handleCloseErrorModal = () => {
    if (getAllLine.isError) {
      getAllLine.refetch();
    } else {
      mutateLineIndex.reset();
    }
  };

  return (
    <main className="page">
      <div className="line-of-poetry-page">
        <PoetrySubPages />
        <section className="search-bar-container">
          <Input
            onChange={handleSearchTermChange}
            value={state.text}
            placeholder={t("line-of-poetry-page-search-bar-placeholder")}
            type="search"
            className="searchbar"
          />
          <Button
            onClick={() => handleMutateModalVisible(false)}
            title="+"
            htmlType="button"
            creationButton
          />
        </section>
        <section>
          <Table
            columns={Object.values(
              LineOfPoetryColumns({
                indexOrder: state.indexOrder,
                handleIndexOrder,
                handleDeleteAudioModalVisible,
                handleDeleteModalVisible,
                handleMutateModalVisible,
              })
            )}
            paginatorNeeded
            data={getAllLine.data?.data}
            loading={getAllLine.isLoading}
            page={getAllLine.data?.page}
            pageSize={getAllLine.data?.pageSize}
            allPage={getAllLine.data?.count}
            handleSetPage={handleSetPage}
            handleSetPageSize={handleSetPageSize}
            dragable
            handleDrop={handleMutateLineIndex}
          />
        </section>
      </div>
      {state.mutateModalVisible && (
        <section>
          <LineOfPoetryMutationForm
            modify={state.modify}
            handleMutationModalVisible={handleMutateModalVisible}
            latestQueryKey={[
              QueryKeys.linesOfPoetryQuery,
              {
                poetryId: parseInt(params.poetryId!),
                page: state.page,
                pageSize: state.pageSize,
                text: state.searchTerm,
                indexOrder: state.indexOrder,
              },
            ]}
          />
        </section>
      )}
      {state.deleteModalVisible && (
        <section>
          <LineOfPoetryDeleteModal
            handleDeleteModalVisible={handleDeleteModalVisible}
            handleSetPage={handleSetPage}
          />
        </section>
      )}
      {state.deleteAudioModalVisible && (
        <section>
          <LineOfPoetryDeleteAudioModal
            handleDeleteModalVisible={handleDeleteAudioModalVisible}
          />
        </section>
      )}
      {(getAllLine.isError || mutateLineIndex.isError) && (
        <section>
          <ErrorModal
            onClose={handleCloseErrorModal}
            title={
              getAllLine.isError
                ? t("line-of-poetry-page-error-modal-get-all-title")
                : t("line-of-poetry-page-error-modal-mutate-index-title")
            }
            error={getAllLine.error || mutateLineIndex.error}
          />
        </section>
      )}
    </main>
  );
}
