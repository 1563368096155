import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  QueryKeys,
  mutationHandler,
  useAppLocation,
  useAppState,
  useAppTranslation,
  PageType,
} from "../../../common";
import { ErrorModal, Modal } from "../../../components";
import { deleteChildAction } from "../../../actions";
import { ChildEntity } from "../../../entity";

type ChildDeleteModalProps = {
  handleDeleteModalVisible: () => void;
  handleSetPage: (value: number) => void;
};

export function ChildDeleteModal(props: ChildDeleteModalProps) {
  const { t } = useAppTranslation();
  const location = useAppLocation();
  const queryClient = useQueryClient();

  const [state, setState] = useAppState({
    errorModalVisible: false,
  });

  const handleModalVisibility = () => {
    setState((prevState) => ({
      ...prevState,
      errorModalVisible: !prevState.errorModalVisible,
    }));
  };

  const deleteChild = useMutation({
    mutationKey: [QueryKeys.deleteChildMutation],
    mutationFn: deleteChildAction,
    onSuccess() {
      props.handleDeleteModalVisible();
      queryClient.invalidateQueries({ queryKey: [QueryKeys.childrenQuery] });
      let page = 1;
      queryClient.setQueryData(
        [QueryKeys.childrenQuery],
        (old: PageType<ChildEntity>) => {
          const handler = mutationHandler({
            dataLength: old.data.length,
            pageSize: old.pageSize,
            count: old.count,
          });
          page = handler.page;

          return {
            page: old.page,
            pageSize: old.pageSize,
            count: handler.page,
            data: old.data,
          };
        }
      );
      props.handleSetPage(page);
    },
    onError() {
      handleModalVisibility();
    },
  });

  const handleDeleteChild = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    deleteChild.mutate({ id: location.state?.id });
  };

  return (
    <div>
      <section>
        <Modal
          onClose={props.handleDeleteModalVisible}
          title={t("child-page-delete-modal-title")}
          footer
          submitText={t("child-page-delete-submit-button-title")}
          cancelText={t("child-page-delete-cancel-button-title")}
          handleSubmit={handleDeleteChild}
          loading={deleteChild.isPending}
        >
          <span>{t("child-page-delete-child-question-label")}</span>
        </Modal>
      </section>
      <section>
        {state.errorModalVisible && (
          <ErrorModal
            error={deleteChild.error}
            onClose={handleModalVisibility}
            title={t("child-page-delete-child-error-modal-title")}
          />
        )}
      </section>
    </div>
  );
}
