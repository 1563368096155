import axios from "axios";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { GetAllFactDTO } from "../../dto";

type GetAllFactProps = {
  poetryId: number;
  page: number;
  pageSize: number;
  searchTerm?: string;
};

let body: GetAllFactDTO;

export async function getAllFactAction(props: GetAllFactProps) {
  const token = localStorage.getItem("token");

  const params = {
    poetryId: props.poetryId,
    page: props.page,
    pageSize: props.pageSize,
    searchTerm: props.searchTerm,
  };

  return await axios
    .get(`${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/facts/get-all`, {
      headers: {
        "Content-Type": "application/json",
        "app-platform": "cms",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then(async (res) => {
      body = plainToClass(GetAllFactDTO, res.data);
      const errors = await validate(body);

      if (errors.length > 0) {
        throw errors;
      }

      return res.data;
    });
}
