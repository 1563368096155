import { useQuery } from "@tanstack/react-query";
import {
  QueryKeys,
  useAppNavigate,
  useAppState,
  useAppTranslation,
  useDebounce,
} from "../../common";
import { Button, ErrorModal, Input, Table } from "../../components";
import {
  CategoryColumns,
  CategoryDeleteModal,
  CategoryMutationForm,
} from "./components";
import { getAllCategoryAction } from "../../actions";
import "./category.page.scss";

export function CategoryPage() {
  const navigate = useAppNavigate();
  const { t } = useAppTranslation();
  const [state, setState] = useAppState({
    page: 1,
    pageSize: 10,
    title: "",
    titleOrder: "" as "ASC" | "DESC" | "",
    mutationModalVisible: false,
    deleteModalVisible: false,
    modify: false,
    searchTerm: "",
  });

  const getAllCategory = useQuery({
    queryKey: [
      QueryKeys.categoryQuery,
      {
        page: state.page,
        pageSize: state.pageSize,
        title: state.title,
        titleOrder: state.titleOrder,
      },
    ],
    queryFn: () =>
      getAllCategoryAction({
        page: state.page,
        pageSize: state.pageSize,
        title: state.title,
        titleOrder: state.titleOrder,
      }),
    refetchOnReconnect: "always",
    refetchOnWindowFocus: "always",
  });

  const handleSearch = useDebounce((searchTerm: string) => {
    setState((prevState) => ({ ...prevState, searchTerm: searchTerm }));
  }, 250);

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, title: e.target.value }));

    handleSearch(e.target.value);
  };

  const handleMutateModalVisible = (modify: boolean) => {
    navigate("", { state: undefined });
    setState((prevState) => ({
      ...prevState,
      mutationModalVisible: !prevState.mutationModalVisible,
      modify,
    }));
  };
  const handleTitleOrder = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      titleOrder: value as "ASC" | "DESC" | "",
    }));
  };

  const handleDeleteModalVisible = () => {
    setState((prevState) => ({
      ...prevState,
      deleteModalVisible: !prevState.deleteModalVisible,
    }));
  };

  const handleSetPage = (value: number) => {
    setState((prevState) => ({ ...prevState, page: value }));
  };

  const handleSetPageSize = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setState((prevState) => ({
      ...prevState,
      pageSize: parseInt(e.target.value),
    }));
  };

  const handleCloseErrorModal = () => {
    getAllCategory.refetch();
  };

  return (
    <main className="page">
      <div className="category-page">
        <section className="title">{t("category-page-title")}</section>
        <section className="search-bar-container">
          <Input
            onChange={handleSearchTermChange}
            value={state.title}
            placeholder={t("category-page-search-bar-placeholder")}
            type="search"
            className="searchbar"
          />
          <Button
            onClick={() => handleMutateModalVisible(false)}
            title="+"
            htmlType="button"
            creationButton
          />
        </section>
        <section>
          <Table
            columns={Object.values(
              CategoryColumns({
                titleOrder: state.titleOrder,
                handleTitleOrder,
                handleDeleteModalVisible,
                handleMutateModalVisible,
              })
            )}
            paginatorNeeded
            data={getAllCategory.data?.data}
            loading={getAllCategory.isLoading}
            page={getAllCategory.data?.page}
            pageSize={getAllCategory.data?.pageSize}
            allPage={getAllCategory.data?.count}
            handleSetPage={handleSetPage}
            handleSetPageSize={handleSetPageSize}
          />
        </section>
      </div>
      <section>
        {state.mutationModalVisible && (
          <CategoryMutationForm
            modify={state.modify}
            handleMutationModalVisible={handleMutateModalVisible}
            latestQueryKey={
              [
                QueryKeys.categoryQuery,
                {
                  page: state.page,
                  pageSize: state.pageSize,
                  title: state.title,
                  titleOrder: state.titleOrder,
                },
              ]
            }
          />
        )}
      </section>
      <section>
        {state.deleteModalVisible && (
          <CategoryDeleteModal
            handleDeleteModalVisible={handleDeleteModalVisible}
            handleSetPage={handleSetPage}
          />
        )}
      </section>
      <section>
        {getAllCategory.isError && (
          <ErrorModal
            onClose={handleCloseErrorModal}
            title={t("category-page-error-modal-title")}
            error={getAllCategory.error}
          />
        )}
      </section>
    </main>
  );
}
