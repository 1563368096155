import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  PageType,
  QueryKeys,
  mutationHandler,
  useAppLocation,
  useAppParams,
  useAppState,
  useAppTranslation,
} from "../../../common";
import {
  ErrorModal,
  Form,
  Input,
  Modal,
  ValidationErrorModal,
} from "../../../components";
import { createFactAction, modifyFactAction } from "../../../actions";
import { FactEntity } from "../../../entity";

type FactMutationFormProps = {
  modify: boolean;
  handleMutationModalVisible: (value: boolean) => void;
  latestQueryKey: any;
};

export function FactMutationForm(props: FactMutationFormProps) {
  const queryClient = useQueryClient();
  const location = useAppLocation();
  const params = useAppParams();
  const { t } = useAppTranslation();

  const [state, setState] = useAppState({
    title: location.state?.fact.title ?? "",
    description: location.state?.fact.description ?? "",
    validationErrorMessage: null as string | null,
  });

  const mutateFact = useMutation({
    mutationKey: [
      props.modify ? QueryKeys.modifyFactQuery : QueryKeys.createFactQuery,
    ],
    mutationFn: (data: any) =>
      props.modify ? modifyFactAction(data) : createFactAction(data),
    onMutate: async (newFact) => {
      await queryClient.cancelQueries({ queryKey: props.latestQueryKey });
      const previousFacts = queryClient.getQueryData([props.latestQueryKey]);

      queryClient.setQueryData(
        props.latestQueryKey,
        (old: PageType<FactEntity>) => {
          const handler = mutationHandler({
            dataLength: old.data.length,
            pageSize: old.pageSize,
            count: old.count,
            oldData: old.data,
            newData: newFact,
          });

          return {
            page: old.page,
            pageSize: old.pageSize,
            count: handler.newCount,
            data: handler.newData,
          };
        }
      );

      return { previousFacts };
    },
    onSuccess() {
      props.handleMutationModalVisible(false);
    },
    onError(error, variables, context) {
      queryClient.setQueryData([props.latestQueryKey], context?.previousFacts);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.factsQuery],
      });
    },
  });

  const handleCloseErrorModal = () => {
    mutateFact.reset();
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, title: e.target.value }));
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, description: e.target.value }));
  };

  const checkForm = () => {
    if (state.title.trim().length === 0) {
      setState({
        ...state,
        validationErrorMessage: `A cím kitöltése kötelező!`,
      });
      return false;
    }
    if (state.description.trim().length === 0) {
      setState({
        ...state,
        validationErrorMessage: `A leírás kitöltése kötelező!`,
      });
      return false;
    }

    return true;
  };

  const handleMutateFact = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!checkForm()) {
      return;
    }
    mutateFact.mutate({
      poetryId: parseInt(params.poetryId!),
      title: state.title,
      description: state.description,
      id: props.modify ? location.state?.fact.id : undefined!,
    });
  };

  return (
    <div>
      <section>
        <Modal
          onClose={() => props.handleMutationModalVisible(false)}
          title={
            props.modify
              ? t("fact-page-mutate-fact-modal-modify-title")
              : t("fact-page-mutate-fact-modal-create-title")
          }
          footer
          cancelText={t("fact-page-mutate-fact-modal-cancel-label")}
          submitText={
            props.modify
              ? t("fact-page-mutate-fact-modal-modify-submit-label")
              : t("fact-page-mutate-fact-modal-create-submit-label")
          }
          handleSubmit={handleMutateFact}
          loading={mutateFact.isPending}
        >
          <Form onSubmit={handleMutateFact}>
            <div className="fact-mutation-form">
              <Input
                type="text"
                maxLength={255}
                className="mutate-modal-input"
                onChange={handleTitleChange}
                value={state.title}
                placeholder={t("fact-page-mutate-fact-modal-title-placeholder")}
              />
              <Input
                type="text"
                maxLength={255}
                className="mutate-modal-input"
                onChange={handleDescriptionChange}
                value={state.description}
                placeholder={t(
                  "fact-page-mutate-fact-modal-description-placeholder"
                )}
              />
            </div>
          </Form>
        </Modal>
      </section>
      <section>
        {mutateFact.isError && (
          <ErrorModal
            error={mutateFact.error}
            onClose={handleCloseErrorModal}
            title={
              props.modify
                ? t("fact-page-mutate-fact-form-error-modal-modify-title")
                : t("fact-page-mutate-fact-form-error-modal-create-title")
            }
          />
        )}
      </section>
      <section>
        {state.validationErrorMessage && (
          <ValidationErrorModal
            error={state.validationErrorMessage}
            onClose={() =>
              setState({
                ...state,
                validationErrorMessage: null,
              })
            }
          />
        )}
      </section>
    </div>
  );
}
