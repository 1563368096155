import { PaginatorOptions, useAppTranslation } from "../../common";
import { PaginatorIcon } from "../icon";
import { Selector } from "../selector/selector";
import "./paginator.scss";

type PaginatorProps = {
  page: number;
  pagesize: number;
  allPage: number;
  handleSetPage: (value: number) => void;
  handleSetPageSize: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

export function Paginator(props: PaginatorProps) {
  const { t } = useAppTranslation();

  return (
    <div className="paginator-container">
      <PaginatorIcon
        onClick={() =>
          props.page <= props.allPage && props.page !== 1
            ? props.handleSetPage(props.page - 1)
            : null
        }
        type="previous"
        active={props.page <= props.allPage && props.page !== 1}
      />
      <span>{t("paginator-page-title")}:</span>
      <span>{`${props.page ?? 1} / ${props.allPage ?? 1}`}</span>
      <PaginatorIcon
        onClick={() =>
          props.page !== props.allPage && props.allPage !== 0
            ? props.handleSetPage(props.page + 1)
            : null
        }
        type="next"
        active={props.page !== props.allPage && props.allPage !== 0}
      />
      <Selector
        name="page-selector"
        id="page-selector"
        currentValue={props.pagesize ?? 10}
        handleSelectOption={props.handleSetPageSize}
        options={PaginatorOptions}
      />
    </div>
  );
}
