import React from "react";
import { useAppTranslation } from "../../common";
import "./selector.scss";

type SelectorProps = {
  name: string;
  id: string;
  handleSelectOption: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  currentValue?: number | string;
  options: {
    value: number | string;
    betweenText?: string;
    label: string;
  }[];
  onlyLabel?: boolean;
};

export function Selector(props: SelectorProps) {
  const { t } = useAppTranslation();

  return (
    <select
      className="page-selector"
      name={props.name}
      id={props.id}
      onChange={(e) => props.handleSelectOption(e)}
      value={props.currentValue}
    >
      {!props.currentValue && <option></option>}
      {props.options.map((o, index) => (
        <option
          key={index}
          value={o.value}
          label={`${
            props.onlyLabel
              ? t(o.label)
              : `${o.value.toString()} ${o.betweenText ?? ""} ${t(o.label)}`
          }`}
        />
      ))}
    </select>
  );
}
