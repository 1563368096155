import { createBrowserRouter } from "react-router-dom";
import {
  AchievementPage,
  AdminPage,
  CategoryPage,
  ChildPage,
  FisrtLoginPage,
  ForgotPasswordPage,
  LoginPage,
  NotFoundPage,
  ParentPage,
  PoetPage,
  ProfilePage,
  SuccessPage,
  PoetryPage,
  TierPage,
  VersionPage,
  FactPage,
  LineOfPoetryPage,
  PoetryChatPage,
  PoetryQuizPage,
  InlineInfoPage,
  DisappearingPage,
  ForgotPasswordConfirmPage,
} from "../pages";
import { DefaultLayout, LoginLayout } from "../layout";
import { AuthHandler } from "../common";

export const router = createBrowserRouter([
  {
    element: (
      <AuthHandler>
        <DefaultLayout />
      </AuthHandler>
    ),
    errorElement: null,
    path: "/cms",
    children: [
      {
        element: <AdminPage />,
        path: `admins`,
      },
      {
        element: <ProfilePage />,
        path: `profile`,
      },
      {
        element: <ParentPage />,
        path: `parents`,
      },
      {
        element: <ChildPage />,
        path: `parents/:parentId`,
      },
      {
        element: <PoetryPage />,
        path: `poetry`,
      },
      {
        element: <FactPage />,
        path: `poetry/:poetryId/:poetryTitle/facts`,
      },
      {
        element: <PoetryChatPage />,
        path: `poetry/:poetryId/:poetryTitle/chats`,
      },
      {
        element: <PoetryQuizPage />,
        path: `poetry/:poetryId/:poetryTitle/quizzes`,
      },
      {
        element: <LineOfPoetryPage />,
        path: `poetry/:poetryId/:poetryTitle/lines`,
      },
      {
        element: <InlineInfoPage />,
        path: `poetry/:poetryId/:poetryTitle/lines/:lineId/:line/info`,
      },
      {
        element: <DisappearingPage />,
        path: `poetry/:poetryId/:poetryTitle/lines/:lineId/:line/disappearing`,
      },
      {
        element: <PoetPage />,
        path: `poets`,
      },
      {
        element: <CategoryPage />,
        path: `categories`,
      },
      {
        element: <AchievementPage />,
        path: `achievements`,
      },
      {
        element: <TierPage />,
        path: `tiers`,
      },
      {
        element: <VersionPage />,
        path: `versions`,
      },
    ],
  },
  {
    element: <LoginLayout />,
    errorElement: null,
    path: "/",
    children: [
      {
        element: <LoginPage />,
        path: `/`,
      },
      {
        element: <ForgotPasswordPage />,
        path: `forgot-password`,
      },
      {
        element: <ForgotPasswordConfirmPage />,
        path: `forgot-password-confirm`,
      },
    ],
  },
  {
    element: (
      <AuthHandler>
        <LoginLayout />
      </AuthHandler>
    ),
    errorElement: null,
    path: "/",
    children: [
      {
        element: <FisrtLoginPage />,
        path: `first-login`,
      },
    ],
  },

  {
    element: <SuccessPage />,
    path: `/success`,
  },
  {
    element: <NotFoundPage />,
    path: "*",
  },
]);
