import axios from "axios";
import { GetAllPoetryChatDTO } from "../../dto";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";

type GetAllPoetryChatProps = {
  page: number;
  pageSize: number;
  searchTerm?: string;
  indexOrder?: "ASC" | "DESC" | "";
  poetryId: number;
  type: 0 | 1;
};

let body: GetAllPoetryChatDTO;

export async function getAllPoetryChatAction(props: GetAllPoetryChatProps) {
  const token = localStorage.getItem("token");

  const params = {
    page: props.page,
    pageSize: props.pageSize,
    text: props.searchTerm,
    indexOrder: props.indexOrder !== "" ? props.indexOrder : undefined,
    poetryId: props.poetryId,
    type: props.type
  };

  return await axios
    .get(`${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/preparation-chats/get-all`, {
      headers: {
        "Content-Type": "application/json",
        "app-platform": "cms",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then(async (res) => {
      body = plainToClass(GetAllPoetryChatDTO, res.data);
      const errors = await validate(body);

      if (errors.length > 0) {
        throw errors;
      }

      return res.data;
    });
}
