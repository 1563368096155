import axios from "axios";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { MutateDisappearingDTO } from "../../dto";

type CreateDisappearingProps = {
  lineOfPoetryId: number;
  option: string;
  word: string;
};

let body: MutateDisappearingDTO;

export async function createDisappearingAction(props: CreateDisappearingProps) {
  const token = localStorage.getItem("token");

  const params = {
    lineOfPoetryId: props.lineOfPoetryId,
    option: props.option,
    word: props.word,
  };

  return await axios
    .post(
      `${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/disappearing-game-options/create`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          "app-platform": "cms",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(async (res) => {
      body = plainToClass(MutateDisappearingDTO, res.data);
      const errors = await validate(body);

      if (errors.length > 0) {
        throw errors;
      }

      return res.data;
    });
}
