import axios from "axios";
import { GetAllPoetryDTO } from "../../dto";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";

type GetAllPoetryProps = {
  page: number;
  pageSize: number;
  searchTerm?: string;
  titleOrder?: "ASC" | "DESC" | "";
  writingDateOrder?: "ASC" | "DESC" | "";
};

let body: GetAllPoetryDTO;

export async function getAllPoetryAction(props: GetAllPoetryProps) {
  const token = localStorage.getItem("token");

  const params = {
    page: props.page,
    pageSize: props.pageSize,
    searchTerm: props.searchTerm,
    titleOrder: props.titleOrder !== "" ? props.titleOrder : undefined,
    timeOfWrittingOrder: props.writingDateOrder !== "" ? props.writingDateOrder : undefined
  };

  return await axios
    .get(`${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/poetries/get-all`, {
      headers: {
        "Content-Type": "application/json",
        "app-platform": "cms",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then(async (res) => {
      body = plainToClass(GetAllPoetryDTO, res.data);
      const errors = await validate(body);

      if (errors.length > 0) {
        throw errors;
      }

      return res.data;
    });
}
