import { useQuery } from "@tanstack/react-query";
import {
  QueryKeys,
  useAppState,
  useAppTranslation,
  useDebounce,
} from "../../common";
import { Button, ErrorModal, Input, Table } from "../../components";
import {
  AdminColumns,
  AdminDeleteModal,
  AdminMutationForm,
} from "./components";
import { getAllAdminAction } from "../../actions";
import "./admin.page.scss";

export function AdminPage() {
  const { t } = useAppTranslation();
  const [state, setState] = useAppState({
    page: 1,
    pageSize: 10,
    email: "",
    createdOrder: "" as "ASC" | "DESC" | "",
    updatedOrder: "" as "ASC" | "DESC" | "",
    creationModalVisible: false,
    deleteModalVisible: false,
    searchTerm: "",
  });

  const getAllAdmin = useQuery({
    queryKey: [
      QueryKeys.adminQuery,
      {
        page: state.page,
        pageSize: state.pageSize,
        email: state.searchTerm,
        createdOrder: state.createdOrder,
        updatedOrder: state.updatedOrder,
      },
    ],
    queryFn: () =>
      getAllAdminAction({
        page: state.page,
        pageSize: state.pageSize,
        email: state.searchTerm,
        createdOrder: state.createdOrder,
        updatedOrder: state.updatedOrder,
      }),
    refetchOnReconnect: "always",
    refetchOnWindowFocus: "always",
  });

  const handleSearch = useDebounce((searchTerm: string) => {
    setState((prevState) => ({ ...prevState, searchTerm: searchTerm }));
  }, 250);

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, email: e.target.value }));

    handleSearch(e.target.value);
  };

  const handleCreationModalVisible = () => {
    setState((prevState) => ({
      ...prevState,
      creationModalVisible: !prevState.creationModalVisible,
    }));
  };

  const handleDeleteModalVisible = () => {
    setState((prevState) => ({
      ...prevState,
      deleteModalVisible: !prevState.deleteModalVisible,
    }));
  };

  const handleCreatedOrder = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      createdOrder: value as "ASC" | "DESC" | "",
    }));
  };

  const handleUpdatedOrder = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      updatedOrder: value as "ASC" | "DESC" | "",
    }));
  };

  const handleCloseErrorModal = () => {
    getAllAdmin.refetch();
  };

  const handleSetPage = (value: number) => {
    setState((prevState) => ({ ...prevState, page: value }));
  };

  const handleSetPageSize = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setState((prevState) => ({
      ...prevState,
      pageSize: parseInt(e.target.value),
    }));
  };

  return (
    <main className="page">
      <div className="admin-page">
        <section className="title">{t("admin-page-title")}</section>
        <section className="search-bar-container">
          <Input
            onChange={handleSearchTermChange}
            value={state.email}
            placeholder={t("admin-page-search-bar-placeholder")}
            type="search"
            className="searchbar"
          />
          <Button
            onClick={handleCreationModalVisible}
            title="+"
            htmlType="button"
            creationButton
          />
        </section>
        <section>
          <Table
            columns={Object.values(
              AdminColumns({
                updatedOrder: state.updatedOrder,
                createdOrder: state.createdOrder,
                handleCreatedOrder,
                handleUpdatedOrder,
                handleDeleteModalVisible,
              })
            )}
            paginatorNeeded
            data={getAllAdmin.data?.data}
            loading={getAllAdmin.isLoading}
            page={getAllAdmin.data?.page}
            pageSize={getAllAdmin.data?.pageSize}
            allPage={getAllAdmin.data?.count}
            handleSetPage={handleSetPage}
            handleSetPageSize={handleSetPageSize}
          />
        </section>
      </div>
      <section>
        {state.creationModalVisible && (
          <AdminMutationForm
            handleCreationModalVisible={handleCreationModalVisible}
            latestQueryKey={[
              QueryKeys.adminQuery,
              {
                page: state.page,
                pageSize: state.pageSize,
                email: state.searchTerm,
                createdOrder: state.createdOrder,
                updatedOrder: state.updatedOrder,
              },
            ]}
          />
        )}
      </section>
      <section>
        {getAllAdmin.isError && (
          <ErrorModal
            onClose={handleCloseErrorModal}
            title={t("admin-page-error-modal-title")}
            error={getAllAdmin.error}
          />
        )}
      </section>
      <section>
        {state.deleteModalVisible && (
          <AdminDeleteModal
            handleDeleteModalVisible={handleDeleteModalVisible}
            handleSetPage={handleSetPage}
          />
        )}
      </section>
    </main>
  );
}
