import { Expose, Type } from "class-transformer";
import { ValidateNested } from "class-validator";
import { SettingEntity } from "../../entity";

export class GetSettingDTO {
  @Expose()
  @ValidateNested()
  @Type(() => SettingEntity)
  setting!: SettingEntity;
}
