import { Expose } from "class-transformer"
import { IsBoolean, IsDateString, IsDefined, IsNumber, IsString } from "class-validator"

export class PoetryQuizAnswerEntity {
    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number;


    @Expose()
    @IsDefined()
    @IsString()
    answer!: string;

    @Expose()
    @IsDefined()
    @IsBoolean()
    isCorrect!: boolean;


    @Expose()
    @IsDefined()
    @IsDateString()
    created!: Date

    @Expose()
    @IsDefined()
    @IsDateString()
    updated!: Date
}
