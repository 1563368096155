import axios from "axios";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { MutateAchievementDTO } from "../../dto";

type CreateAchievementProps = {
  badge: File;
  name: string;
  description: string;
  type: string;
  level: number;
};

let body: MutateAchievementDTO;

export async function createAchievementAction(props: CreateAchievementProps) {
  const token = localStorage.getItem("token");

  const params = new FormData();
  params.append("badge", props.badge);
  params.append("name", props.name);
  params.append("description", props.description);
  params.append("type", props.type);
  params.append("level", props.level.toString());

  return await axios
    .post(
      `${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/achievements/create`,
      params,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "app-platform": "cms",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(async (res) => {
      body = plainToClass(MutateAchievementDTO, res.data);
      await validate(body);
      const errors = await validate(body);
      if (errors.length > 0) {
        throw errors;
      }

      return res.data;
    });
}
