import { Expose } from "class-transformer";
import {
  IsDateString,
  IsDefined,
  IsNumber,
  IsOptional,
  IsString,
} from "class-validator";

export class TierEntity {
  @Expose()
  @IsDefined()
  @IsNumber()
  id!: number;

  @Expose()
  @IsDefined()
  @IsString()
  name!: string;

  @Expose()
  @IsDefined()
  @IsNumber()
  practiceNeeded!: number;

  @Expose()
  @IsDefined()
  @IsString()
  url!: string;

  @Expose()
  @IsOptional()
  @IsDateString()
  updated?: string;

  @Expose()
  @IsOptional()
  @IsDateString()
  created?: string;
}
