import axios from "axios";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { GetAllVersionDTO } from "../../dto";

type GetAllVersionProps = {
  page: number;
  pageSize: number;
  platform?: string;
  critical?: string;
  version?: string;
  createdOrder?: "ASC" | "DESC" | "";
};

let body: GetAllVersionDTO;

export async function getAllVersionAction(props: GetAllVersionProps) {
  const token = localStorage.getItem("token");

  const params = {
    page: props.page,
    pageSize: props.pageSize,
    version: props.version !== "" ? props.version : undefined,
    critical: props.critical ? props.critical : undefined,
    platform: props.platform ? props.platform : undefined,
    createdOrder: props.createdOrder !== "" ? props.createdOrder : undefined,
  };

  return await axios
    .get(`${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/versions/get-all`, {
      headers: {
        "Content-Type": "application/json",
        "app-platform": "cms",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then(async (res) => {
      body = plainToClass(GetAllVersionDTO, res.data);
      const errors = await validate(body);

      if (errors.length > 0) {
        throw errors;
      }

      return res.data;
    });
}
