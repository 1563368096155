import { Expose, Type } from "class-transformer";
import { ValidateNested } from "class-validator";
import { GetMeEntity } from "../../entity";

export class GetMeDTO {
  @Expose()
  @ValidateNested()
  @Type(() => GetMeEntity)
  admin!: GetMeEntity;
}
