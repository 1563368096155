import { NavLink } from "react-router-dom";
import "./logo.scss"

export function Logo() {
  //TODO ide majd a logót berakni
  return (
    <NavLink to={"/cms/parents"}>
      <img className="logo" src={`/pictures/logo.png`} alt="logo" />
    </NavLink>
  );
}
