import { useQuery } from "@tanstack/react-query";
import {
  PoetrySubPages,
  QueryKeys,
  useAppState,
  useAppTranslation,
  useDebounce,
} from "../../common";
import { Button, ErrorModal, Input, Table } from "../../components";
import {
  PoetryChatColumns,
  PoetryChatDeleteModal,
  PoetryChatModal,
} from "./components";
import "./poetry-chat.page.scss";
import { PoetryChatEntity } from "../../entity";
import { getAllPoetryChatAction } from "../../actions/poetry-chat";
import { useParams } from "react-router-dom";

export function PoetryChatPage() {
  const params = useParams();
  const { t } = useAppTranslation();
  const [state, setState] = useAppState({
    page: 1,
    pageSize: 10,
    search: "",
    indexOrder: "ASC" as "ASC" | "DESC" | "",
    deleteModalVisible: false,
    creationModalVisible: false,
    selectedPoetryChat: null as PoetryChatEntity | null,
    poetryId: parseInt(params.poetryId!),
    type: 0 as 0 | 1,
    searchTerm: "",
  });

  const getAllPoetryChat = useQuery({
    queryKey: [
      QueryKeys.poetryChatQuery,
      {
        poetryId: state.poetryId,
        page: state.page,
        pageSize: state.pageSize,
        searchTerm: state.searchTerm,
        type: state.type,
        indexOrder: state.indexOrder,
      },
    ],
    queryFn: () =>
      getAllPoetryChatAction({
        poetryId: state.poetryId,
        page: state.page,
        pageSize: state.pageSize,
        searchTerm: state.searchTerm,
        type: state.type,
        indexOrder: state.indexOrder,
      }),
  });

  const handleSearch = useDebounce((searchTerm: string) => {
    setState((prevState) => ({ ...prevState, searchTerm: searchTerm }));
  }, 250);

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, search: e.target.value }));

    handleSearch(e.target.value);
  };

  const handleIndexOrder = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      indexOrder: value as "ASC" | "DESC" | "",
    }));
  };

  const handleSetPage = (value: number) => {
    setState((prevState) => ({ ...prevState, page: value }));
  };

  const handleSetPageSize = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setState((prevState) => ({
      ...prevState,
      pageSize: parseInt(e.target.value),
    }));
  };

  const handleDeleteModalVisible = () => {
    setState((prevState) => ({
      ...prevState,
      deleteModalVisible: !prevState.deleteModalVisible,
    }));
  };

  const handleCreationModalClose = () => {
    setState((prevState) => ({
      ...prevState,
      selectedPoetryChat: null,
      creationModalVisible: false,
    }));
  };

  const showModifyModal = (poetryChat: PoetryChatEntity) => {
    setState({
      ...state,
      selectedPoetryChat: poetryChat,
      creationModalVisible: true,
    });
  };

  const handleCloseErrorModal = () => {
    getAllPoetryChat.refetch();
  };

  return (
    <main className="page">
      <div className="poetry-chat-page">
        <section className="top-bar-container">
          <PoetrySubPages />
          <div className="type-select">
            <span
              className={state.type === 0 ? `active` : ``}
              onClick={() => setState({ ...state, type: 0 })}
            >
              {t("poetry-chat-page-start-chat-title")}
            </span>
            <span
              className={state.type === 1 ? `active` : ``}
              onClick={() => setState({ ...state, type: 1 })}
            >
              {t("poetry-chat-page-start-end-title")}
            </span>
          </div>
        </section>
        <section className="search-bar-container">
          <Input
            onChange={handleSearchTermChange}
            value={state.search}
            placeholder={t("poetry-chat-page-search-bar-placeholder")}
            type="search"
            className="searchbar"
          />
          <Button
            onClick={() =>
              setState({
                ...state,
                creationModalVisible: true,
              })
            }
            title="+"
            htmlType="button"
            creationButton
          />
        </section>
        <section>
          <Table
            columns={Object.values(
              PoetryChatColumns({
                indexOrder: state.indexOrder,
                handleIndexOrder,
                handleDeleteModalVisible,
                showModifyModal,
              })
            )}
            paginatorNeeded
            data={getAllPoetryChat.data?.data}
            loading={getAllPoetryChat.isLoading}
            page={getAllPoetryChat.data?.page}
            pageSize={getAllPoetryChat.data?.pageSize}
            allPage={getAllPoetryChat.data?.count}
            handleSetPage={handleSetPage}
            handleSetPageSize={handleSetPageSize}
          />
        </section>
      </div>
      <section>
        {state.creationModalVisible && (
          <PoetryChatModal
            poetryId={parseInt(params.poetryId!)}
            poetryChat={state.selectedPoetryChat}
            handleMutationModalVisible={handleCreationModalClose}
            latestQueryKey={[
              QueryKeys.poetryChatQuery,
              {
                poetryId: state.poetryId,
                page: state.page,
                pageSize: state.pageSize,
                searchTerm: state.searchTerm,
                type: state.type,
                indexOrder: state.indexOrder,
              },
            ]}
          />
        )}
      </section>
      <section>
        {state.deleteModalVisible && (
          <PoetryChatDeleteModal
            handleDeleteModalVisible={handleDeleteModalVisible}
          />
        )}
      </section>
      <section>
        {getAllPoetryChat.isError && (
          <ErrorModal
            onClose={handleCloseErrorModal}
            title={t("poetry-chat-page-error-modal-title")}
            error={getAllPoetryChat.error}
          />
        )}
      </section>
    </main>
  );
}
