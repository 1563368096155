import axios from "axios";
import { GetAllParentDTO } from "../../dto";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";

type GetAllAdminProps = {
  page: number;
  pageSize: number;
  searchTerm?: string;
  nameOrder?: "ASC" | "DESC" | "";
  nicknameOrder?: "ASC" | "DESC" | "";
  emailOrder?: "ASC" | "DESC" | "";
  createdOrder?: "ASC" | "DESC" | "";
  updatedOrder?: "ASC" | "DESC" | "";
};

let body: GetAllParentDTO;

export async function getAllParentAction(props: GetAllAdminProps) {
  const token = localStorage.getItem("token");

  const params = {
    page: props.page,
    pageSize: props.pageSize,
    searchTerm: props.searchTerm,
    nameOrder: props.nameOrder !== "" ? props.nameOrder : undefined,
    nicknameOrder: props.nicknameOrder !== "" ? props.nicknameOrder : undefined,
    emailOrder: props.emailOrder !== "" ? props.emailOrder : undefined,
    createdOrder: props.createdOrder !== "" ? props.createdOrder : undefined,
    updatedOrder: props.updatedOrder !== "" ? props.updatedOrder : undefined,
  };

  return await axios
    .get(`${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/parents/get-all`, {
      headers: {
        "Content-Type": "application/json",
        "app-platform": "cms",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then(async (res) => {
      body = plainToClass(GetAllParentDTO, res.data);
      const errors = await validate(body);

      if (errors.length > 0) {
        throw errors;
      }

      return res.data;
    });
}
