import { useAppNavigate } from "../../../common";
import {
  CloseIcon,
  DeleteIcon,
  DetailsIcon,
  ModifyIcon,
  SortIcon,
} from "../../../components";
import { LinesOfPoetriesEntity } from "../../../entity";

type LineOfPoetryColumnProps = {
  handleIndexOrder: (value: string) => void;
  indexOrder: "ASC" | "DESC" | "";
  handleDeleteModalVisible: () => void;
  handleDeleteAudioModalVisible: () => void;
  handleMutateModalVisible: (value: boolean) => void;
};

export function LineOfPoetryColumns(props: LineOfPoetryColumnProps) {
  const navigate = useAppNavigate();

  const handleOpenDeleteModal = (id: number) => {
    props.handleDeleteModalVisible();
    navigate("", { state: { id } });
  };

  const handleOpenModifyModal = (line: LinesOfPoetriesEntity) => {
    props.handleMutateModalVisible(true);
    navigate("", { state: { line } });
  };

  const handleOpenDeleteAudioModal = (id: number) => {
    props.handleDeleteAudioModalVisible();
    navigate("", { state: { audioId: id } });
  };

  return [
    {
      title: "line-of-poetry-page-table-header-index",
      dataIndex: "index",
      suffix: (
        <SortIcon
          order={props.indexOrder}
          orderFunction={props.handleIndexOrder}
        />
      ),
    },
    {
      title: "line-of-poetry-page-table-header-young-audio",
      dataIndex: "audioYoung",
      render: (record: LinesOfPoetriesEntity) => {
        return record?.audioYoungUrl ? (
          <audio controls>
            <source src={record?.audioYoungUrl} type="audio/mpeg" />
          </audio>
        ) : (
          <>{""}</>
        );
      },
    },
    {
      title: "line-of-poetry-page-table-header-old-audio",
      dataIndex: "audioOld",
      render: (record: LinesOfPoetriesEntity) => {
        return record?.audioOldUrl ? (
          <audio controls>
            <source src={record?.audioOldUrl} type="audio/mpeg" />
          </audio>
        ) : (
          <>{""}</>
        );
      },
    },
    {
      title: "line-of-poetry-page-table-header-audio-delete",
      dataIndex: "deleteAudio",
      render: (record: LinesOfPoetriesEntity) => {
        return (
          <CloseIcon onClick={() => handleOpenDeleteAudioModal(record?.id)} />
        );
      },
    },
    {
      title: "line-of-poetry-page-table-header-stanza",
      dataIndex: "stanza",
    },
    {
      title: "line-of-poetry-page-table-header-text",
      dataIndex: "text",
    },
    {
      title: "line-of-poetry-page-table-header-unit",
      dataIndex: "unit",
    },
    {
      title: "line-of-poetry-page-table-header-created",
      dataIndex: "created",
      render: (record: LinesOfPoetriesEntity) => {
        return <span>{new Date(record?.created!).toLocaleDateString()}</span>;
      },
    },
    {
      title: "line-of-poetry-page-table-header-updated",
      dataIndex: "updated",

      render: (record: LinesOfPoetriesEntity) => {
        return <span>{new Date(record?.updated!).toLocaleDateString()}</span>;
      },
    },
    {
      title: "line-of-poetry-page-table-header-info",
      dataIndex: "info",
      render: (record: LinesOfPoetriesEntity) => {
        return (
          <DetailsIcon
            onClick={() => navigate(`${record?.id}/${encodeURIComponent(record?.text)}/info`)}
          />
        );
      },
    },
    {
      title: "line-of-poetry-page-table-header-disappearing",
      dataIndex: "disappearing",
      render: (record: LinesOfPoetriesEntity) => {
        return (
          <DetailsIcon
            onClick={() =>
              navigate(`${record?.id}/${encodeURIComponent(record?.text)}/disappearing`)
            }
          />
        );
      },
    },

    {
      title: "line-of-poetry-page-table-header-modify",
      dataIndex: "modify",
      render: (record: LinesOfPoetriesEntity) => {
        return <ModifyIcon onClick={() => handleOpenModifyModal(record)} />;
      },
    },
    {
      title: "line-of-poetry-page-table-header-delete",
      dataIndex: "delete",
      render: (record: LinesOfPoetriesEntity) => {
        return <DeleteIcon onClick={() => handleOpenDeleteModal(record?.id)} />;
      },
    },
  ];
}
