import { useQuery } from "@tanstack/react-query";
import {
  QueryKeys,
  useAppNavigate,
  useAppParams,
  useAppState,
  useAppTranslation,
  useDebounce,
} from "../../common";
import {
  Button,
  ErrorModal,
  Input,
  PaginatorIcon,
  Table,
} from "../../components";
import { getAllInlineInfoAction } from "../../actions";
import {
  InlineInfoColumns,
  InlineInfoDeleteModal,
  InlineInfoMutationForm,
} from "./components";
import "./inline-info.page.scss";

export function InlineInfoPage() {
  const params = useAppParams();
  const navigate = useAppNavigate();
  const { t } = useAppTranslation();

  const [state, setState] = useAppState({
    page: 1,
    pageSize: 10,
    search: "",
    mutateModalVisible: false,
    errorModalVisible: false,
    deleteModalVisible: false,
    modify: false,
    searchTerm: "",
  });

  const getAllInfo = useQuery({
    queryKey: [
      QueryKeys.inlineInfos,
      {
        lineId: parseInt(params.lineId!),
        page: state.page,
        pageSize: state.pageSize,
        searchTerm: state.searchTerm,
      },
    ],
    queryFn: () =>
      getAllInlineInfoAction({
        lineId: parseInt(params.lineId!),
        page: state.page,
        pageSize: state.pageSize,
        searchTerm: state.searchTerm,
      }),
  });

  const handleSearch = useDebounce((searchTerm: string) => {
    setState((prevState) => ({ ...prevState, searchTerm: searchTerm }));
  }, 250);

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, search: e.target.value }));

    handleSearch(e.target.value);
  };

  const handleMutateModalVisible = (modify: boolean) => {
    navigate("", { state: undefined });
    setState((prevState) => ({
      ...prevState,
      mutateModalVisible: !prevState.mutateModalVisible,
      modify,
    }));
  };

  const handleDeleteModalVisible = () => {
    setState((prevState) => ({
      ...prevState,
      deleteModalVisible: !prevState.deleteModalVisible,
    }));
  };

  const handleSetPage = (value: number) => {
    setState((prevState) => ({ ...prevState, page: value }));
  };

  const handleSetPageSize = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setState((prevState) => ({
      ...prevState,
      pageSize: parseInt(e.target.value),
    }));
  };

  const handleOpenErrorModal = () => {
    setState((prevState) => ({ ...prevState, errorModalVisible: true }));
  };

  const handleCloseErrorModal = () => {
    getAllInfo.refetch();
  };

  return (
    <main className="page">
      <div className="inline-info-page">
        <div className="line-of-poetry-subpages-breadcrumb">
          <PaginatorIcon
            type="previous"
            onClick={() =>
              navigate(
                `/cms/poetry/${params.poetryId}/${params.poetryTitle}/lines`
              )
            }
            active
          />
          <span className="poetry-subpage-breadcrumb-title">
            {params.poetryTitle}
          </span>
          <span>-</span>
          <span className="subtitle">{t("inline-info-page-subtitle")}</span>
          <span>-</span>
          <span className="line-text">{params.line}</span>
        </div>

        <section className="search-bar-container">
          <Input
            onChange={handleSearchTermChange}
            value={state.search}
            placeholder={t("inline-info-page-search-bar-placeholder")}
            type="search"
            className="searchbar"
          />
          <Button
            onClick={() => handleMutateModalVisible(false)}
            title="+"
            htmlType="button"
            creationButton
          />
        </section>
        <section>
          <Table
            columns={Object.values(
              InlineInfoColumns({
                handleDeleteModalVisible,
                handleOpenErrorModal,
                handleMutateModalVisible,
              })
            )}
            paginatorNeeded
            data={getAllInfo.data?.data}
            loading={getAllInfo.isLoading}
            page={getAllInfo.data?.page}
            pageSize={getAllInfo.data?.pageSize}
            allPage={getAllInfo.data?.count}
            handleSetPage={handleSetPage}
            handleSetPageSize={handleSetPageSize}
          />
        </section>
      </div>
      <section>
        {state.mutateModalVisible && (
          <InlineInfoMutationForm
            modify={state.modify}
            handleMutationModalVisible={handleMutateModalVisible}
            latestQueryKey={[
              QueryKeys.inlineInfos,
              {
                lineId: parseInt(params.lineId!),
                page: state.page,
                pageSize: state.pageSize,
                searchTerm: state.searchTerm,
              },
            ]}
          />
        )}
      </section>
      <section>
        {state.deleteModalVisible && (
          <InlineInfoDeleteModal
            handleDeleteModalVisible={handleDeleteModalVisible}
            handleSetPage={handleSetPage}
          />
        )}
      </section>
      <section>
        {getAllInfo.isError && (
          <ErrorModal
            onClose={handleCloseErrorModal}
            title={t("inline-info-page-error-modal-get-all-title")}
            error={getAllInfo.error}
          />
        )}
      </section>
    </main>
  );
}
