import { useQuery } from "@tanstack/react-query";
import {
  PoetrySubPages,
  QueryKeys,
  useAppNavigate,
  useAppParams,
  useAppState,
  useAppTranslation,
  useDebounce,
} from "../../common";
import { Button, ErrorModal, Input, Table } from "../../components";
import { FactDeleteModal, FactMutationForm, FactsColumns } from "./components";
import { getAllFactAction } from "../../actions";
import "./fact.page.scss";

export function FactPage() {
  const navigate = useAppNavigate();
  const { t } = useAppTranslation();
  const params = useAppParams();
  const [state, setState] = useAppState({
    page: 1,
    pageSize: 10,
    search: "",
    mutateModalVisible: false,
    deleteModalVisible: false,
    modify: false,
    searchTerm: "",
  });

  const getAllFact = useQuery({
    queryKey: [
      QueryKeys.factsQuery,
      {
        poetryId: parseInt(params.poetryId!),
        page: state.page,
        pageSize: state.pageSize,
        searchTerm: state.searchTerm,
      },
    ],
    queryFn: () =>
      getAllFactAction({
        poetryId: parseInt(params.poetryId!),
        page: state.page,
        pageSize: state.pageSize,
        searchTerm: state.searchTerm,
      }),
  });

  const handleSearch = useDebounce((searchTerm: string) => {
    setState((prevState) => ({ ...prevState, searchTerm: searchTerm }));
  }, 250);

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, search: e.target.value }));

    handleSearch(e.target.value);
  };

  const handleMutateModalVisible = (modify: boolean) => {
    navigate("", { state: undefined });
    setState((prevState) => ({
      ...prevState,
      mutateModalVisible: !prevState.mutateModalVisible,
      modify,
    }));
  };

  const handleDeleteModalVisible = () => {
    setState((prevState) => ({
      ...prevState,
      deleteModalVisible: !prevState.deleteModalVisible,
    }));
  };

  const handleSetPage = (value: number) => {
    setState((prevState) => ({ ...prevState, page: value }));
  };

  const handleSetPageSize = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setState((prevState) => ({
      ...prevState,
      pageSize: parseInt(e.target.value),
    }));
  };

  const handleOpenErrorModal = () => {
    setState((prevState) => ({ ...prevState, errorModalVisible: true }));
  };

  const handleCloseErrorModal = () => {
    getAllFact.refetch();
  };

  return (
    <main className="page">
      <div className="poetry-facts-page">
        <PoetrySubPages />
        <section className="search-bar-container">
          <Input
            onChange={handleSearchTermChange}
            value={state.search}
            placeholder={t("facts-page-search-bar-placeholder")}
            type="search"
            className="searchbar"
          />
          <Button
            onClick={() => handleMutateModalVisible(false)}
            title="+"
            htmlType="button"
            creationButton
          />
        </section>
        <section>
          <Table
            columns={Object.values(
              FactsColumns({
                handleDeleteModalVisible,
                handleOpenErrorModal,
                handleMutateModalVisible,
              })
            )}
            paginatorNeeded
            data={getAllFact.data?.data}
            loading={getAllFact.isLoading}
            page={getAllFact.data?.page}
            pageSize={getAllFact.data?.pageSize}
            allPage={getAllFact.data?.count}
            handleSetPage={handleSetPage}
            handleSetPageSize={handleSetPageSize}
          />
        </section>
      </div>
      <section>
        {state.mutateModalVisible && (
          <FactMutationForm
            modify={state.modify}
            handleMutationModalVisible={handleMutateModalVisible}
            latestQueryKey={[
              QueryKeys.factsQuery,
              {
                poetryId: parseInt(params.poetryId!),
                page: state.page,
                pageSize: state.pageSize,
                searchTerm: state.searchTerm,
              },
            ]}
          />
        )}
      </section>
      <section>
        {state.deleteModalVisible && (
          <FactDeleteModal
            handleDeleteModalVisible={handleDeleteModalVisible}
          />
        )}
      </section>
      <section>
        {getAllFact.isError && (
          <ErrorModal
            onClose={handleCloseErrorModal}
            title={t("fact-page-error-modal-get-all-title")}
            error={getAllFact.error}
          />
        )}
      </section>
    </main>
  );
}
