export const QueryKeys = {
  userQuery: "user",
  adminQuery: "admins",
  mutateAdminQuery: "mutateAdmin",
  forgotPasswordConfirmQuery: "forgotPassword",
  getMeQuery: "getMe",
  verifyQuery: "verify",
  profileMutationQuery: "profile",
  loginMutationQuery: "login",
  logoutMutationQuery: "logout",
  fisrtLoginMutationQuery: "fisrtLogin",
  parentsQuery: "parents",
  deleteAdminMutatuionQuery: "deleteAdmin",
  deleteParentMutatuionQuery: "deleteParent",
  childrenQuery: "children",
  parentQuery: "parent",
  deleteChildMutation: "deleteChild",
  poetryQuery: "poetry",
  mutatePoetryQuery: "mutatePoetry",
  deletePoetryMutatuionQuery: "deletePoetry",
  poetQuery: "poets",
  activatePoetQuery: "activatePoet",
  createPoetQuery: "createPoet",
  modifyPoetQuery: "modifyPoet",
  deletePoetQuery: "deletePoet",
  categoryQuery: "category",
  modifyCategoryQuery: "modifyCategory",
  createCategoryQuery: "createCategory",
  deleteCategoryQuery: "deleteCategory",
  achievementQuery: "achievements",
  modifyAchievementQuery: "modifyAchievement",
  createAchievementQuery: "createAchievement",
  deleteAchievementQuery: "deleteAchievement",
  tierQuery: "tiers",
  modifyTierQuery: "modifyTier",
  createTierQuery: "createTier",
  deleteTierQuery: "deleteTier",
  versionQuery: "versions",
  modifyVersionQuery: "modifyVersion",
  createVersionQuery: "createVersion",
  deleteVersionQuery: "deleteVersion",
  factsQuery: "facts",
  modifyFactQuery: "modifyFact",
  createFactQuery: "createFact",
  deleteFactQuery: "deleteFact",
  poetryChatQuery: "poetryChat",
  modifyPoetryChatQuery: "modifyPoetryChat",
  createPoetryChatQuery: "createPoetryChat",
  deletePoetryChatMutationQuery: "deletePoetryChat",
  poetryQuizQuery: "poetryQuiz",
  modifyPoetryQuizQuery: "modifyPoetryQuiz",
  createPoetryQuizQuery: "createPoetryQuiz",
  deletePoetryQuizMutationQuery: "deletePoetryQuiz",
  linesOfPoetryQuery: "linesOfPoetry",
  modifyLineOfPoetry: "modifyLineOfPoetry",
  createLineOfPoetry: "createLineOfPoetry",
  deleteAudioOfLineOfPoetry: "deleteAudioOfLineOfPoetry",
  deleteLineOfPoetry: "deleteLineOfPoetry",
  modifyLineOfPoetryIndex: "modifyLineOfPoetryIndex",
  inlineInfos: "inlineInfos",
  createInlineInfo: "createIlineInfo",
  modifyInlineInfo: "modifyIlineInfo",
  deleteInlineInfo: "deleteIlineInfo",
  disappearingGames: "disappearingGames",
  createDisappearingGame: "createDisappearingGame",
  modifyDisappearingGame: "modifyDisappearingGame",
  modifyCorrectDisappearingGame: "modifyCorrectDisappearingGame",
  deleteDisappearingGame: "deleteDisappearingGame",
  validateToken: "validateToken",
  resetPassword: "resetPassword",
  activatePoetryQuery: "activatePoetry",
  highlightPoetryQuery: "highlightPoetry",
  createQuizInfoQuery: "createQuizInfo",
  modifyQuizInfoQuery: "modifyQuizInfo",
  deleteQuizInfoQuery: "deleteQuizInfo",
  createQuizAnswerQuery: "createQuizAnswer",
  modifyQuizAnswerQuery: "modifyQuizAnswer",
  mutateQuizCorrectAnswerQuery: "mutateQuizCorrectAnswer",
  deleteQuizAnswerQuery: "deleteQuizAnswer",
  getSettingQuery: "getSetting",
  mutateSettingQuery: "mutateSetting",
  mutateParentQuery: "mutateParent",
  mutateChildQuery: "mutateChild"
};
