import "./paginator.icon.scss";

type PaginatorIconProps = {
  onClick: () => void;
  type: "next" | "previous";
  active: boolean;
};

export function PaginatorIcon(props: PaginatorIconProps) {
  return (
    <div
      className={`paginator-icon ${props.type} ${
        props.active ? "active" : "inactive"
      }`}
      onClick={props.onClick}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="arrow-left">
          <path
            id="Icon"
            d="M10 19L3 12M3 12L10 5M3 12L21 12"
            stroke={props.active ? "#545F71" : "#D9D9D9"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </div>
  );
}
