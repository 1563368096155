import { Form, Input, Modal } from "../../../components";
import { useAppState } from "../../../common";
import { FormEvent } from "react";

interface InputModalProps {
  title: string;
  cancelText: string;
  submitText: string;
  text?: string;
  placeholder: string;

  save: (text: string) => void;
  close: () => void;
}

export const InputModal = (props: InputModalProps) => {
  const [state, setState] = useAppState({
    text: props.text ?? ``,
  });

  const submit = (e: FormEvent) => {
    e.preventDefault();
    props.save(state.text);
  };

  return (
    <Modal
      onClose={props.close}
      title={props.title}
      footer={true}
      submitText={props.submitText}
      cancelText={props.cancelText}
      handleSubmit={submit}
    >
      <div className="input-modal">
        <Form onSubmit={submit}>
          <Input
            onChange={(e) => setState({ ...state, text: e.target.value })}
            value={state.text}
            placeholder={props.placeholder}
            className="input-modal-text"
            type="text"
          />
        </Form>
      </div>
    </Modal>
  );
};
