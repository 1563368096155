import { Expose, Type } from "class-transformer";
import { IsArray, IsNumber, ValidateNested } from "class-validator";
import { TierEntity } from "../../entity";

export class GetAllTierDTO {
  @Expose()
  @ValidateNested()
  @IsArray()
  @Type(() => TierEntity)
  data!: TierEntity[];

  @Expose()
  @IsNumber()
  count!: number;

  @Expose()
  @IsNumber()
  page!: number;

  @Expose()
  @IsNumber()
  pageSize!: number;
}
