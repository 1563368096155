import { useQuery, useMutation } from "@tanstack/react-query";
import {
  QueryKeys,
  passwordValidator,
  useAppNavigate,
  useAppState,
  useAppTranslation,
} from "../../common";
import { resetPasswordAction, validateTokenAction } from "../../actions";
import { Button, Form, Input, Tooltip } from "../../components";
import "./forgot-password-confirm.scss";

export function ForgotPasswordConfirmPage() {
  const { t } = useAppTranslation();
  const navigate = useAppNavigate();
  const getQueryParam = new URLSearchParams(window.location.search);
  const type = getQueryParam.get("type");

  const [state, setState] = useAppState({
    password: "",
    passwordCheck: "",
  });

  const validateToken = useQuery({
    queryKey: [QueryKeys.validateToken],
    queryFn: validateTokenAction,
    retry: 0,
    refetchOnMount: false,
  });

  const resetPassword = useMutation({
    mutationKey: [QueryKeys.resetPassword],
    mutationFn: resetPasswordAction,
    onSuccess() {
      if (type === "cms") {
        navigate("/");
      } else {
        navigate("/success");
      }
    },
  });

  const handlePasswordCheckChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setState((prevState) => ({ ...prevState, passwordCheck: e.target.value }));
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, password: e.target.value }));
  };

  const handleResetPassword = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    resetPassword.mutate({
      password: state.password,
    });
  };

  const isPasswordValid = (pwd: string) => {
    return passwordValidator({ password: pwd });
  };

  const isButtonDisabled =
    type === "cms"
      ? state.password !== state.passwordCheck ||
        !isPasswordValid(state.password) ||
        !isPasswordValid(state.passwordCheck)
      : state.password !== state.passwordCheck;

  return (
    <main>
      {!validateToken.isLoading &&
      (validateToken.isError || resetPassword.isError) ? (
        <span className="error-title">
          {t("forgot-password-confirm-token-expired-invalid-error-title")}
        </span>
      ) : (
        <div>
          <section className="forgot-password-confirm-title">
            <span>{t("forgot-password-confirm-page-title")}</span>
          </section>
          <section className="login-form">
            <Form onSubmit={handleResetPassword}>
              <Tooltip
                content={
                  state.password.length > 0 &&
                  !isPasswordValid(state.password) &&
                  type === "cms"
                    ? t(
                        "forgot-password-confirm-page-password-input-error-text"
                      )
                    : ""
                }
              >
                <Input
                  type="password"
                  className={`login-input ${
                    state.password.length > 0 &&
                    type === "cms" &&
                    !isPasswordValid(state.password)
                      ? "error"
                      : ""
                  }`}
                  value={state.password}
                  onChange={handlePasswordChange}
                  placeholder={t(
                    "forgot-password-confirm-page-password-input-placeholder"
                  )}
                />
              </Tooltip>
              <Tooltip
                content={
                  state.passwordCheck.length > 0 &&
                  (!isPasswordValid(state.passwordCheck) ||
                    state.passwordCheck !== state.password) &&
                  type === "cms"
                    ? t(
                        "forgot-password-confirm-page-password-check-input-error-text"
                      )
                    : state.passwordCheck !== state.password
                    ? t("forgot-password-confirm-page-passwords-must-match")
                    : ""
                }
              >
                <Input
                  type="password"
                  className={`login-input ${
                    state.passwordCheck.length > 0 &&
                    (!isPasswordValid(state.passwordCheck) ||
                      state.passwordCheck !== state.password) &&
                    type === "cms"
                      ? "error"
                      : state.passwordCheck !== state.password
                      ? "error"
                      : ""
                  }`}
                  value={state.passwordCheck}
                  onChange={handlePasswordCheckChange}
                  placeholder={t(
                    "forgot-password-confirm-page-password-check-input-placeholder"
                  )}
                />
              </Tooltip>

              <div className="button-container">
                <Button
                  htmlType="submit"
                  title={t("forgot-password-confirm-page-button-title")}
                  loading={resetPassword.isPending}
                  disabled={isButtonDisabled || resetPassword.isPending}
                />
              </div>
            </Form>
          </section>
        </div>
      )}
    </main>
  );
}
