import axios from "axios";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { MutateTierDTO } from "../../dto";

type CreateTierProps = {
  picture: File;
  name: string;
  practiceNeeded: string;
};

let body: MutateTierDTO;

export async function createTierAction(props: CreateTierProps) {
  const token = localStorage.getItem("token");

  const params = new FormData();
  params.append("picture", props.picture);
  params.append("name", props.name);
  params.append("practiceNeeded", props.practiceNeeded);

  return await axios
    .post(
      `${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/tiers/create`,
      params,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "app-platform": "cms",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(async (res) => {
      body = plainToClass(MutateTierDTO, res.data);
      await validate(body);
      const errors = await validate(body);
      if (errors.length > 0) {
        throw errors;
      }

      return res.data;
    });
}
