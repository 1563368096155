import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  PageType,
  QueryKeys,
  mutationHandler,
  useAppLocation,
  useAppState,
  useAppTranslation,
} from "../../../common";
import {
  ErrorModal,
  Form,
  Input,
  Modal,
  ValidationErrorModal,
} from "../../../components";
import { Selector } from "../../../components/selector/selector";
import { createVersionAction, modifyVersionAction } from "../../../actions";
import { criticalFilterOptions, platformFilterOptions } from "../static";
import { VersionEntity } from "../../../entity";

type VersionMutationFormProps = {
  modify: boolean;
  handleMutationModalVisible: (value: boolean) => void;
  latestQueryKey: any;
};

export function VersionMutationForm(props: VersionMutationFormProps) {
  const queryClient = useQueryClient();
  const location = useAppLocation();
  const { t } = useAppTranslation();
  const [state, setState] = useAppState({
    version: location.state?.version.version ?? "",
    platform:
      location.state?.version.platform === "android" ? "android" : "ios",
    critical: location.state?.version.critical ? "Kritikus" : "Nem kritikus",
    validationErrorMessage: null as string | null,
  });

  const mutateVersion = useMutation({
    mutationKey: [
      props.modify
        ? QueryKeys.modifyVersionQuery
        : QueryKeys.createVersionQuery,
    ],
    mutationFn: (data: any) =>
      props.modify ? modifyVersionAction(data) : createVersionAction(data),
    onMutate: async (newVersion) => {
      await queryClient.cancelQueries({
        queryKey: props.latestQueryKey,
      });
      const previousVersions = queryClient.getQueryData([props.latestQueryKey]);

      queryClient.setQueryData(
        props.latestQueryKey,
        (old: PageType<VersionEntity>) => {
          const handler = mutationHandler({
            dataLength: old.data.length,
            pageSize: old.pageSize,
            count: old.count,
            oldData: old.data,
            newData: newVersion,
          });

          return {
            page: old.page,
            pageSize: old.pageSize,
            count: handler.newCount,
            data: handler.newData,
          };
        }
      );

      return { previousVersions };
    },
    onSuccess() {
      props.handleMutationModalVisible(false);
    },
    onError(error, variables, context) {
      queryClient.setQueryData(
        [props.latestQueryKey],
        context?.previousVersions
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.versionQuery],
      });
    },
  });

  const handleCloseErrorModal = () => {
    mutateVersion.reset();
  };

  const handleVersionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, version: e.target.value }));
  };

  const handlePlatformChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setState((prevState) => ({ ...prevState, platform: e.target.value }));
  };

  const handleCriticalChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setState((prevState) => ({
      ...prevState,
      critical: e.target.value,
    }));
  };

  const checkForm = () => {
    if (state.version.trim().length === 0) {
      setState({
        ...state,
        validationErrorMessage: `A verzió kitöltése kötelező!`,
      });
      return false;
    }
    if (state.platform.trim && state.platform.trim().length === 0) {
      setState({
        ...state,
        validationErrorMessage: `A platform kiválasztása kötelező!`,
      });
      return false;
    }
    if (state.critical.trim && state.critical.trim().length === 0) {
      setState({
        ...state,
        validationErrorMessage: `A kritikusság kiválasztása kötelező!`,
      });
      return false;
    }

    return true;
  };

  const handleMutateVersion = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!checkForm()) {
      return;
    }
    mutateVersion.mutate({
      version: state.version,
      platform: state.platform,
      critical: state.critical,
      id: props.modify ? location.state?.version.id : undefined!,
    });
  };

  return (
    <div>
      <section>
        <Modal
          onClose={() => props.handleMutationModalVisible(false)}
          title={
            props.modify
              ? t("version-page-mutate-version-modal-modify-title")
              : t("version-page-mutate-version-modal-create-title")
          }
          footer
          cancelText={t("version-page-mutate-version-modal-cancel-label")}
          submitText={
            props.modify
              ? t("version-page-mutate-version-modal-modify-submit-label")
              : t("version-page-mutate-version-modal-create-submit-label")
          }
          handleSubmit={handleMutateVersion}
          loading={mutateVersion.isPending}
        >
          <Form onSubmit={handleMutateVersion}>
            <div className="version-form">
              <Input
                type="text"
                maxLength={255}
                className="mutate-modal-input"
                onChange={handleVersionChange}
                value={state.version}
                placeholder={t(
                  "version-page-mutate-version-modal-version-placeholder"
                )}
              />
              <Selector
                name="platform"
                id="platform"
                handleSelectOption={handlePlatformChange}
                options={platformFilterOptions}
                onlyLabel
              />
              <Selector
                name="critical"
                id="critical"
                handleSelectOption={handleCriticalChange}
                options={criticalFilterOptions}
                onlyLabel
              />
            </div>
          </Form>
        </Modal>
      </section>
      <section>
        {mutateVersion.isError && (
          <ErrorModal
            error={mutateVersion.error}
            onClose={handleCloseErrorModal}
            title={
              props.modify
                ? t("version-page-mutate-version-form-error-modal-modify-title")
                : t("version-page-mutate-version-form-error-modal-create-title")
            }
          />
        )}
      </section>
      <section>
        {state.validationErrorMessage && (
          <ValidationErrorModal
            error={state.validationErrorMessage}
            onClose={() =>
              setState({
                ...state,
                validationErrorMessage: null,
              })
            }
          />
        )}
      </section>
    </div>
  );
}
