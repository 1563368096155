import { removeHTMLTags, useAppNavigate } from "../../../common";
import { DeleteIcon, ModifyIcon } from "../../../components";
import { InlineInfoEntity } from "../../../entity";

type InlineInfoColumnProps = {
  handleDeleteModalVisible: () => void;
  handleMutateModalVisible: (value: boolean) => void;
  handleOpenErrorModal: () => void;
};

export function InlineInfoColumns(props: InlineInfoColumnProps) {
  const navigate = useAppNavigate();

  const handleOpenDeleteModal = (id: number) => {
    props.handleDeleteModalVisible();
    navigate("", { state: { id } });
  };

  const handleOpenModifyModal = (info: InlineInfoEntity) => {
    props.handleMutateModalVisible(true);
    navigate("", { state: { info } });
  };

  return [
    {
      title: "inline-info-page-table-header-word",
      dataIndex: "name",
    },
    {
      title: "inline-info-page-table-header-text",
      dataIndex: "text",
      render: (record: InlineInfoEntity) => {
        let correctText = record?.text;
        if (record?.text.length > 100) {
          correctText = record?.text.substring(0, 100) + "...";
        }
        return <span>{removeHTMLTags(correctText)}</span>;
      },
    },
    {
      title: "inline-info-page-table-header-created",
      dataIndex: "created",
      render: (record: InlineInfoEntity) => {
        return <span>{new Date(record?.created!).toLocaleDateString()}</span>;
      },
    },
    {
      title: "inline-info-page-table-header-updated",
      dataIndex: "updated",

      render: (record: InlineInfoEntity) => {
        return <span>{new Date(record?.updated!).toLocaleDateString()}</span>;
      },
    },
    {
      title: "inline-info-page-table-header-modify",
      dataIndex: "modify",
      render: (record: InlineInfoEntity) => {
        return <ModifyIcon onClick={() => handleOpenModifyModal(record)} />;
      },
    },
    {
      title: "inline-info-page-table-header-delete",
      dataIndex: "delete",
      render: (record: InlineInfoEntity) => {
        return <DeleteIcon onClick={() => handleOpenDeleteModal(record?.id)} />;
      },
    },
  ];
}
