import { useQuery } from "@tanstack/react-query";
import {
  QueryKeys,
  useAppNavigate,
  useAppParams,
  useAppState,
  useAppTranslation,
  useDebounce,
} from "../../common";
import { getParentAction, getParentChildrenAction } from "../../actions";
import { ErrorModal, Input, PaginatorIcon, Table } from "../../components";

import "./child.page.scss";
import {
    ChildAchievementModal, ChildColumns, ChildDeleteModal, ChildEditModal
} from "./components"
import { AchievementEntity, ChildEntity } from "../../entity"

export function ChildPage() {
  const { t } = useAppTranslation();
  const params = useAppParams();
  const navigate = useAppNavigate();
  const [state, setState] = useAppState({
    page: 1,
    pageSize: 10,
    nickname: "",
    gender: "" as "1" | "2" | "",
    sleepTime: "" as "0" | "1" | "2" | "",
    classOrder: "" as "ASC" | "DESC" | "",
    nicknameOrder: "" as "ASC" | "DESC" | "",
    latestActivityOrder: "" as "ASC" | "DESC" | "",
    editModalVisible: false,
    deleteModalVisible: false,
    achievementModalVisible: false,
    achievements: [] as AchievementEntity[],
    childName: "",
    searchTerm: "",
    currentChild: null as ChildEntity | null
  });

  const getParent = useQuery({
    queryKey: [QueryKeys.parentQuery],
    queryFn: () => getParentAction({ id: params.parentId! }),
  });

  const getParentChildren = useQuery({
    queryKey: [
      QueryKeys.childrenQuery,
      {
        parentId: parseInt(params.parentId!),
        page: state.page,
        pageSize: state.pageSize,
        nickname: state.searchTerm,
        gender: state.gender,
        sleepTime: state.sleepTime,
        classOrder: state.classOrder,
        nicknameOrder: state.nicknameOrder,
        latestActivityOrder: state.latestActivityOrder,
      },
    ],
    queryFn: () =>
      getParentChildrenAction({
        parentId: parseInt(params.parentId!),
        page: state.page,
        pageSize: state.pageSize,
        nickname: state.searchTerm,
        gender: state.gender,
        sleepTime: state.sleepTime,
        classOrder: state.classOrder,
        nicknameOrder: state.nicknameOrder,
        latestActivityOrder: state.latestActivityOrder,
      }),
    refetchOnReconnect: "always",
    refetchOnWindowFocus: "always",
  });

  const handleSearch = useDebounce((searchTerm: string) => {
    setState((prevState) => ({ ...prevState, searchTerm: searchTerm }));
  }, 250);

  const handleNicknameSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, nickname: e.target.value }));

    handleSearch(e.target.value);
  };

  const handleSetPage = (value: number) => {
    setState((prevState) => ({ ...prevState, page: value }));
  };

  const handleSetPageSize = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setState((prevState) => ({
      ...prevState,
      pageSize: parseInt(e.target.value),
    }));
  };

  const handleClassOrder = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      classOrder: value as "ASC" | "DESC" | "",
    }));
  };

  const handleNicknameOrder = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      nicknameOrder: value as "ASC" | "DESC" | "",
    }));
  };

  const handleGenderChange = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      gender: value as "1" | "2" | "",
    }));
  };

  const handleSleepTimeChange = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      sleepTime: value as "0" | "1" | "2" | "",
    }));
  };

  const handleActivityOrder = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      latestActivityOrder: value as "ASC" | "DESC" | "",
    }));
  };

    const handleEditModalVisible = (id?: number) => {
        if (id !== undefined) {
            setState((prevState) => ({
                ...prevState,
                currentChild: getParentChildren.data?.data.find((c: ChildEntity) => c.id === id)
            }))
        }
        setState((prevState) => ({
            ...prevState,
            editModalVisible: !prevState.editModalVisible,
        }));
    };

  const handleDeleteModalVisible = () => {
    setState((prevState) => ({
      ...prevState,
      deleteModalVisible: !prevState.deleteModalVisible,
    }));
  };

  const handleAchievementModalVisible = (
    achievements: AchievementEntity[],
    childName: string
  ) => {
    setState((prevState) => ({
      ...prevState,
      achievementModalVisible: !prevState.achievementModalVisible,
      achievements,
      childName,
    }));
  };

  const handleCloseErrorModal = () => {
    if (getParentChildren.isError) {
      getParentChildren.refetch();
    } else {
      getParent.refetch();
    }
  };

  return ( <main className="page">
          <div className="child-page">
              <section className="title">
                  <PaginatorIcon type="previous" onClick={ () => navigate( "/cms/parents" ) } active/>
                  <span className="parent-name">
            { !getParent.isLoading ? getParent.data.parent?.name : "" }
          </span>
              </section>
              <section className="search-bar-container">
                  <Input onChange={ handleNicknameSearch } value={ state.nickname } placeholder={ t( "child-page-search-bar-placeholder" ) } type="search" className="searchbar"/>
              </section>
              <section>
                  <Table columns={ Object.values( ChildColumns( {
                      classOrder: state.classOrder,
                      nicknameOrder: state.nicknameOrder,
                      gender: state.gender,
                      sleepTime: state.sleepTime,
                      latestActivityOrder: state.latestActivityOrder,
                      handleClassOrder,
                      handleNicknameOrder,
                      handleGenderChange,
                      handleSleepTimeChange,
                      handleActivityOrder,
                      handleEditModalVisible,
                      handleDeleteModalVisible,
                      handleAchievementModalVisible,
                  } ) ) } paginatorNeeded data={ getParentChildren.data?.data } loading={ getParentChildren.isLoading } page={ getParentChildren.data?.page } pageSize={ getParentChildren.data?.pageSize } allPage={ getParentChildren.data?.count } handleSetPage={ handleSetPage } handleSetPageSize={ handleSetPageSize }/>
              </section>
          </div>
          <section>
              { ( getParent.isError || getParentChildren.isError ) && (
                  <ErrorModal onClose={ handleCloseErrorModal } title={ t( "child-page-error-modal-title" ) } error={ getParent.isError ?? getParentChildren.error }/> ) }
          </section>
          <section>
              { state.achievementModalVisible && (
                  <ChildAchievementModal childName={ state.childName } achievements={ state.achievements } handleModalClose={ handleAchievementModalVisible }/> ) }
          </section>
          <section>
              { state.deleteModalVisible && (
                  <ChildDeleteModal handleDeleteModalVisible={ handleDeleteModalVisible } handleSetPage={ handleSetPage }/> ) }
          </section>
          <section>
              { state.editModalVisible && (
                  <ChildEditModal close={ handleEditModalVisible } latestQueryKey={[
                      QueryKeys.childrenQuery,
                      {
                          parentId: parseInt(params.parentId!),
                          page: state.page,
                          pageSize: state.pageSize,
                          nickname: state.searchTerm,
                          gender: state.gender,
                          sleepTime: state.sleepTime,
                          classOrder: state.classOrder,
                          nicknameOrder: state.nicknameOrder,
                          latestActivityOrder: state.latestActivityOrder,
                      },
                  ]} child={ state.currentChild! } /> ) }
          </section>
      </main> );
}
