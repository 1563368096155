import { Expose, Type } from "class-transformer";
import { ValidateNested } from "class-validator";
import { VersionEntity } from "../../entity";

export class MutateVersionDTO {
  @Expose()
  @ValidateNested()
  @Type(() => VersionEntity)
  version!: VersionEntity;
}
