import { Expose, Type } from "class-transformer";
import { IsArray, IsNumber, ValidateNested } from "class-validator";
import { PoetryQuizEntity } from "../../entity"

export class GetAllPoetryQuizDTO {
  @Expose()
  @ValidateNested()
  @IsArray()
  @Type(() => PoetryQuizEntity)
  data!: PoetryQuizEntity[];

  @Expose()
  @IsNumber()
  count!: number;

  @Expose()
  @IsNumber()
  page!: number;

  @Expose()
  @IsNumber()
  pageSize!: number;
}
