import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  QueryKeys,
  mutationHandler,
  useAppLocation,
  useAppState,
  useAppTranslation,
  PageType,
} from "../../../common";
import { ErrorModal, Modal } from "../../../components";
import { deleteDisappearingAction } from "../../../actions";
import { DisappearingEntity } from "../../../entity";

type DisappearingDeleteModalProps = {
  handleDeleteModalVisible: () => void;
  handleSetPage: (value: number) => void;
};

export function DisappearingDeleteModal(props: DisappearingDeleteModalProps) {
  const { t } = useAppTranslation();
  const location = useAppLocation();

  const queryClient = useQueryClient();
  const [state, setState] = useAppState({
    errorModalVisible: false,
  });

  const handleModalVisibility = () => {
    setState((prevState) => ({
      ...prevState,
      errorModalVisible: !prevState.errorModalVisible,
    }));
  };

  const deleteDisappearing = useMutation({
    mutationKey: [QueryKeys.deleteDisappearingGame],
    mutationFn: deleteDisappearingAction,
    onSuccess() {
      props.handleDeleteModalVisible();
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.disappearingGames],
      });
      let page = 1;
      queryClient.setQueryData(
        [QueryKeys.disappearingGames],
        (old: PageType<DisappearingEntity>) => {
          const handler = mutationHandler({
            dataLength: old.data.length,
            pageSize: old.pageSize,
            count: old.count,
          });

          page = handler.page;

          return {
            page: old.page,
            pageSize: old.pageSize,
            count: handler.page,
            data: old.data,
          };
        }
      );
      props.handleSetPage(page);
    },
    onError() {
      handleModalVisibility();
    },
  });

  const handleDeleteDisappearing = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    deleteDisappearing.mutate({ id: location.state?.id });
  };

  return (
    <div>
      <section>
        <Modal
          onClose={props.handleDeleteModalVisible}
          title={t("disappearing-page-delete-modal-title")}
          footer
          submitText={t("disappearing-page-delete-submit-button-title")}
          cancelText={t("disappearing-page-delete-cancel-button-title")}
          handleSubmit={handleDeleteDisappearing}
          loading={deleteDisappearing.isPending}
        >
          <span>
            {t("disappearing-page-delete-disappearing-question-label")}
          </span>
        </Modal>
      </section>
      <section>
        {state.errorModalVisible && (
          <ErrorModal
            error={deleteDisappearing.error}
            onClose={handleModalVisibility}
            title={t("disappearing-page-delete-disappearing-error-modal-title")}
          />
        )}
      </section>
    </div>
  );
}
