import { Expose } from "class-transformer";
import {
  IsDateString,
  IsDefined,
  IsNumber,
  IsOptional,
  IsString,
} from "class-validator";

export class AchievementEntity {
  @Expose()
  @IsDefined()
  @IsNumber()
  id!: number;

  @Expose()
  @IsDefined()
  @IsString()
  type!: string;

  @Expose()
  @IsDefined()
  @IsNumber()
  level!: number;

  @Expose()
  @IsDefined()
  @IsString()
  description!: string;

  @Expose()
  @IsDefined()
  @IsString()
  badgeUrl!: string;

  @Expose()
  @IsOptional()
  @IsDateString()
  updated?: string;

  @Expose()
  @IsOptional()
  @IsDateString()
  created?: string;
}
