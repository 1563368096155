type DetailsIconProps = {
  onClick: () => void;
};

export function EditIcon(props: DetailsIconProps) {
  return (
    <img
      className="clickable-icon"
      onClick={props.onClick}
      src="/pictures/edit-icon.png"
      alt="edit"
    />
  );
}
