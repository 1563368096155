import axios from "axios";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { SuccessDTO } from "../../dto";

type ResetPasswordActionProps = {
  password: string;
};

let body: SuccessDTO;

export async function resetPasswordAction(props: ResetPasswordActionProps) {
  const getQueryParam = new URLSearchParams(window.location.search);
  const token = getQueryParam.get("token");

  const params = {
    password: props.password,
    token,
  };

  return await axios
    .patch(
      `${process.env.REACT_APP_API_ORIGIN}/1.0.0/auth/reset-password`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          "app-platform": "cms",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(async (res) => {
      body = plainToClass(SuccessDTO, res.data);
      await validate(body);
      const errors = await validate(body);
      if (errors.length > 0) {
        throw errors;
      }

      return res.data;
    });
}
