import { useAppNavigate } from "../../../common";
import { DeleteIcon, SortIcon } from "../../../components";
import { AdminEntity } from "../../../entity";

type AdminColumnProps = {
  handleUpdatedOrder: (value: string) => void;
  updatedOrder: "ASC" | "DESC" | "";
  handleCreatedOrder: (value: string) => void;
  createdOrder: "ASC" | "DESC" | "";
  handleDeleteModalVisible: () => void;
};

export function AdminColumns(props: AdminColumnProps) {
  const navigate = useAppNavigate();
  const handleOpenDeleteModal = (id: number) => {
    props.handleDeleteModalVisible();
    navigate("", { state: { id } });
  };

  return [
    {
      title: "admin-page-table-header-email",
      dataIndex: "email",
      width: "65%",
    },
    {
      title: "admin-page-table-header-updated",
      dataIndex: "created",
      suffix: (
        <SortIcon
          order={props.createdOrder}
          orderFunction={props.handleCreatedOrder}
        />
      ),
      render: (record: AdminEntity) => {
        return <span>{new Date(record?.created).toLocaleDateString()}</span>;
      },
    },
    {
      title: "admin-page-table-header-created",
      dataIndex: "updated",
      suffix: (
        <SortIcon
          order={props.updatedOrder}
          orderFunction={props.handleUpdatedOrder}
        />
      ),
      render: (record: AdminEntity) => {
        return <span>{new Date(record?.updated).toLocaleDateString()}</span>;
      },
    },
    {
      title: "admin-page-table-header-delete",
      dataIndex: "delete",
      render: (record: AdminEntity) => {
        return <DeleteIcon onClick={() => handleOpenDeleteModal(record?.id)} />;
      },
    },
  ];
}
