import axios from "axios";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { MutatePoetryChatDTO } from "../../dto"

let body: MutatePoetryChatDTO;

export async function createPoetryChatAction(poetryChat: any) {
  const token = localStorage.getItem("token");

  const params = new FormData();
  if (poetryChat.audio) params.append("audio", poetryChat.audio);
  params.append("type", poetryChat.type);
  params.append("text", poetryChat.text);
  params.append("poetryId", poetryChat.poetryId.toString());

  return await axios
    .post(
      `${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/preparation-chats/create`,
      params,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "app-platform": "cms",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(async (res) => {
      body = plainToClass(MutatePoetryChatDTO, res.data);
      await validate(body);
      const errors = await validate(body);
      if (errors.length > 0) {
        throw errors;
      }

      return res.data;
    });
}
