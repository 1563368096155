import { useMutation } from "@tanstack/react-query";
import {
  QueryKeys,
  emailValidator,
  useAppState,
  useAppTranslation,
} from "../../common";
import { Button, Form, InfoModal, Input, Tooltip } from "../../components";
import { ForgotPasswordAction } from "../../actions";

export function ForgotPasswordPage() {
  const { t } = useAppTranslation();
  const [state, setState] = useAppState({
    email: "",
    modalVisible: false,
  });

  const forgotPassword = useMutation({
    mutationKey: [QueryKeys.forgotPasswordConfirmQuery],
    mutationFn: ForgotPasswordAction,
    onSuccess() {
      setState((prevState) => ({ ...prevState, modalVisible: true }));
    },
  });

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, email: e.target.value }));
  };

  const handleSendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    forgotPassword.mutate({
      email: state.email,
    });
  };

  const handleCloseModal = () => {
    setState((prevState) => ({ ...prevState, modalVisible: false }));
  };

  const isEmailValid = (email: string) => {
    return emailValidator({ email });
  };

  return (
    <main>
      <section className="login-form">
        <Form onSubmit={handleSendEmail}>
          <Tooltip
            content={
              state.email.length > 0 && !isEmailValid(state.email)
                ? t("invalid-email-format")
                : ""
            }
          >
            <Input
              type="text"
              className={`login-input ${
                state.email.length > 0 && !isEmailValid(state.email)
                  ? "error"
                  : ""
              }`}
              value={state.email}
              onChange={handleEmailChange}
              placeholder={t("forgot-password-page-input-placeholder-email")}
            />
          </Tooltip>

          <div className="button-container">
            <Button
              htmlType="submit"
              title={t("forgot-password-page-button-title")}
              loading={forgotPassword.isPending}
              disabled={state.email.length === 0 || !isEmailValid(state.email) || forgotPassword.isPending}
            />
          </div>
        </Form>
      </section>
      {state.modalVisible && (
        <InfoModal
          type="success"
          message={t("forgot-password-info-modal-success-message")}
          onClose={handleCloseModal}
        />
      )}
    </main>
  );
}
