import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  ErrorModal,
  Form,
  Input,
  Modal,
  ValidationErrorModal,
} from "../../../components";
import { Selector } from "../../../components/selector/selector";
import { CategoryEntity, PoetEntity, PoetryEntity } from "../../../entity";
import {
  PageType,
  QueryKeys,
  mutationHandler,
  useAppState,
  useAppTranslation,
} from "../../../common";
import { RichTextEditor } from "../../../components/rich-text-input";
import {
  createPoetryAction,
  modifyPoetryAction,
} from "../../../actions/poetry";
import { CategoriesModal } from "./categories-modal";
import { listAllCategoryAction, listAllPoetAction } from "../../../actions";

interface PoetryModalProps {
  poetry: PoetryEntity | null;
  close: () => void;
  latestQueryKey: any;
}

export const PoetryModal = (props: PoetryModalProps) => {
  const queryClient = useQueryClient();
  const { t } = useAppTranslation();

  const [state, setState] = useAppState({
    categoriesModalVisible: false,
    validationErrorMessage: null as string | null,
    title: props.poetry ? props.poetry.title : ``,
    cls: "1",
    isRecommended: "1",
    writingTime: props.poetry ? props.poetry.timeOfWritting : "",
    poet: props.poetry ? props.poetry.poet.id.toString() : `0`,
    info: props.poetry ? props.poetry.info : ``,
    analysis: props.poetry ? props.poetry.analysis : ``,
    categories: props.poetry
      ? props.poetry.categories.map((category) => category.id)
      : ([] as number[]),
  });

  const classes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => ({
    value: i.toString(),
    label: `poetry-page-${i}-class`,
  }));

  const listAllCategory = useQuery({
    queryKey: [QueryKeys.categoryQuery],
    queryFn: listAllCategoryAction,
  });

  const getAllPoet = useQuery({
    queryKey: [QueryKeys.poetQuery],
    queryFn: listAllPoetAction,
  });

  const checkForm = () => {
    if (state.title.trim().length === 0) {
      setState({
        ...state,
        validationErrorMessage: `A cím kitöltése kötelező!`,
      });
      return false;
    }

    if (state.categories.length === 0) {
      setState({
        ...state,
        validationErrorMessage: `Legalább egy kategória kiválasztása kötelező!`,
      });
      return false;
    }

    if (state.info.trim().length === 0) {
      setState({
        ...state,
        validationErrorMessage: `Az infó kitöltése kötelező!`,
      });
      return false;
    }

    if (state.analysis.trim().length === 0) {
      setState({
        ...state,
        validationErrorMessage: `Az analízis kitöltése kötelező!`,
      });
      return false;
    }

    return true;
  };

  const mutatePoetry = useMutation({
    mutationKey: [QueryKeys.mutatePoetryQuery],
    mutationFn: (data: any) =>
      props.poetry?.id ? modifyPoetryAction(data) : createPoetryAction(data),
    onMutate: async (newPoetry) => {
      await queryClient.cancelQueries({ queryKey: props.latestQueryKey });
      const previousPoetries = queryClient.getQueryData([props.latestQueryKey]);

      queryClient.setQueryData(
        props.latestQueryKey,
        (old: PageType<PoetryEntity>) => {
          const handler = mutationHandler({
            dataLength: old.data.length,
            pageSize: old.pageSize,
            count: old.count,
            oldData: old.data,
            newData: newPoetry,
          });

          return {
            page: old.page,
            pageSize: old.pageSize,
            count: handler.newCount,
            data: handler.newData,
          };
        }
      );

      return { previousPoetries };
    },
    onSuccess() {
      props.close();
    },
    onError(error, variables, context) {
      queryClient.setQueryData(
        [props.latestQueryKey],
        context?.previousPoetries
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.poetryQuery],
      });
    },
  });

  const handleCloseErrorModal = () => {
    mutatePoetry.reset();
  };

  const handleCategoriesModalVisible = () => {
    setState((prevState) => ({
      ...prevState,
      categoriesModalVisible: !prevState.categoriesModalVisible,
    }));
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      title: e.target.value,
    }));
  };

  const handleClsChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setState((prevState) => ({
      ...prevState,
      cls: e.target.value,
    }));
  };

  const handleRecommendedChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setState((prevState) => ({
      ...prevState,
      isRecommended: e.target.value,
    }));
  };

  const handleWritingTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      writingTime: e.target.value,
    }));
  };

  const handlePoetChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setState((prevState) => ({
      ...prevState,
      poet: e.target.value,
    }));
  };

  const handleCategoriesChange = (ids: number[]) => {
    setState((prevState) => ({
      ...prevState,
      categories: ids,
    }));
  };

  const handleInfoChange = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      info: value,
    }));
  };

  const handleAnalysisChange = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      analysis: value,
    }));
  };

  const submit = async () => {
    if (!checkForm()) {
      return;
    }
    const poetry = {
      id: props.poetry ? props.poetry.id : null,
      title: state.title,
      class: state.cls,
      recommended: state.isRecommended === `1`,
      timeOfWritting: state.writingTime,
      poetId:
        state.poet === `0`
          ? getAllPoet.data?.poets[0]?.id.toString() || `0`
          : state.poet,
      info: state.info,
      analysis: state.analysis,
      categoryIds: state.categories,
    };

    mutatePoetry.mutate(poetry);
  };

  return (
    <div>
      <section>
        <Modal
          onClose={props.close}
          title={`${t(`poetry-page-poetry`)} ${
            props.poetry ? t(`poetry-page-edit`) : t(`poetry-page-create`)
          }`}
          footer={true}
          submitText={props.poetry ? `Módosítás` : `Létrehozás`}
          cancelText={t(`poetry-page-delete-cancel-button-title`)}
          handleSubmit={submit}
          loading={mutatePoetry.isPending}
        >
          <div className="poetry-modal">
            <Form onSubmit={submit}>
              <Input
                onChange={handleTitleChange}
                value={state.title}
                placeholder={t(`poetry-page-poetry-title`)}
                className="poetry-modal-input"
                type="text"
              />

              <Selector
                name={t(`poetry-page-poetry-class`)}
                id="poetry-class"
                handleSelectOption={handleClsChange}
                options={classes}
                onlyLabel={true}
                currentValue={state.cls}
              />

              <Selector
                name={t(`poetry-page-poetry-recommended`)}
                id="poetry-recommended"
                handleSelectOption={handleRecommendedChange}
                options={[
                  { value: `0`, label: `poetry-page-poetry-recommended-false` },
                  { value: `1`, label: `poetry-page-poetry-recommended` },
                ]}
                onlyLabel={true}
                currentValue={state.isRecommended}
              />

              <Input
                onChange={handleWritingTimeChange}
                value={state.writingTime}
                placeholder={t(`poetry-page-poetry-writing-time`)}
                className="poetry-modal-input"
                type="text"
              />

              <Selector
                name={t(`poetry-page-poetry-poet`)}
                id="poetry-poet"
                handleSelectOption={handlePoetChange}
                options={
                  getAllPoet.data?.poets.map((poet: PoetEntity) => ({
                    value: poet.id.toString(),
                    label: poet.name,
                  })) || []
                }
                onlyLabel={true}
                currentValue={state.poet}
              />

              <div className="poetry-category-select">
                <div className="label">
                  {t(`poetry-page-poetry-categories`)}
                </div>
                <div className="categories">
                  {state.categories
                    .map((categoryId) =>
                      listAllCategory.data?.categories.find(
                        (category: CategoryEntity) => category.id === categoryId
                      )
                    )
                    .map((category) =>
                      category ? (
                        <div key={category.id} className="category">
                          {category.title}
                          <div
                            className="close"
                            onClick={() =>
                              handleCategoriesChange(
                                state.categories.filter(
                                  (id) => id !== category.id
                                )
                              )
                            }
                          >
                            x
                          </div>
                        </div>
                      ) : null
                    )}
                  {state.categories.length === 0 ? (
                    <>{t(`poetry-page-poetry-categories-no-category`)}</>
                  ) : null}
                </div>
                <Button
                  htmlType="button"
                  onClick={handleCategoriesModalVisible}
                  title={t(`poetry-page-poetry-categories-select`)}
                />
              </div>

              <RichTextEditor
                onChange={handleInfoChange}
                value={state.info}
                placeholder={t(`poetry-page-poetry-info`)}
              />

              <RichTextEditor
                onChange={handleAnalysisChange}
                value={state.analysis}
                placeholder={t(`poetry-page-poetry-analysis`)}
              />
            </Form>

            {state.categoriesModalVisible &&
            listAllCategory.data?.categories ? (
              <CategoriesModal
                categories={listAllCategory.data?.categories}
                onClose={handleCategoriesModalVisible}
                selectedCategories={state.categories}
                onChange={(c) => handleCategoriesChange(c)}
              />
            ) : null}
            {state.validationErrorMessage && (
              <ValidationErrorModal
                error={state.validationErrorMessage}
                onClose={() =>
                  setState({
                    ...state,
                    validationErrorMessage: null,
                  })
                }
              />
            )}
          </div>
        </Modal>
      </section>

      <section>
        {mutatePoetry.isError && (
          <ErrorModal
            error={mutatePoetry.error}
            onClose={handleCloseErrorModal}
            title={
              props.poetry?.id
                ? t("poetry-page-mutate-poetry-form-error-modal-modify-title")
                : t("poetry-page-mutate-poetry-form-error-modal-create-title")
            }
          />
        )}
      </section>
    </div>
  );
};
