import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import {
  QueryKeys,
  SettingEnum,
  useAppNavigate,
  useAppState,
  useAppTranslation,
  useDebounce,
} from "../../common";
import { Button, ErrorModal, Input, Table } from "../../components";
import {
  getAllTierAction,
  getSettingAction,
  mutateSettingAction,
} from "../../actions";
import { TierColumns, TierDeleteModal, TierMutationForm } from "./components";
import { DateSelector } from "../../components/date-selector";
import "./tier.page.scss";

export function TierPage() {
  const queryClient = useQueryClient();
  const navigate = useAppNavigate();
  const { t } = useAppTranslation();

  const [state, setState] = useAppState({
    page: 1,
    pageSize: 10,
    name: "",
    mutateModalVisible: false,
    deleteModalVisible: false,
    modify: false,
    searchTerm: "",
  });

  const getAllTier = useQuery({
    queryKey: [
      QueryKeys.tierQuery,
      {
        page: state.page,
        pageSize: state.pageSize,
        name: state.searchTerm,
      },
    ],
    queryFn: () =>
      getAllTierAction({
        page: state.page,
        pageSize: state.pageSize,
        name: state.searchTerm,
      }),
  });

  const getSetting = useQuery({
    queryKey: [QueryKeys.getSettingQuery],
    queryFn: () => getSettingAction({ type: SettingEnum.TIER_RESET_TIME }),
  });

  const handleSearch = useDebounce((searchTerm: string) => {
    setState((prevState) => ({ ...prevState, searchTerm: searchTerm }));
  }, 250);

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, name: e.target.value }));

    handleSearch(e.target.value);
  };

  const handleMutateModalVisible = (modify: boolean) => {
    navigate("", { state: undefined });
    setState((prevState) => ({
      ...prevState,
      mutateModalVisible: !prevState.mutateModalVisible,
      modify,
    }));
  };

  const handleDeleteModalVisible = () => {
    setState((prevState) => ({
      ...prevState,
      deleteModalVisible: !prevState.deleteModalVisible,
    }));
  };

  const handleSetPage = (value: number) => {
    setState((prevState) => ({ ...prevState, page: value }));
  };

  const handleSetPageSize = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setState((prevState) => ({
      ...prevState,
      pageSize: parseInt(e.target.value),
    }));
  };

  const mutateTierResetTime = useMutation({
    mutationKey: [QueryKeys.mutateSettingQuery],
    mutationFn: mutateSettingAction,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.getSettingQuery],
      }),
  });

  const handleCloseErrorModal = () => {
    getAllTier.isError
      ? getAllTier.refetch()
      : getSetting.isError
      ? getSetting.refetch()
      : mutateTierResetTime.reset();
  };

  const handleChangeResetTime = (date: Date) => {
    mutateTierResetTime.mutate({
      tierResetTime: date,
    });
  };

  return (
    <main className="page">
      <div className="tier-page">
        <section className="tier-title-timereset-display">
          <section className="title">{t("tier-page-title")}</section>
          <section className="date-selector">
            <span>{t("tier-page-reset-time")}</span>
            <DateSelector
              onChange={handleChangeResetTime}
              value={new Date(getSetting.data?.setting?.tierResetTime)}
              placeholder={`${new Date(
                getSetting.data?.setting?.tierResetTime
              )}`}
              className="tier-reset-time-selector"
              disabled={mutateTierResetTime.isPending}
            />
          </section>
        </section>

        <section className="search-bar-container">
          <Input
            onChange={handleSearchTermChange}
            value={state.name}
            placeholder={t("tier-page-search-bar-placeholder")}
            type="search"
            className="searchbar"
          />
          <Button
            onClick={() => handleMutateModalVisible(false)}
            title="+"
            htmlType="button"
            creationButton
          />
        </section>
        <section>
          <Table
            columns={Object.values(
              TierColumns({
                handleDeleteModalVisible,
                handleMutateModalVisible,
              })
            )}
            paginatorNeeded
            data={getAllTier.data?.data}
            loading={getAllTier.isLoading}
            page={getAllTier.data?.page}
            pageSize={getAllTier.data?.pageSize}
            allPage={getAllTier.data?.count}
            handleSetPage={handleSetPage}
            handleSetPageSize={handleSetPageSize}
          />
        </section>
      </div>
      <section>
        {state.mutateModalVisible && (
          <TierMutationForm
            modify={state.modify}
            handleMutationModalVisible={handleMutateModalVisible}
            latestQueryKey={[
              QueryKeys.tierQuery,
              {
                page: state.page,
                pageSize: state.pageSize,
                name: state.searchTerm,
              },
            ]}
          />
        )}
      </section>
      <section>
        {state.deleteModalVisible && (
          <TierDeleteModal
            handleDeleteModalVisible={handleDeleteModalVisible}
          />
        )}
      </section>
      <section>
        {(getAllTier.isError ||
          getSetting.isError ||
          mutateTierResetTime.error) && (
          <ErrorModal
            onClose={handleCloseErrorModal}
            title={
              getAllTier.isError
                ? t("tier-page-error-modal-title")
                : getSetting.isError
                ? t("tier-page-get-setting-error-title")
                : t("tier-page-mutate-tier-reset-time-error-modal-title")
            }
            error={
              getAllTier.error || getSetting.error || mutateTierResetTime.error
            }
          />
        )}
      </section>
    </main>
  );
}
