export const menuButtons = [
  {
    title: "menu-admins-link",
    navigation: "/cms/admins",
  },
  {
    title: "menu-parents-link",
    navigation: "/cms/parents",
  },
  {
    title: "menu-poets-link",
    navigation: "/cms/poets",
  },
  {
    title: "menu-poetry-link",
    navigation: "/cms/poetry",
  },
  {
    title: "menu-categories-link",
    navigation: "/cms/categories",
  },
  // {
  //   title: "menu-events-link",
  //   navigation: "/cms/events",
  // },
  {
    title: "menu-achievements-link",
    navigation: "/cms/achievements",
  },
  {
    title: "menu-tiers-link",
    navigation: "/cms/tiers",
  },
  {
    title: "menu-versions-link",
    navigation: "/cms/versions",
  },
];
