import { Expose } from "class-transformer";
import {
  IsBoolean,
  IsDateString,
  IsDefined,
  IsNumber,
  IsString,
} from "class-validator";

export class VersionEntity {
  @Expose()
  @IsDefined()
  @IsNumber()
  id!: number;

  @Expose()
  @IsDefined()
  @IsString()
  version!: string;

  @Expose()
  @IsDefined()
  @IsString()
  platform!: string;

  @Expose()
  @IsDefined()
  @IsBoolean()
  critical!: boolean;

  @Expose()
  @IsDefined()
  @IsDateString()
  created!: string;

  @Expose()
  @IsDefined()
  @IsDateString()
  updated!: string;
}
