import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  PageType,
  QueryKeys,
  mutationHandler,
  useAppLocation,
  useAppParams,
  useAppState,
  useAppTranslation,
} from "../../../common";
import {
  ErrorModal,
  Form,
  Input,
  Modal,
  RichTextEditor,
  ValidationErrorModal,
} from "../../../components";
import {
  createInlineInfoAction,
  modifyInlineInfoAction,
} from "../../../actions";
import { InlineInfoEntity } from "../../../entity";
import { MutateInlineInfoDTO } from "../../../dto";

type InlineInfoMutationFormProps = {
  modify: boolean;
  handleMutationModalVisible: (value: boolean) => void;
  latestQueryKey: any;
};

export function InlineInfoMutationForm(props: InlineInfoMutationFormProps) {
  const queryClient = useQueryClient();
  const location = useAppLocation();
  const params = useAppParams();
  const { t } = useAppTranslation();

  const [state, setState] = useAppState({
    name: location.state?.info.name ?? "",
    text: location.state?.info.text ?? "",
    validationErrorMessage: null as string | null,
  });

  const mutateInfo = useMutation({
    mutationKey: [
      props.modify ? QueryKeys.modifyInlineInfo : QueryKeys.createInlineInfo,
    ],
    mutationFn: (data: any) =>
      props.modify
        ? modifyInlineInfoAction(data)
        : createInlineInfoAction(data),
    onMutate: async (inlineInfo) => {
      await queryClient.cancelQueries({ queryKey: props.latestQueryKey });
      const previousInlineInfo = queryClient.getQueryData([
        props.latestQueryKey,
      ]);
      queryClient.setQueryData(
        props.latestQueryKey,
        (old: PageType<InlineInfoEntity>) => {
          const handler = mutationHandler({
            dataLength: old.data.length,
            pageSize: old.pageSize,
            count: old.count,
            oldData: old.data,
            newData: inlineInfo,
          });

          return {
            page: old.page,
            pageSize: old.pageSize,
            count: handler.newCount,
            data: handler.newData,
          };
        }
      );
      return { previousInlineInfo };
    },
    onSuccess(result: MutateInlineInfoDTO) {
      props.handleMutationModalVisible(false);
    },
    onError(error, variables, context) {
      queryClient.setQueryData(
        [props.latestQueryKey],
        context?.previousInlineInfo
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.inlineInfos],
      });
    },
  });

  const handleCloseErrorModal = () => {
    mutateInfo.reset();
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, name: e.target.value }));
  };

  const handleTextChange = (e: string) => {
    setState((prevState) => ({ ...prevState, text: e }));
  };

  const checkForm = () => {
    if (state.name.trim().length === 0) {
      setState({
        ...state,
        validationErrorMessage: `A szó kitöltése kötelező!`,
      });
      return false;
    }
    if (state.text.trim().length === 0) {
      setState({
        ...state,
        validationErrorMessage: `A leírás kitöltése kötelező!`,
      });
      return false;
    }

    return true;
  };

  const handleMutateLine = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!checkForm()) {
      return;
    }
    mutateInfo.mutate({
      lineId: !props.modify ? parseInt(params.lineId!) : undefined,
      name: state.name,
      text: state.text,
      id: props.modify ? location.state?.info.id : undefined!,
    });
  };

  return (
    <div>
      <section>
        <Modal
          onClose={() => props.handleMutationModalVisible(false)}
          title={
            props.modify
              ? t("inline-info-page-mutate-info-modal-modify-title")
              : t("inline-info-page-mutate-info-modal-create-title")
          }
          footer
          cancelText={t("inline-info-page-mutate-info-modal-cancel-label")}
          submitText={
            props.modify
              ? t("inline-info-page-mutate-info-modal-modify-submit-label")
              : t("inline-info-page-mutate-info-modal-create-submit-label")
          }
          handleSubmit={handleMutateLine}
          loading={mutateInfo.isPending}
        >
          <Form onSubmit={handleMutateLine}>
            <div className="inline-info-form">
              <Input
                type="text"
                maxLength={255}
                className="mutate-modal-input"
                onChange={handleNameChange}
                value={state.name}
                placeholder={t(
                  "inline-info-page-mutate-info-modal-name-placeholder"
                )}
              />
              <RichTextEditor
                onChange={handleTextChange}
                value={state.text}
                placeholder={t(
                  "inline-info-page-mutate-info-modal-text-placeholder"
                )}
              />
            </div>
          </Form>
        </Modal>
      </section>
      <section>
        {mutateInfo.isError && (
          <ErrorModal
            error={mutateInfo.error}
            onClose={handleCloseErrorModal}
            title={
              props.modify
                ? t(
                    "inline-info-page-mutate-info-form-error-modal-modify-title"
                  )
                : t(
                    "inline-info-page-mutate-info-form-error-modal-create-title"
                  )
            }
          />
        )}
      </section>
      <section>
        {state.validationErrorMessage && (
          <ValidationErrorModal
            error={state.validationErrorMessage}
            onClose={() =>
              setState({
                ...state,
                validationErrorMessage: null,
              })
            }
          />
        )}
      </section>
    </div>
  );
}
