import axios from "axios";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { SuccessDTO } from "../../dto";

type ModifyProfileActionProps = {
  password: string;
  passwordCheck: string;
};

let body: SuccessDTO;

export async function modifyProfileAction(props: ModifyProfileActionProps) {
  const token = localStorage.getItem("token");

  const params = {
    password: props.password,
    passwordCheck: props.passwordCheck,
  };

  return await axios
    .patch(`${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/admins/modify`, params, {
      headers: {
        "Content-Type": "application/json",
        "app-platform": "cms",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async (res) => {
      body = plainToClass(SuccessDTO, res.data);
      await validate(body);
      const errors = await validate(body);
      if (errors.length > 0) {
        throw errors;
      }

      return res.data;
    });
}
