import axios from "axios";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { MutateLineOfPoetryDTO } from "../../dto";

type ModifyLineOfPoetryProps = {
  audioYoung: File;
  audioOld: File;
  text: string;
  stanza: string;
  unit: string;
  id: number;
};

let body: MutateLineOfPoetryDTO;

export async function modifyLineOfPoetryAction(props: ModifyLineOfPoetryProps) {
  const token = localStorage.getItem("token");

  const params = new FormData();
  if (props.audioYoung) params.append("audioYoung", props.audioYoung);
  if (props.audioOld) params.append("audioOld", props.audioOld);
  params.append("stanza", props.stanza);
  params.append("text", props.text);
  params.append("id", props.id.toString());
  params.append("unit", props.unit);

  return await axios
    .patch(
      `${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/lines-of-poetries/modify`,
      params,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "app-platform": "cms",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(async (res) => {
      body = plainToClass(MutateLineOfPoetryDTO, res.data);
      await validate(body);
      const errors = await validate(body);

      if (errors.length > 0) {
        throw errors;
      }

      return res.data;
    });
}
