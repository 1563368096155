import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";

export const useAppNavigate = () => useNavigate();
export const useAppParams = () => useParams();
export const useAppTranslation = () => useTranslation();
export const useAppState = <T>(
  initialState: T
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  return useState<T>(initialState);
};
export const useAppMemo = <T>(
  factory: () => T,
  deps: React.DependencyList
): T => {
  return useMemo(factory, [factory, deps]);
};

export const useAppLocation = () => useLocation();
