import axios from "axios";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { GetParentChildrenDTO } from "../../dto";

type GetParentChildrenProps = {
  page: number;
  pageSize: number;
  parentId: number;
  nickname?: string;
  gender?: "1" | "2" | "";
  sleepTime?: "0" | "1" | "2" | "";
  classOrder?: "ASC" | "DESC" | "";
  nicknameOrder?: "ASC" | "DESC" | "";
  latestActivityOrder?: "ASC" | "DESC" | "";
};

let body: GetParentChildrenDTO;

export async function getParentChildrenAction(props: GetParentChildrenProps) {
  const token = localStorage.getItem("token");

  const params = {
    page: props.page,
    pageSize: props.pageSize,
    parentId: props.parentId,
    nickname: props.nickname ?? undefined,
    gender: props.gender !== "" ? props.gender : undefined,
    sleepTime: props.sleepTime !== "" ? props.sleepTime : undefined,
    classOrder: props.classOrder !== "" ? props.classOrder : undefined,
    nicknameOrder: props.nicknameOrder !== "" ? props.nicknameOrder : undefined,
    latestActivityOrder:
      props.latestActivityOrder !== "" ? props.latestActivityOrder : undefined,
  };

  return await axios
    .get(`${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/parents/search-child`, {
      headers: {
        "Content-Type": "application/json",
        "app-platform": "cms",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then(async (res) => {
      body = plainToClass(GetParentChildrenDTO, res.data);
      const errors = await validate(body);

      if (errors.length > 0) {
        console.log(errors);
        throw errors;
      }

      return res.data;
    });
}
