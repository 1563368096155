import { useAppTranslation } from "../hooks";

type ErrorHandlerProps = {
  code: number;
  data?: number;
};

export function useErrorHandler({ code, data }: ErrorHandlerProps) {
  const { t } = useAppTranslation();

  let errorMessage = "";
  switch (code) {
    case 1:
      errorMessage = t("error-internal-server-error");
      break;
    case 12:
      errorMessage = t("error-invalid-response-from-server");
      break;
    case 13:
      errorMessage = t("error-error-invalid-header-params");
      break;
    case 14:
      errorMessage = t("error-invalid-body-params");
      break;
    case 20 || 30:
      errorMessage = t("error-no-rights");
      break;
    case 40:
      errorMessage = t("error-too-many-login-attempts");
      break;
    case 1001:
      errorMessage = t("error-parent-not-found");
      break;
    case 1003:
      errorMessage = t("error-password-mismatch");
      break;
    case 1008:
      errorMessage = t("error-invalid-email-or-password");
      break;
    case 2001:
      errorMessage = t("error-session-expired");
      break;
    case 2002:
      errorMessage = t("error-invalid-session-params");
      break;
    case 3001:
      errorMessage = t("error-file-not-found");
      break;
    case 4001:
      errorMessage = t("error-invalid-password-reset-token");
      break;
    case 5001:
      errorMessage = t("error-child-not-found");
      break;
    case 6001:
      errorMessage = t("error-poetry-not-found");
      break;
    case 7001:
      errorMessage = t("error-admin-not-found");
      break;
    case 7002:
      errorMessage = t("error-admin-already-exist");
      break;
    case 7003:
      errorMessage = t("error-cannot-delete-maintainer");
      break;
    case 8001:
      errorMessage = t("error-poet-not-found");
      break;
    case 9001:
      errorMessage = t("error-poetry-not-found");
      break;
    case 10001:
      errorMessage = t("error-category-not-found");
      break;
    case 10002:
      errorMessage = t("error-category-already-exist");
      break;
    case 11001:
      errorMessage = t("error-inline-info-not-found");
      break;
    case 12002:
      errorMessage = t("error-event-not-found");
      break;
    case 13001:
      errorMessage = t("error-fact-not-found");
      break;
    case 14001:
      errorMessage = t("error-preparation-chat-not-found");
      break;
    case 15001:
      errorMessage = t("error-line-of-poetry-not-found");
      break;
    case 15003:
      errorMessage = t("error-line-of-poetry-unit-of-line-already-has-audio");
      break;
    case 15004:
      errorMessage = t("error-line-of-poetry-audio-not-found");
      break;
    case 15005:
      errorMessage = t(
        "error-line-of-poetry-there-must-always-be-line-with-audio"
      );
      break;
    case 16001:
      errorMessage = t("error-achievement-not-found");
      break;
    case 16002:
      errorMessage = t("error-achievement-already-exist");
      break;
    case 17001:
      errorMessage = t("error-badge-not-found");
      break;
    case 18001:
      errorMessage = t("error-tier-not-found");
      break;
    case 18002:
      errorMessage = t("error-tier-already-exist");
      break;
    case 19001:
      errorMessage = t("error-disappearing-game-not-found");
      break;
    case 19002:
      errorMessage = t("error-disappearing-game-already-exist");
      break;
    case 20001:
      errorMessage = t("error-disappearing-game-option-not-found");
      break;
    case 20002:
      errorMessage = t("error-disappearing-game-already-exist");
      break;
    case 21001:
      errorMessage = t("error-quiz-not-found");
      break;
    case 22001:
      errorMessage = t("error-quiz-answer-not-found");
      break;
    case 23001:
      errorMessage = t("error-quiz-info-not-found");
      break;
    case 23002:
      errorMessage = t("error-quiz-info-already-exist");
      break;
    case 24001:
      errorMessage = t("error-version-not-found");
      break;
    case 24002:
      errorMessage = t("error-version-already-exist");
      break;
    default:
      errorMessage = t("error-internal-server-error");
      break;
  }
  return errorMessage;
}
