import { Expose, Type } from "class-transformer";
import {
  IsBoolean,
  IsDateString,
  IsDefined,
  IsNumber,
  IsOptional,
  IsString,
  ValidateNested,
} from "class-validator";
import { MyPoetryEntity } from "./myPoetry.entity";
import { AchievementEntity } from "./achievement.entity";
import { TierEntity } from "./tier.entity";

export class ChildEntity {
  @Expose()
  @IsDefined()
  @IsNumber()
  id!: number;

  @Expose()
  @IsDefined()
  @IsString()
  nickname!: string;

  @Expose()
  @IsOptional()
  @IsString()
  pictureUrl?: string;

  @Expose()
  @IsDefined()
  @IsString()
  class!: string;

  @Expose()
  @IsDefined()
  @IsString()
  gender!: string;

  @Expose()
  @IsDefined()
  @IsString()
  sleepTime!: string;

  @Expose()
  @IsOptional()
  @IsString()
  learingPeriodFrom?: string | null;

  @Expose()
  @IsOptional()
  @IsString()
  learingPeriodTo?: string | null;

  @Expose()
  @IsOptional()
  @IsDateString()
  lastActivity?: string | null;

  @Expose()
  @IsDefined()
  @IsBoolean()
  soundEffects!: boolean;

  @Expose()
  @IsDefined()
  @IsBoolean()
  weekGoal!: boolean;

  @Expose()
  @IsOptional()
  @IsNumber()
  weekGoalPercent?: number | null;

  @Expose()
  @IsDefined()
  @IsDateString()
  updated!: string;

  @Expose()
  @IsDefined()
  @IsDateString()
  created!: string;

  @Expose()
  @IsOptional()
  @Type(() => MyPoetryEntity)
  myPoetries?: MyPoetryEntity[] | [];

  @Expose()
  @IsDefined()
  @ValidateNested()
  @Type(() => MyPoetryEntity)
  achievements!: AchievementEntity[] | [];

  @Expose()
  @IsDefined()
  @ValidateNested()
  @Type(() => TierEntity)
  tier!: TierEntity;
}
